import { axiosInstance } from '@/axiosInstance';
import { iCategory, iCrea, iMetaCategoryDetails } from '@/interfaces/global';
import { useGlobalStore } from '@/store';
import { defineStore } from 'pinia';

export const useCategoryStore = defineStore('useCategoryStore', {
    state: () => ({
        categories: [] as (iCategory | iCrea)[],
        metaCategories: [] as iMetaCategoryDetails[],
    }),
    getters: {
        apiCategories() {
            const globalStore = useGlobalStore();
            return `/categories?brand=${globalStore.brand}`;
        },
        apiCategory() {
            const globalStore = useGlobalStore();
            const url = `/categories/:crea_category_code?brand=${globalStore.brand}`;
            return (crea_category_code: string) => url.replace(':crea_category_code', crea_category_code);
        },
        apiMetaCategories() {
            const globalStore = useGlobalStore();
            return `/meta-categories?brand=${globalStore.brand}`;
        },
        apiMetaCategory() {
            const globalStore = useGlobalStore();
            const url = `/meta-categories/:crea_metacategory_code?brand=${globalStore.brand}`;
            return (crea_metacategory_code: string) => url.replace(':crea_metacategory_code', crea_metacategory_code);
        },
        apiAssociateCatToMetacat() {
            const globalStore = useGlobalStore();
            const url = `/meta-categories/category/:crea_category_code/:crea_metacategory_code?brand=${globalStore.brand}`;
            return (crea_category_code: string, crea_metacategory_code: string) => url.replace(':crea_category_code', crea_category_code).replace(':crea_metacategory_code', crea_metacategory_code);
        },
    },
    actions: {
        formatCreacategories() {
            this.metaCategories?.forEach((metaCat: iMetaCategoryDetails) => {
                if (metaCat.creacategories) {
                    const newCreacategories: any[] = metaCat.creacategories
                        ?.map((creaCat: any) => {
                            const mappedCat: any = this.categories.find((cat: any) => cat.crea_category_code === creaCat.crea_category_code);
                            return { ...mappedCat, creacategory_to_metacategory_code: creaCat.creacategory_to_metacategory_code };
                        })
                        .filter((creaCat: any) => !!creaCat);
                    metaCat.creacategories = newCreacategories;
                } else {
                    metaCat.creacategories = [];
                }
            });
            this;
        },
        async getCategories() {
            try {
                this.categories = (await axiosInstance.get(this.apiCategories)).data.data;
                this.formatCreacategories();
            } catch (error) {
                console.log(error);
            }
        },
        async getMetaCategories() {
            try {
                this.metaCategories = (await axiosInstance.get(this.apiMetaCategories)).data.data as iMetaCategoryDetails[];
                this.formatCreacategories();
            } catch (error) {
                console.log(error);
            }
        },
        async addCategory(categoryName: string) {
            try {
                const newCategory = (await axiosInstance.post(this.apiCategories, { name: categoryName })).data.data as iCategory;

                if (newCategory) {
                    this.categories.push(newCategory);
                }

                return newCategory;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async addMetaCategory(metaCategoryName: string, categories: string[]) {
            try {
                const newMetaCategory = (await axiosInstance.post(this.apiMetaCategories, { name: metaCategoryName })).data.data as iMetaCategoryDetails;

                if (newMetaCategory) {
                    for await (const categoryName of categories) {
                        const newCategory = await this.addCategory(categoryName);

                        await axiosInstance.post(this.apiAssociateCatToMetacat(newCategory.crea_category_code, newMetaCategory.crea_metacategory_code));
                    }

                    await this.getMetaCategories();
                }
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async updateCategory(category: iCategory) {
            try {
                const updatedCategory = (await axiosInstance.patch(this.apiCategory(category.crea_category_code), { name: category.name })).data.data as iCategory;

                if (updatedCategory) {
                    const categoryIndex = this.categories.findIndex((cat: any) => cat.crea_category_code === updatedCategory.crea_category_code);
                    this.categories[categoryIndex] = updatedCategory;
                }

                return updatedCategory;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async updateMetaCategory(metaCategoryCode: string, metaCategoryName: string, categoriesToUpdate: iCategory[], categoriestoAdd: string[], categoriesToRemove: iCategory[]) {
            try {
                const updatedMetaCategory = (await axiosInstance.patch(this.apiMetaCategory(metaCategoryCode), { name: metaCategoryName })).data.data as iMetaCategoryDetails;

                if (updatedMetaCategory) {
                    for await (const category of categoriesToUpdate) {
                        await this.updateCategory(category);
                    }

                    for await (const categoryName of categoriestoAdd) {
                        const newCategory = await this.addCategory(categoryName);

                        await axiosInstance.post(this.apiAssociateCatToMetacat(newCategory.crea_category_code, metaCategoryCode));
                    }

                    for await (const category of categoriesToRemove) {
                        await this.deleteCategory(category.crea_category_code);
                    }

                    await this.getCategories();
                    await this.getMetaCategories();
                }
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async swapCategories(catA: any, catB: any) {
            try {
                const categoryA: any = this.categories.find((cat) => cat.crea_category_code === catA.crea_category_code);
                const categoryB: any = this.categories.find((cat) => cat.crea_category_code === catB.crea_category_code);

                if (categoryA && categoryB) {
                    await axiosInstance.patch(this.apiCategory(categoryA.crea_category_code), { display_order: categoryB.display_order });

                    await axiosInstance.patch(this.apiCategory(categoryB.crea_category_code), { display_order: categoryA.display_order });

                    await this.getCategories();
                }
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async swapMetaCategories(metaCatA: any, metaCatB: any) {
            if (metaCatA && metaCatB) {
                await axiosInstance.patch(this.apiMetaCategory(metaCatA.crea_metacategory_code), { display_order: metaCatB.display_order });

                await axiosInstance.patch(this.apiMetaCategory(metaCatB.crea_metacategory_code), { display_order: metaCatA.display_order });

                await this.getMetaCategories();
            }
        },
        async deleteCategory(categoryCode: string) {
            try {
                if (categoryCode) {
                    await axiosInstance.delete(this.apiCategory(categoryCode));

                    await this.getCategories();
                    await this.getMetaCategories();
                }
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async deleteMetaCategory(metaCategoryCode: string) {
            try {
                if (metaCategoryCode) {
                    await axiosInstance.delete(this.apiMetaCategory(metaCategoryCode));

                    await this.getMetaCategories();
                }
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },

        async init() {
            await this.getCategories();
            await this.getMetaCategories();
        },
    },
});
