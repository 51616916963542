<template>
    <div class="pos-panel__details">
        <div class="pos-panel__section">
            <div class="pos-panel__card">
                <div class="pos-panel__card__row" :class="{ 'pos-panel__card__row--border': subscriptionStatus.label === 'activeSubscribed' }">
                    <subscription-tag :subscription-status="posDetails.subscribed" :active-status="posDetails.active"></subscription-tag>
                    <p v-if="subscriptionStatus.label === 'activeSubscribed'" class="fsb-typo-para-regular pos-panel__info--gray pos-panel__info--left">{{ statusSinceDate }}</p>
                    <p v-else-if="subscriptionStatus.label === 'inactiveUnsubscribed'" class="fsb-typo-para-regular pos-panel__info--gray pos-panel__info--left">
                        {{ t('withoutSubscription') }}
                    </p>
                    <p v-else-if="subscriptionStatus.label === 'inactiveSubscribed'" class="fsb-typo-para-regular pos-panel__info--gray pos-panel__info--left">
                        {{ t('withSubscription') }}
                    </p>
                </div>
                <div class="pos-panel__card__row" v-if="subscriptionStatus.label === 'activeSubscribed'">
                    <p class="fsb-typo-btn-principal">{{ t('price') }}</p>
                    <div>
                        <p class="fsb-typo-para-regular pos-panel__info--price" v-cents-to-euro.showCents="formattedDetails.subscriptionInfo.price || 0"></p>
                        <!-- <icon-button icon-type="edit" variant="secondary" @click="editPrice" /> -->
                    </div>
                </div>
                <div class="pos-panel__card__row" v-if="subscriptionStatus.label === 'activeSubscribed'">
                    <p class="fsb-typo-btn-principal">{{ t('iban') }}</p>
                    <p class="fsb-typo-para-medium pos-panel__info--iban" v-bank-card="formattedDetails.subscriptionInfo.iban"></p>
                </div>
            </div>
        </div>

        <div class="pos-panel__section" v-if="formattedDetails.adMandate">
            <div class="pos-panel__card">
                <div class="pos-panel__card__row">
                    <p class="fsb-typo-btn-principal">{{ t('mandate') }}</p>
                    <mandate-tag :mandate-status="formattedDetails.adMandate?.status" :validity="formattedDetails.adMandate?.validity" />
                </div>
            </div>
        </div>

        <div class="pos-panel__section">
            <zone-list-in-pos-details :point-code="posDetails.point_code" :pos-name="posDetails.details.name" />
        </div>

        <div class="pos-panel__section" v-if="posDetails?.users">
            <user-list-in-pos-details :users-list="posDetails.users" :point-code="posDetails.point_code" @switch-to-users-list="emit('showUserList')" />
        </div>

        <div class="pos-panel__section">
            <infos-title :title="t('generalInfo')" hide-icon :padding-bottom="false"></infos-title>
            <div class="pos-panel__info-row" v-for="(keyValue, index) of Object.entries(formattedDetails.generalInfo)" :key="`pos-details-general-info-${index}`">
                <p class="fsb-typo-btn-principal">{{ t(keyValue[0]) }}</p>
                <p v-if="checkTruthy(keyValue[1])" class="fsb-typo-para-regular">{{ keyValue[1] }}</p>
            </div>
        </div>

        <div class="pos-panel__section" v-if="checkTruthy(formattedDetails.replication)">
            <infos-title :title="t('replication')" hide-icon :padding-bottom="false"></infos-title>
            <div class="pos-panel__info-row">
                <p class="fsb-typo-btn-principal">{{ t('replication') }}</p>
                <p check class="fsb-typo-para-regular">{{ formattedDetails.replication }}</p>
            </div>
        </div>

        <div class="pos-panel__section">
            <infos-title :title="t('infoInvoice')" hide-icon :padding-bottom="false"></infos-title>
            <div class="pos-panel__info-row" v-for="(keyValue, index) of Object.entries(formattedDetails.invoiceInfo)" :key="`pos-details-invoice-info-${index}`">
                <p class="fsb-typo-btn-principal">{{ t(keyValue[0]) }}</p>
                <p v-if="checkTruthy(keyValue[1])" class="fsb-typo-para-regular">{{ keyValue[1] }}</p>
            </div>
        </div>

        <div class="pos-panel__section">
            <infos-title :title="t('contactInvoice')" hide-icon :padding-bottom="false"></infos-title>

            <div class="pos-panel__info-column" v-for="(keyValue, index) of Object.entries(formattedDetails.invoiceContact)" :key="`pos-details-invoice-contact-${index}`">
                <p class="fsb-typo-para-regular pos-panel__info--bold">{{ t(keyValue[0]) }}</p>
                <p v-if="!checkTruthy(keyValue[1].value)" class="fsb-typo-para-regular pos-panel__info--gray pos-panel__info--gray--padding">
                    {{ 'noRegisteredContact' }}
                </p>
                <div v-else class="pos-panel__info-row">
                    <p class="fsb-typo-btn-secondary">{{ keyValue[1].value }}</p>
                    <p class="fsb-typo-para-regular" v-if="keyValue[1].label">{{ keyValue[1].label }}</p>
                </div>
            </div>
        </div>

        <!-- <div class="pos-panel__section pos-panel__section--row pos-panel__section--right">
            <ui-button :label="t('delete')" variant="white" />
            <fsb-dropdown
                :linked-button="linkedButtonUnsubscribe"
                :list="UNSUBSCRIBE_DROPDOWN_LIST"
                :select-one="true"
                :close-dropdown-on-item-click="true"
                dropdown-position="left"
                close-dropdown-on-click-outside
                no-select
            />
        </div> -->
    </div>
</template>

<script setup lang="ts">
    import getLatestMandate from '@/helpers/getLatestMandate';
    import { iPos } from '@/interfaces/global';
    import { ComputedRef, Ref, computed, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import MandateTag from '../SharedUi/MandateTag.vue';
    import SubscriptionTag, { SUBSCRIPTION_TAG } from '../SharedUi/SubscriptionTag.vue';
    import InfosTitle from '../Sidebar/InfosTitle.vue';
    import UserListInPosDetails from './UserListInPosDetails.vue';
    import ZoneListInPosDetails from './ZoneListInPosDetails.vue';

    const { t } = useI18n({ useScope: 'global' });

    const props = defineProps<{
        pos: iPos;
    }>();

    const emit = defineEmits(['showUserList']);

    const checkTruthy = (value: any): boolean => !!value && !value?.includes('undefined');

    const posDetails: Ref<iPos> = ref(props.pos);

    const formattedDetails = computed(() => {
        const generalInfo = {
            name: posDetails.value?.details?.name,
            code: posDetails.value?.details?.point_code,
            address: `${posDetails.value?.details?.address}, ${posDetails.value?.details?.zipcode} ${posDetails.value?.details?.city}`,
            financialGroup: posDetails.value?.details?.financial_group,
            region: posDetails.value?.details?.region,
            district: posDetails.value?.details?.district,
        };

        const invoiceInfo = {
            subscriber: posDetails.value?.invoiceInfo?.name,
            email: posDetails.value?.invoiceInfo?.email,
            socialReason: posDetails.value?.invoiceInfo?.company_name,
            siret: posDetails.value?.adMandate?.find((mandate) => !!mandate.details.my_manda_informations.siret)?.details.my_manda_informations.siret,
            addressInvoice: posDetails.value?.invoiceInfo?.address,
        };

        const invoiceContact = {
            debitNotification: {
                value: posDetails.value?.accountingInfo?.email,
                label: t('subscriber'),
            },
            contactAccounting: {
                value: posDetails.value?.accountingInfo?.email,
                label: undefined,
            },
        };

        const subscriptionInfo = {
            status: SUBSCRIPTION_TAG(posDetails.value.subscribed, posDetails.value.active),
            price: posDetails.value?.invoiceInfo?.subscription?.amount / 100,
            iban: posDetails.value?.invoiceInfo?.sepa_debit?.last4,
        };

        const replication = `${posDetails.value?.replication?.name} - ${posDetails.value?.replication?.address}`;

        const adMandate = getLatestMandate(posDetails.value?.adMandate ?? []);

        return {
            subscriptionInfo,
            generalInfo,
            replication,
            invoiceInfo,
            invoiceContact,
            adMandate,
        };
    });

    const subscriptionStatus = computed(() => formattedDetails.value.subscriptionInfo.status);

    const statusSinceDate: ComputedRef<string> = computed(() => '');

    const editPrice = () => {
        // Edit Price here
    };

    const linkedButtonUnsubscribe = ref({
        type: 'ui',
        variant: 'secondary',
        label: t('unsubscribe'),
    });

    const UNSUBSCRIBE_DROPDOWN_LIST = [
        {
            label: t('temporaryUnsubscribe'),
            onClick: () => {
                // Unsubscribe here
            },
        },
        {
            label: t('unsubscribe'),
            onClick: () => {
                //
            },
        },
    ];
</script>

<style lang="scss">
    .pos-panel {
        &__section {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 40px;

            &--row {
                flex-direction: row;
            }

            &--column {
                flex-direction: column;
            }

            &--right {
                justify-content: flex-end;

                .position-relative {
                    width: auto !important;
                }
            }
        }

        &__card {
            border-radius: 12px;
            border: 1px solid var(--grayscale-gray-light);

            &__row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px;

                div {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                }

                &--border {
                    position: relative;

                    &::after {
                        position: absolute;
                        content: '';
                        display: block;
                        width: calc(100% + 16px * 2);
                        height: 1px;
                        background-color: var(--grayscale-gray-light);
                        bottom: 0;
                        left: -16px;
                    }
                }
            }
        }

        &__info {
            &-row {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                gap: 8px;
                padding: 8px 0;

                p {
                    &.fsb-typo-para-regular {
                        color: var(--grayscale-gray-dark);
                    }

                    text-align: right;
                }

                &:empty {
                    display: none;
                }
            }

            &--bold {
                font-weight: 700 !important;
            }

            &--price {
                color: var(--grayscale-gray-dark);

                .--euro {
                    font-size: 10px;
                    color: var(--grayscale-gray-dark);
                }
            }

            &--gray {
                flex: 1;

                color: var(--grayscale-gray-medium) !important;
                font-style: italic !important;

                &--padding {
                    padding: 8px 0;
                }
            }

            &--left {
                margin-left: 8px;
            }

            &--iban {
                .--bank-number {
                    color: var(--grayscale-gray-dark) !important;
                    letter-spacing: -0.4px;
                }
            }
        }

        .pos-users-list {
            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;

                h3 {
                    font-weight: 700 !important;
                }
            }
        }

        .checkbox-card__content {
            display: flex;
            gap: 8px;

            p {
                &.fsb-typo-btn-principal {
                    color: var(--grayscale-black);
                }
                &.fsb-typo-para-regular {
                    color: var(--grayscale-gray-dark);
                }
            }
        }
    }
</style>
