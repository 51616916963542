<template>
    <div class="position-buttons-group">
        <icon-button icon-type="up" :on-click="() => !upDisabled && swapElements(currentIndex, previousIndex)" :disabled="upDisabled" />
        <icon-button icon-type="down" :on-click="() => !downDisabled && swapElements(currentIndex, nextIndex)" :disabled="downDisabled" />
    </div>
</template>

<script lang="ts" setup>
    import { Ref, ref, watch } from 'vue';
    import IconButton from '@/components/SharedUi/IconButton.vue';
    import { useCategoryStore } from '@/store';

    interface Props {
        upDisabled: boolean;
        downDisabled: boolean;
        currentIndex: number;
        nextIndex: number;
        previousIndex: number;
        elementsArray: any[];
    }

    const props = withDefaults(defineProps<Props>(), {
        upDisabled: false,
        downDisabled: false,
    });

    const emit = defineEmits(['update:elementsArray']);

    const categoryStore = useCategoryStore();

    const elementsArray: Ref<any[]> = ref(props.elementsArray);

    const swapElements = (indexA: number, indexB: number) => {
        // const temp = elementsArray.value[indexA];
        // elementsArray.value[indexA] = elementsArray.value[indexB];
        // elementsArray.value[indexB] = temp;

        emit('update:elementsArray', { elementsArray: elementsArray.value });

        if (elementsArray.value[indexA].hasOwnProperty('crea_category_code') || elementsArray.value[indexB].hasOwnProperty('crea_category_code')) {
            // Then it is category
            categoryStore.swapCategories(elementsArray.value[indexA], elementsArray.value[indexB]);
        } else {
            // Else it is metacategory
            categoryStore.swapMetaCategories(elementsArray.value[indexA], elementsArray.value[indexB]);
        }
    };

    watch(
        () => props.elementsArray,
        (newVal: any[]) => {
            elementsArray.value = newVal;
        }
    );
</script>

<style scoped lang="scss">
    .position-buttons-group {
        display: flex;
        margin-left: 24px;

        & > *:not(:last-child) {
            margin-right: 4px;
        }
    }
</style>
