<template>
    <layout-with-fixed-nav-bar :show-nav-bar="route.meta.showNavBar" nav-bar-width="200px" padding="40px">
        <template #nav-bar>
            <ui-button :label="t('back')" leftIcon="chevron-left" variant="white" class="mb-3" @click="goHome()" />
            <admin-nav-bar ref="nav" :brands-list="brandsList" :current-brand="currentBrand" :change-brand="changeBrand" :sections="adminNavigationSections" :default-active-section="activeSection">
                <template #router-links>
                    <admin-nav-bar-themes></admin-nav-bar-themes>
                </template>
            </admin-nav-bar>
        </template>

        <template #main-content>
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <transition name="fadeView" mode="out-in">
                        <component :is="Component" :key="route.path" />
                    </transition>
                </keep-alive>
            </router-view>
        </template>
    </layout-with-fixed-nav-bar>
</template>

<script lang="ts" setup>
    import { RouterView, isNavigationFailure, useRoute } from 'vue-router';
    import { useGlobalStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import router from '@/router/index';
    import { ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';

    import useAdminNavigation from '@/configs/adminNavigation';

    import AdminNavBarThemes from './router/router-link/adminNavBarThemes.vue';

    const { t } = useI18n({ useScope: 'global' });

    const route = useRoute();
    const nav = ref(null);
    const globalStore = useGlobalStore();

    const { adminNavigationSections } = useAdminNavigation();

    const getActiveSection = (path: string): string => {
        const prefixPattern = '/brand/([^/]+)/';
        const match = path.match(new RegExp(`^${prefixPattern}(.*)$`));
        return (match && match[2]) ?? adminNavigationSections.value[0].id;
    };

    const activeSection = ref<string>(getActiveSection(route.path));

    const { brand: currentBrand, brands: brandsList } = storeToRefs(globalStore);

    const changeBrand = (brand: string) => {
        globalStore.setBrand(brand);

        // if(nav.value) (nav.value as any).switchActiveSection(('users'));
        // router.push({ name: 'ManageUsers', params: { brandCode: brand } });

        router.push({ name: route.name as string, params: { brandCode: brand } });
    };

    const goHome = () => {
        router.push('/home');
    };

    watch(
        () => route.path,
        (newPath) => {
            activeSection.value = getActiveSection(newPath);
        }
    );
</script>

<style lang="scss">
    @import url('./style/index.scss');
</style>
