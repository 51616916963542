<template>
    <div class="posList-list">
        <div class="posList-list__header">
            <div class="posList-list__header__first">
                <search-input
                    id="posList-search-input"
                    :placeholder="t('searchPos')"
                    width="2px"
                    :search-in-list="[
                        {
                            list: posList.map((pos) => pos.details).filter(Boolean),
                            key: 'point_code',
                        },
                        { list: posList.map((pos) => pos.details).filter(Boolean), key: 'name' },
                    ]"
                    @update:searchInList="updateFilterKeyword"
                />
                <fin-groups-filter ref="financialComponent" v-if="itemsToFilter.length" :items-to-filter="itemsToFilter" @update:financialGroup="updateFilterFinGroup" />
            </div>
            <div class="posList-list__header__second">
                <switch-btn @switcher-value="filterPosSwitch" />
                <fsb-dropdown class="linesFilter" :linkedInput="tableLinesLabel[0].label" :list="tableLinesLabel" :select-one="true" :close-dropdown-on-item-click="true" v-model="tableRowsPerPage" />
            </div>
        </div>
        <dynamic-table
            :fields="posListFields"
            :items="finalPosTable"
            :per-page="tableRowsPerPage[0].value"
            unique-key="uid"
            @item-clicked="showPosDetails"
            with-actions-column
            :empty-text="t('noPosFound')"
            :empty-filtered-text="t('noPosFound')"
        >
            <template #actions="item">
                <icon-button icon-type="profile" variant="secondary" @click="() => showUserListOfPos(item.item)" />
                <icon-button v-if="item.item.active !== 1" icon-type="delete" variant="secondary" @click="() => openPosDeleteModal(item)" />
                <fsb-dropdown
                    v-else
                    class="posList-list__dropdown-more"
                    :linked-button="DROPDOWN_MORE_BUTTON"
                    :list="generateDropdownMoreList(item)"
                    select-one
                    close-dropdown-on-item-click
                    close-dropdown-on-click-outside
                    no-select
                />
            </template>
        </dynamic-table>

        <pos-panel v-if="showPosPanel && posDetails" v-model:show-pos-panel="showPosPanel" :pos="posDetails" />
    </div>
</template>

<script lang="ts" setup>
    import { iFinancialGroup, iPos, iGenericOption } from '@/interfaces/global';
    import { useGlobalStore, usePosStore } from '@/store';
    import { intersectionBy, isEqual } from 'lodash';
    import { storeToRefs } from 'pinia';
    import { Ref, computed, ref, watch, onUnmounted } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { SUBSCRIPTION_TAG } from '../SharedUi/SubscriptionTag.vue';
    import SwitchBtn from '../SwitchBtn.vue';
    import PosPanel from './PosPanel.vue';
    import FinGroupsFilter from '../SharedUi/FinGroupsFilter.vue';

    const { t } = useI18n({ useScope: 'global' });

    const posStore = usePosStore();
    const { posList } = storeToRefs(posStore);

    const globalStore = useGlobalStore();
    const { financialGroups } = storeToRefs(globalStore);

    const showPosPanel: Ref<'details' | 'userList' | false> = ref(false);
    const posDetails: Ref<iPos | null> = ref(null);

    const DROPDOWN_MORE_LIST = [
        {
            label: t('duplicatePos'),
            value: 'duplicatePos',
        },
        {
            label: t('deletePos'),
            value: 'deletePos',
        },
    ];

    const DROPDOWN_MORE_BUTTON = ref({
        type: 'icon',
        icon: 'dots',
        variant: 'secondary',
    });

    const filterPos = ref(posList.value);
    const posListFields = [
        { key: 'details.name', label: t('pos'), sortable: true },
        { key: 'point_code', label: t('code'), sortable: true },
        { key: 'details.financial_group', label: t('financialGroup', 2), sortable: true },
        { key: 'users_number', label: t('users'), sortable: true },
        {
            key: 'subscribed',
            label: t('subscription'),
            sortable: true,
            getContent: (pos: any) => {
                if (!isNaN(pos.subscribed) && !isNaN(pos.active)) {
                    const tag = SUBSCRIPTION_TAG(pos.active, pos.subscribed);
                    return {
                        name: 'tag-label',
                        props: {
                            ...tag,
                            label: t(tag.label),
                        },
                    };
                }
                return null;
            },
        },
        { key: 'price', label: t('price'), sortable: true, formatPrice: true },
    ];

    const itemsToFilter = computed(() => posList.value.filter((pos) => !!pos?.details?.financial_group).map((pos) => ({ ...pos, value: pos.details.financial_group })));
    const filterResultsOfFinGroup = ref<string[] | null>(null);
    const filterResultOfKeyword = ref<iPos[] | null>(null);

    const tableLinesLabel = ref([
        { label: '25 ' + t('lines'), value: 25 },
        { label: '50 ' + t('lines'), value: 50 },
        { label: '75 ' + t('lines'), value: 75 },
        { label: '100 ' + t('lines'), value: 100 },
    ]);
    const tableRowsPerPage = ref([tableLinesLabel.value[0]]);

    const financialComponent = ref(null);

    const ACTIVE_FILTER: { [key: string]: number } = { inactive: 0, active: 1, all: -1 };
    const activeFilter = ref(ACTIVE_FILTER.all);

    const filterPosSwitch = (status: string) => {
        activeFilter.value = ACTIVE_FILTER[status];
    };

    const finalPosTable = computed(() => {
        let finalResult = filterPos.value ?? [];

        return activeFilter.value !== -1 ? finalResult.filter((pos) => pos.active === activeFilter.value) : finalResult;
    });

    const onMoreAction = (dropdownItem: any, item: any) => {
        const { item: pos } = item;

        if (dropdownItem && dropdownItem.value === 'deletePos') {
            // To be developed - completly delete a point_code from the table
        } else if (dropdownItem && dropdownItem.value === 'duplicatePos') {
            console.log('duplicatePos', pos);
        }
    };

    const generateDropdownMoreList = (selectedItem: any) => {
        return DROPDOWN_MORE_LIST.map((item) => ({ ...item, onClick: () => onMoreAction(item, selectedItem) }));
    };

    const showPosDetails = async (_index: number, pos: iPos) => {
        posDetails.value = (await posStore.getPos(pos)) || {};
        showPosPanel.value = 'details';
    };

    const showUserListOfPos = async (pos: iPos) => {
        posDetails.value = (await posStore.getPos(pos)) || {};
        showPosPanel.value = 'userList';
    };

    const openPosDeleteModal = (pos: any) => {
        console.log('openPosDeleteModal', pos);
    };

    const updateFilterFinGroup = (filterResult: iGenericOption[] | null) => {
        filterResultsOfFinGroup.value = filterResult?.map((item) => item.unique_point_code) || null;
    };

    const updateFilterKeyword = ({ searchResult, searchInput }: { searchResult: any[]; searchInput: [] }) => {
        filterResultOfKeyword.value = !searchInput
            ? posList.value
            : [...new Set(searchResult.flat())].map((pos) => posList.value.find((pos_) => pos.point_code === pos_.point_code)).filter(Boolean) ?? null;
    };

    watch([() => filterResultsOfFinGroup.value, () => filterResultOfKeyword.value], ([resFin, resKeyword]) => {
        if (resFin || resKeyword) {
            const withKeyword = resKeyword?.length > 0 ? resKeyword : posList.value;

            const withFinGroup =
                resFin?.length > 0
                    ? posList.value.filter((item) => {
                          const filterByFinGroupRes = posList.value.length && resFin.includes(item.unique_point_code);

                          return filterByFinGroupRes;
                      })
                    : posList.value;

            filterPos.value = intersectionBy(withKeyword, withFinGroup, 'unique_point_code');
        } else {
            filterPos.value = posList.value;
        }
    });

    watch(
        () => posList.value,
        (newPosList, oldPosList) => {
            if (isEqual(newPosList, oldPosList)) return;
            filterPos.value = newPosList;
        }
    );

    onUnmounted(() => {
        financialComponent.value?.resetFinancialDropdown();
    });
</script>

<style lang="scss">
    .posList-list {
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            &__first,
            &__second {
                display: flex;
                gap: 16px;
            }
        }

        .linesFilter {
            width: 110px !important;
            > div {
                width: 110px !important;
                cursor: pointer;
                &.fsb-dropdown {
                    &__trigger {
                        overflow: hidden !important;
                    }
                    &__trigger-node {
                        padding: 13px 10px !important;
                        width: 110px !important;
                    }
                }
            }
        }

        .groupFilter {
            > div {
                width: 200px !important;
                cursor: pointer;
                &.fsb-dropdown {
                    overflow: hidden !important;
                }
            }
        }

        .fsb-dynamic-table {
            .position-relative:has(.fsb-dropdown) {
                width: auto !important;
            }
        }

        &__dropdown-more {
            .--right {
                transform: translate(40px, 90px) !important;
            }
        }
    }
</style>
