<template>
    <div class="zone-editor-overlay">
        <zone-editor
            :action-type="zoneStore.actionType"
            :pos-name="zoneStore.posName"
            :zone-data="zoneData"
            :logo-url="`${LOGO_URL}${globalStore.brand}.svg`"
            :tiles-url="TILES_URL"
            :get-polygon="zoneStore.getPolygon"
            @action-back-button="zoneStore.closeZoneEditor"
            @create-zone="createZone"
            @update-zone="updateZone"
            @delete-zone="deleteZone"
        />
    </div>
</template>

<script setup lang="ts">
    import { useGlobalStore } from '@/store';
    import { useZoneStore } from '@/store/views/zone';
    import { ZoneData } from '@adcleek/front-zones-lib';
    import { storeToRefs } from 'pinia';
    import { inject } from 'vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });
    const snackBar: { open(variant: string, text: string, delay?: number): void } | undefined = inject('snackBar');

    const LOGO_URL = process.env.VUE_APP_BRAND_LOGO_URL;
    const TILES_URL = process.env.VUE_APP_TILES_URL;

    const globalStore = useGlobalStore();
    const zoneStore = useZoneStore();
    const { zoneData } = storeToRefs(zoneStore);

    const createZone = async (newZoneData: ZoneData) => {
        try {
            await zoneStore.createZone(newZoneData);
            await goBackAndshowSnackBar('savedZone');
        } catch (error) {
            console.log(error);
        }
    };

    const updateZone = async (newZoneData: ZoneData) => {
        try {
            await zoneStore.updateZone(newZoneData);
            await goBackAndshowSnackBar('updatedZone');
        } catch (error) {
            console.log(error);
        }
    };

    const deleteZone = async () => {
        try {
            await zoneStore.deleteZone(zoneData.value.data.code || '');
            await goBackAndshowSnackBar('deletedZone');
        } catch (error) {
            console.log(error);
        }
    };

    const goBackAndshowSnackBar = async (action: 'savedZone' | 'updatedZone' | 'deletedZone') => {
        await zoneStore.getZoneList(zoneData.value.data.pointCode);
        zoneStore.closeZoneEditor();
        snackBar?.open('success', t(action));
    };
</script>

<style scoped lang="scss">
    .zone-editor-overlay {
        position: fixed;
        background-color: var(--grayscale-background);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }
</style>
