<template>
    <div class="add-user-panel">
        <checkboxes-list
            id="users"
            :title="t('addUsers')"
            :search-input-placeholder="t('usernameOrEmail')"
            :search-keys="['firstname', 'lastname', 'email']"
            :items="usersToAdd"
            :confirm-label="t('confirm')"
            :confirm-selection-fn="addUsers"
            :cancel-selection-fn="() => emit('goBack')"
            :selected-label="t('usersSelected')"
            :items-to-filter="usersToFilterFinGroup"
            unique-key="uid"
            :nothing-found-label="t('noUserFound')"
            @onUpdateSelectedItems="updateSelectedUsers"
        >
            <template #default="{ item }">
                <div class="checkbox-card__content">
                    <p class="fsb-typo-btn-principal">{{ item.fullName || `${item.firstname} ${item.lastname}` }}</p>
                    <p class="fsb-typo-para-regular">{{ item.email }}</p>
                </div>
            </template>
        </checkboxes-list>
    </div>
</template>

<script setup lang="ts">
    import { iGenericOption, iPos, iUser, iUserOfPos } from '@/interfaces/global';
    import { usePosStore, useUsersStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { ComputedRef, Ref, computed, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import CheckboxesList from '../SharedUi/CheckboxesList.vue';

    const { t } = useI18n({ useScope: 'global' });
    const posStore = usePosStore();
    const usersStore = useUsersStore();
    const { users: brandUsers } = storeToRefs(usersStore);

    const props = defineProps<{
        userList: iUserOfPos[];
        pos: iPos;
    }>();

    const emit = defineEmits(['update:userList', 'goBack']);

    const selectedUsersToAdd: Ref<iUser[]> = ref([]);

    const usersToAdd: ComputedRef<iUser[]> = computed(() => getListUsersToAdd());

    const usersToFilterFinGroup: ComputedRef<iGenericOption[]> = computed(() => {
        return usersToAdd.value.map((userToAdd) => brandUsers.value.find((brandUser) => brandUser.uid === userToAdd.uid)).filter(Boolean);
    });

    const getListUsersToAdd = () => {
        const result = [];
        for (const user of brandUsers.value) {
            if (!props.userList.find((u) => u.uid === user.uid)) {
                result.push(user);
            }
        }
        return result;
    };

    const addUsers = async () => {
        const updatedPos = await posStore.addUsersToPos(
            props.pos,
            selectedUsersToAdd.value.map((user) => user.uid)
        );
        if (updatedPos) {
            emit('update:userList', updatedPos.users);
            emit('goBack');
        }
    };

    const updateSelectedUsers = ({ selectedItems }: { selectedItems: any }) => {
        selectedUsersToAdd.value = selectedItems;
    };
</script>

<style lang="scss">
    .add-user-panel {
        .checkboxes-list {
            &:nth-child(2) {
                padding-top: 0;
            }
        }

        .fsb-checkbox-card .--active * {
            color: var(--grayscale-black);
        }
    }
</style>
