<template>
    <div>
        <sidebar :show-sidebar="showSidebar" @updateShowSidebar="emit('update:showSidebar', false)">
            <template v-slot:body>
                <header-infos :title="t('posList')" :is-right-icon="false" icon-name="chevron-left" @action-button="emit('update:showSidebar', false)" />

                <infos-title :title="t('pos')" :label-icon="t('add')" icon-name="add" variant="primary" :is-right-icon="false" @actionButton="addPos()" />
                <template v-if="pointofinterest?.length">
                    <div v-for="(pos, index) in pointofinterest.filter(Boolean)" :key="pos.unique_point_code">
                        <list-item-with-role-label
                            :main-text="pos.details.name"
                            :secondary-text="pos.details.point_code + ' - ' + pos.details.city"
                            :role="pos.role"
                            :enable-icon-group-mode="true"
                            btn-icon="delete"
                            @btn-action="() => (modalDeleteData = { showModal: true, index, pos })"
                        />
                        <hr style="margin: 15px 0px" />
                        <modal-delete v-model:show-modal="modalDeleteData.showModal" @delete="deletePos(pos.point_code)">
                            <p>
                                {{ t('modalMessageToDeletePos', { name: pos.details.name }) }}
                                <br />
                                {{ t('irreversibleAction') }}
                            </p>
                        </modal-delete>
                    </div>
                </template>
                <div v-else class="notPosFoundBloc">
                    {{ t('noPosFound') }}
                </div>
            </template>
        </sidebar>

        <add-user-pos v-model:show-sidebar="showSidebarToAddUserPos" :brand="brand" :pointofsale="posToAdd" @closeSidebar="updateSidebar" />
    </div>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import HeaderInfos from '../Sidebar/HeaderInfos.vue';
    import InfosTitle from '../Sidebar/InfosTitle.vue';
    import ListItemWithRoleLabel from '../SharedUi/ListItemWithRoleLabel.vue';
    import ModalDelete from '../SharedUi/ModalDelete.vue';
    import AddUserPos from './AddUserPos.vue';
    import { iPointofinterest } from '@/interfaces/global';
    import { computed, ref, ComputedRef, Ref, inject } from 'vue';
    import { usePosStore } from '@/store';
    import { useUsersStore } from '@/store';
    const usersStore = useUsersStore();
    const posStore = usePosStore();
    const snackBar: { open(variant: string, text: string, delay?: number): void } | undefined = inject('snackBar');
    const { t } = useI18n({ useScope: 'global' });

    const props = defineProps<{
        showSidebar: boolean;
        pointofinterest: iPointofinterest[];
        brand: string;
        userUid: string;
    }>();

    const emit = defineEmits(['update:showSidebar', 'closeSidebar']);

    const showSidebarToAddUserPos: Ref<boolean> = ref(false);
    const pointofsale: Ref<iPointofinterest[]> = ref([]);
    const addPos = async () => {
        showSidebarToAddUserPos.value = true;
        pointofsale.value = await posStore.getPointOfSaleByBrand(props.brand);
    };
    const modalDeleteData = ref({ showModal: false, index: 0, pos: {} as any });

    const posToAdd: ComputedRef<iPointofinterest[]> = computed(() => {
        if (!props.pointofinterest) return pointofsale.value;
        else {
            return pointofsale.value.filter((pos: iPointofinterest) => {
                return !props.pointofinterest.some((pos2) => {
                    return pos.unique_point_code === pos2?.unique_point_code;
                });
            });
        }
    });
    const deletePos = async (pos: string) => {
        try {
            await usersStore.deleteOnePosOfUser(props.userUid, props.brand, pos);
            snackBar?.open('success', t('successMessageOfDelete'));
            emit('closeSidebar');
            modalDeleteData.value.showModal = false;
        } catch (error) {
            console.log(error);
            snackBar?.open('alert', t('defaultError'));
        }
    };

    const updateSidebar = () => {
        showSidebarToAddUserPos.value = false;
        emit('closeSidebar');
    };
</script>

<style lang="scss">
    .notPosFoundBloc {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
    }
</style>
