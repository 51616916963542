<template>
    <section class="brands-selector">
        <div class="brands-selector__header">
            <h2 class="header__brands-count fsb-typo-heading-3">{{ t('brandCount', { count: brands.length }) }}</h2>
            <search-input
                id="brands-selector-search-input"
                full-width
                :placeholder="t('searchForBrand')"
                inner-search-with-key="name"
                :search-in-list="[{ list: computedBrands, key: 'name' }]"
                @update:searchInList="updateFilterBrand"
                @update:emptyInput="updateFilterBrandWhenEmptyInput"
            />
        </div>

        <div class="brands-selector__selector">
            <h3 class="selector__header" v-b-toggle.collapse-1 variant="primary">
                <span class="fsb-typo-heading-6">{{ t('name') }}</span>
                <icon-button variant="white" :size="12" icon-type="chevron-down" />
            </h3>
            <transition name="fade">
                <b-collapse visible id="collapse-1" class="selector__collapse" v-if="filterBrand.length">
                    <div class="selector__brand" v-for="brand in filterBrand" :key="`selector__brand--${brand}`" @click="navigate(brand)">
                        <img :src="`${VUE_APP_BRAND_LOGO_URL}${brand}.svg`" />
                        <p class="fsb-typo-para-medium">{{ brand }}</p>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" size="2xs" class="selectedBrandIcon" />
                    </div>
                </b-collapse>
                <p v-else class="fsb-typo-para-regular fsb-col-gray-medium d-flex justify-content-center w-100 p-3">{{ t('noBrandFound') }}</p>
            </transition>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { computed, ref, Ref, ComputedRef } from 'vue';
    import { useGlobalStore } from '@/store';
    import { storeToRefs } from 'pinia';

    import { useI18n } from 'vue-i18n';
    import useAdminNavigation from '@/configs/adminNavigation';

    const { t } = useI18n({ useScope: 'global' });

    const emits = defineEmits(['update:selectBrand']);

    const { adminNavigationSections } = useAdminNavigation();

    const globalStore = useGlobalStore();

    const { brands } = storeToRefs(globalStore);

    const computedBrands: ComputedRef<{ name: string }[]> = computed(() => {
        return brands.value?.map((brand: string) => ({ name: brand }));
    });

    const filterBrand: Ref<string[]> = ref(brands.value);

    const updateFilterBrand = ({ searchResult, searchInput }: { searchResult: any[]; searchInput: string }) => {
        if (searchResult.length) {
            filterBrand.value = searchResult[0].map((brand: { name: string }) => brand.name);
        } else {
            filterBrand.value = brands.value;
        }
    };

    const updateFilterBrandWhenEmptyInput = () => {
        filterBrand.value = brands.value;
    };

    //Navigate to first configurated page
    const navigate = (brand: string) => {
        emits('update:selectBrand', brand);

        adminNavigationSections.value[0].onClick(brand);
    };
</script>

<style lang="scss">
    .brands-selector {
        display: flex;
        flex-direction: column;
        gap: 8px;

        * {
            margin: 0;
            padding: 0;
        }

        &__header {
            display: flex;
            width: 100%;
            align-items: center;

            & > * {
                width: 50%;
            }
        }

        &__selector {
            border-radius: 16px;
            background-color: var(--grayscale-white);

            .selector {
                &__header {
                    padding: 16px 24px;
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .fsb-icon-button {
                        padding: 0;
                    }
                }

                &__brand {
                    cursor: pointer;
                    padding: 24px;
                    border-top: thin solid var(--grayscale-gray-light);
                    border-bottom: thin solid var(--grayscale-gray-light);
                    display: flex;
                    gap: 8px;
                    align-items: center;

                    &:last-child {
                        border-bottom: none;
                    }

                    img {
                        width: 14px;
                        height: 14px;
                        aspect-ratio: 1;
                        display: inline-block;
                    }

                    p {
                        flex: 1;
                    }
                }
            }
        }
    }
</style>
