import { iAdminNavigationSection } from '@adcleek/front-storybook/dist/fsb-interfaces';
import { useI18n } from 'vue-i18n';
import { ref, Ref } from 'vue';
import router from '@/router/index';
import { useGlobalStore } from '@/store';
import { storeToRefs } from 'pinia';

export const useAdminNavigation = () => {
    const { t } = useI18n({ useScope: 'global' });

    const globalStore = useGlobalStore();

    const { brand: currentBrand } = storeToRefs(globalStore);

    const adminNavigationSections: Ref<iAdminNavigationSection[]> = ref([
        {
            id: 'users',
            name: t('users'),
            icon: 'users',
            onClick: (brand: string) => router.push({ name: 'ManageUsers', params: { brandCode: brand ?? currentBrand.value } }),
        },
        {
            id: 'pos',
            name: t('pos'),
            icon: 'pos',
            onClick: (brand: string) => router.push({ name: 'ManagePos', params: { brandCode: brand ?? currentBrand.value } }),
        },
        {
            id: 'themes',
            name: t('themes'),
            icon: 'theme',
            onClick: () => {
                //
            },
        },
    ]);

    return {
        adminNavigationSections,
    };
};

export default useAdminNavigation;
