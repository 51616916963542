import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-252b2ab0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-center align-items-center login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_form, {
          onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_group, {
              label: _ctx.$t('email'),
              "label-for": "input-email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_input, {
                  id: "input-email",
                  modelValue: _ctx.form.login,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.login) = $event)),
                  type: "email",
                  placeholder: "Enter email",
                  required: ""
                }, null, 8, ["modelValue"])
              ], undefined, true),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_b_form_group, {
              label: _ctx.$t('password'),
              "label-for": "input-password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_input, {
                  id: "input-password",
                  modelValue: _ctx.form.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
                  type: "password",
                  placeholder: "Enter password",
                  required: ""
                }, null, 8, ["modelValue"])
              ], undefined, true),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_b_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_group, {
                  label: "firstname",
                  "label-for": "firstname"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      id: "firstname",
                      modelValue: _ctx.fakeUser.firstname,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fakeUser.firstname) = $event)),
                      type: "text",
                      required: ""
                    }, null, 8, ["modelValue"])
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_form_group, {
                  label: "lastname",
                  "label-for": "lastname"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      id: "lastname",
                      modelValue: _ctx.fakeUser.lastname,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fakeUser.lastname) = $event)),
                      type: "text",
                      required: ""
                    }, null, 8, ["modelValue"])
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            }),
            _createVNode(_component_b_button, {
              variant: "primary",
              onClick: _ctx.onSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('login')), 1)
              ], undefined, true),
              _: 1
            }, 8, ["onClick"])
          ], undefined, true),
          _: 1
        }, 8, ["onSubmit"])
      ], undefined, true),
      _: 1
    })
  ]))
}