const randomAlphaNumeric = (length = 5) => {
    let s = '';
    Array.from({ length }).some(() => {
        s += Math.random().toString(36).slice(2);
        return s.length >= length;
    });
    return s.slice(0, length);
};

const formatForSearchValue = (value: string) => value.toLowerCase().trim();

const createObject = (key: string, value: any) => {
    const keys = key.split('.');
    const result = {};

    let current: any = result;

    for (let i = 0; i < keys.length; i++) {
        const nextKey = keys[i];
        if (i === keys.length - 1) {
            current[nextKey] = value;
        } else {
            current[nextKey] = {};
            current = current[nextKey];
        }
    }

    return result;
};

const downloadFile = (buffer: ArrayBuffer, filename: string) => {
    // Convert the buffer to a Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
};

export { randomAlphaNumeric, formatForSearchValue, createObject, downloadFile };
