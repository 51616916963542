<template>
    <div class="meta-category-accordion">
        <div class="meta-category-accordion__header" :class="{ '--with-border-bottom': innerExpanded }" @click="toggleExpand">
            <h2>
                <span v-if="categoriesCount > 0">
                    <font-awesome-icon v-if="!innerExpanded" icon="fa-solid fa-chevron-down" />
                    <font-awesome-icon v-else icon="fa-solid fa-chevron-up" />
                </span>
                <span class="h2">{{ groupName }}</span>
            </h2>
            <div class="meta-category-accordion__header__toolbar">
                <icon-button icon-type="edit" :on-click="() => onClickEdit(metaCategory)" variant="tertiary" />
                <icon-button icon-type="delete" :on-click="() => onClickDelete(metaCategory)" variant="tertiary" />

                <position-buttons-group
                    v-if="positionsButtonsGroupOptions"
                    :next-index="positionsButtonsGroupOptions.nextIndex"
                    :previous-index="positionsButtonsGroupOptions.previousIndex"
                    :current-index="positionsButtonsGroupOptions.currentIndex"
                    :elements-array="positionsButtonsGroupOptions.elementsArray"
                    :up-disabled="positionsButtonsGroupOptions.upDisabled"
                    :down-disabled="positionsButtonsGroupOptions.downDisabled"
                    @update:elements-array="onSwapElements"
                />
            </div>
        </div>
        <transition name="fadeY" mode="out-in">
            <transition-group tag="div" name="list" class="meta-category-accordion__content" v-if="innerExpanded && metaCategory.creacategories">
                <div v-for="(cat, index) in updatedCategories" :key="`category-line-${cat.creacategory_to_metacategory_code}`">
                    <category-item-line
                        :category-code="cat.crea_category_code"
                        :positions-buttons-group-options="{
                            upDisabled: index === 0,
                            downDisabled: index === updatedCategories.length - 1,
                            currentIndex: index,
                            nextIndex: index + 1,
                            previousIndex: index - 1,
                            elementsArray: updatedCategories,
                        }"
                        :open-modal-assign="openModalAssign"
                        @update:elements-array="onSwapCategories"
                        @delete:category="onDeleteCategory"
                    />
                </div>
            </transition-group>
        </transition>
    </div>
</template>

<script lang="ts" setup>
    import { iMetaCategoryDetails, iCategory } from '@/interfaces/global';
    import { ComputedRef, Ref, ref, computed, watch, onMounted } from 'vue';

    import PositionButtonsGroup from '@/components/SharedUi/PositionButtonsGroup.vue';
    import CategoryItemLine from '@/components/CategoriesUi/CategoryItemLine.vue';

    interface Props {
        metaCategory: iMetaCategoryDetails;
        expanded: boolean;
        positionsButtonsGroupOptions?: {
            upDisabled: boolean;
            downDisabled: boolean;
            currentIndex: number;
            nextIndex: number;
            previousIndex: number;
            elementsArray: any[];
        };
        openModalAssign: (categoryCode: string) => void;
    }

    const props = defineProps<Props>();

    const emit = defineEmits(['update:elementsArray', 'edit:metaCategory', 'delete:metaCategory', 'delete:category']);

    const categoriesCount: Ref<number> = ref(props.metaCategory.creacategories.length || 0);

    const innerExpanded: Ref<boolean> = ref(props.expanded);

    const updatedCategories: Ref<iCategory[]> = ref(props.metaCategory.creacategories ? [...props.metaCategory.creacategories].sort((a, b) => a.display_order - b.display_order) : []);

    const groupName: ComputedRef<string> = computed(() => props.metaCategory.name);

    const toggleExpand = () => {
        innerExpanded.value = !innerExpanded.value;
    };

    const onSwapElements = ({ elementsArray }: { elementsArray: any[] }) => {
        emit('update:elementsArray', { elementsArray });
    };

    const onSwapCategories = ({ elementsArray }: { elementsArray: any[] }) => {
        // updatedCategories.value = elementsArray;
    };

    const onClickEdit = (metaCategory: iMetaCategoryDetails) => {
        emit('edit:metaCategory', { metaCategory });
    };

    const onClickDelete = (metaCategory: iMetaCategoryDetails) => {
        emit('delete:metaCategory', { metaCategory });
    };

    const onDeleteCategory = ({ categoryCode }: { categoryCode: string }) => {
        emit('delete:category', { categoryCode });
    };

    watch(
        () => props.expanded,
        (newValue: boolean) => {
            innerExpanded.value = newValue;
        }
    );

    watch(
        () => props.metaCategory.creacategories,
        (updatedCreaCategories) => {
            if (updatedCreaCategories) {
                updatedCategories.value = [...updatedCreaCategories].sort((a, b) => a.display_order - b.display_order);
                categoriesCount.value = updatedCreaCategories.length;
            }
        }
    );

    onMounted(() => {
        innerExpanded.value = true;
    });
</script>

<style scoped lang="scss">
    .meta-category-accordion {
        margin-top: 8px;

        * {
            font-family: Inter;
            font-style: normal;
            letter-spacing: -0.4px;
            color: var(--Primary);
            margin: 0;
        }

        border-radius: 8px;
        border: 1px solid var(--grayscale-gray-regular);
        background: var(--grayscale-white);

        &__header {
            padding: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.--with-border-bottom {
                border-bottom: 1px solid var(--grayscale-gray-light);
            }

            h2 {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                * {
                    font-size: 12px;
                    font-weight: 700;
                    margin: 0;
                }

                span.h2 {
                    margin-left: 4px;
                }

                .svg-inline--fa {
                    width: 10px;
                    height: 10px;
                }
            }

            &__toolbar {
                display: flex;

                & > *:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }

        &__content {
        }
    }
</style>
