<template>
    <button type="button" class="ui-button btn" :class="computedClass" @click="onClick" @mouseover="onMouseOver" @mouseout="onMouseOut">
        <span class="ui-button__prefix-icon" v-if="$slots['prefix-icon']">
            <slot name="prefix-icon" />
        </span>

        <span class="ui-button__label">
            {{ label }}
        </span>
        <slot />

        <span class="ui-button__suffix-icon" v-if="$slots['suffix-icon']">
            <slot name="suffix-icon" />
        </span>
    </button>
</template>

<script lang="ts" setup>
    import { ComputedRef, computed } from 'vue';
    interface Props {
        label?: string;
        variant: string;
        disabled?: boolean;
        onClick?: () => void;
        onMouseOver?: () => void;
        onMouseOut?: () => void;
    }
    const props = defineProps<Props>();

    const computedClass: ComputedRef<string> = computed(() => {
        const classes = [];

        switch (props.variant) {
            case 'primary':
                classes.push('--primary', 'btn-dark');
                break;

            case 'secondary':
                classes.push('--secondary');
                break;

            default:
                break;
        }

        if (props.disabled) {
            classes.push('--disabled');
        }

        return classes.join(' ');
    });
</script>

<style scoped lang="scss">
    .ui-button {
        display: inline-block;
        padding: 12px 16px;
        border-radius: 12px;
        transition: all ease-in-out 0.32s;

        border: none !important;
        outline: none !important;
        box-shadow: none !important;


        &__prefix-icon {
            display: inline-block;
            margin-right: 9px;
        }

        &__suffix-icon {
            display: inline-block;
            margin-left: 9px;
        }

        &.--primary {
            * {
                color: var(--grayscale-white);
            }
        }

        &.--secondary {
            background-color: var(--grayscale-background);
        }

        &.--disabled {
            pointer-events: none;
            cursor: not-allowed;
            background-color: var(--grayscale-gray-regular);
        }
    }
</style>
