<template>
    <div class="d-flex justify-content-center align-items-center login">
        <b-card>
            <b-form @submit.prevent="onSubmit">
                <b-form-group :label="$t('email')" label-for="input-email">
                    <b-form-input id="input-email" v-model="form.login" type="email" placeholder="Enter email" required />
                </b-form-group>
                <b-form-group :label="$t('password')" label-for="input-password">
                    <b-form-input id="input-password" v-model="form.password" type="password" placeholder="Enter password" required />
                </b-form-group>

                <b-form>
                    <b-form-group label="firstname" label-for="firstname">
                        <b-form-input id="firstname" v-model="fakeUser.firstname" type="text" required />
                    </b-form-group>
                    <b-form-group label="lastname" label-for="lastname">
                        <b-form-input id="lastname" v-model="fakeUser.lastname" type="text" required />
                    </b-form-group>
                </b-form>
                <b-button variant="primary" @click="onSubmit">
                    {{ $t('login') }}
                </b-button>
            </b-form>
        </b-card>
    </div>
</template>

<script lang="ts">
    import { axiosInstance } from '@/axiosInstance';
    import { useAuthStore } from '@/store';
    import { defineComponent } from 'vue';

    export default defineComponent({
        setup() {
            const authStore = useAuthStore();
            return {
                authStore,
            };
        },

        data() {
            return {
                form: {
                    login: '',
                    password: '',
                },
                fakeUser: {
                    firstname: 'Demo',
                    lastname: 'RRMP',
                },
            };
        },
        methods: {
            async onSubmit() {
                try {
                    await this.authStore.login(this.form);
                    const user = (
                        await axiosInstance.get('/users/authenticated/user-info', {
                            headers: { Authorization: `Bearer ${this.authStore.getToken()}` },
                        })
                    ).data.data;

                    this.authStore.setUser(user);
                } catch (error) {
                    console.log(error);
                }
            },
        },
    });
</script>

<style lang="scss" scoped>
    .login {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
