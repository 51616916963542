import { axiosInstance, setHeaders } from '@/axiosInstance';
import { randomAlphaNumeric } from '@/helpers/tools';
import { iActivation, iBanner, iMedia, iModel, BrandGroups } from '@/interfaces/global';
import { useGlobalStore } from '@/store';
import { defineStore } from 'pinia';
import { useCategoryStore } from './category';
import { useCustomerStore } from './customer';

export const useThemeStore = defineStore('useThemeStore', {
    state: () => ({
        themes: [] as any,
        themeDetails: {} as any,
        activations: [] as iActivation[],
        medias: [] as iMedia[],
        categoriesAndMeta: [] as any,
        models: [] as iModel[],
        categories: [] as any,
        banners: [] as iBanner[],
        brandsGroups: [] as BrandGroups[],
    }),
    getters: {
        apiThemes() {
            const globalStore = useGlobalStore();
            return `/themes?brand=${globalStore.brand}`;
        },
        apiPatchTheme() {
            const globalStore = useGlobalStore();
            const url = `/themes/:crea_code?brand=${globalStore.brand}`;
            return (crea_code: string) => url.replace(':crea_code', crea_code);
        },
        apiDeleteTheme() {
            const globalStore = useGlobalStore();
            const url = `/themes/:crea_code?brand=${globalStore.brand}`;
            return (crea_code: string) => url.replace(':crea_code', crea_code);
        },
        apiActivations() {
            const globalStore = useGlobalStore();
            return `/themes/activations-operations?brand=${globalStore.brand}`;
        },
        // not yet implemented
        apiAddActivation() {
            const globalStore = useGlobalStore();
            return `/themes/crea-code/:crea_code/activator?brand=${globalStore.brand}`;
        },
        apiMedias() {
            return `/media/available-medias-with-configs`;
        },
        apiModels() {
            const globalStore = useGlobalStore();
            return `/models?brand=${globalStore.brand}`;
        },
        apiBanners() {
            const globalStore = useGlobalStore();
            const endpoint = `/banner/api/v1/brands/${globalStore.brand}/banners`;
            return endpoint;
        },
        apiUploadBanner() {
            return '/banner-upload/';
        },
        apiBannerFormat() {
            const globalStore = useGlobalStore();
            return (banner_code: string, format: string) => `/banner/display/${globalStore.brand}/${banner_code}/${format}/index.html`;
        },
        apiBannerDetails() {
            return [
                {
                    media_code: 'DSK',
                    media_format: '300x250',
                },
                {
                    media_code: 'MOB',
                    media_format: '320x480',
                },
                {
                    media_code: 'FBK',
                    media_format: '600x600',
                },
            ];
        },
        apiAssociateCreaToModel() {
            const globalStore = useGlobalStore();
            const url = `/models/crea/:crea_code/model-code/:model_code?brand=${globalStore.brand}`;
            return (crea_code: string, model_code: string) => url.replace(':crea_code', crea_code).replace(':model_code', model_code);
        },
        apiAssociateCreaToCategory() {
            const globalStore = useGlobalStore();
            const url = `/themes/crea-category/:crea_category_code/crea/:crea_code?brand=${globalStore.brand}`;
            return (crea_code: string, crea_category_code: string) => url.replace(':crea_code', crea_code).replace(':crea_category_code', crea_category_code);
        },
        apiDissociateCreaToCategory() {
            const globalStore = useGlobalStore();
            const url = `/themes/crea-to-category/:crea_to_category_code?brand=${globalStore.brand}`;
            return (crea_to_category_code: string) => url.replace(':crea_to_category_code', crea_to_category_code);
        },
        apiMetacatAndCat() {
            const globalStore = useGlobalStore();
            const url = `/meta-categories/crea-code/:crea_category_code/categories/metacategories/?brand=${globalStore.brand}`;
            return (crea_category_code: string) => url.replace(':crea_category_code', crea_category_code);
        },
        apiCategories() {
            const globalStore = useGlobalStore();
            const url = `/categories?brand=${globalStore.brand}`;
            return url;
        },
        apiTheme() {
            const globalStore = useGlobalStore();
            const url = `/themes/crea_code?brand=${globalStore.brand}`;
            return (crea_code: string) => url.replace('crea_code', crea_code);
        },
        apiBrandsGroups() {
            const globalStore = useGlobalStore();
            const BRAND_GROUP_TYPE = 5; // The value of type for a brand groups
            const url = `/customers/${globalStore.brand}/groups?type=${BRAND_GROUP_TYPE}`;
            return url;
        },
    },
    actions: {
        async getThemes() {
            try {
                this.themes = (await axiosInstance.get(this.apiThemes)).data;
            } catch (error) {
                console.log(error);
            }
        },

        async getActivations() {
            try {
                let activations = (await axiosInstance.get(this.apiActivations)).data;
                activations = Object.keys(activations).map((key: string) => {
                    return { ...activations[key], activator_code: key };
                });

                this.activations = activations;
            } catch (error) {
                console.log(error);
            }
        },

        async createActivation(crea_code: string, activator: string) {
            try {
                const newActivation = (await axiosInstance.post(this.apiAddActivation.replace(':crea_code', crea_code), { activator })).data;

                await this.getActivations();

                return newActivation;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },

        async getMedias() {
            try {
                const globalStore = useGlobalStore();
                setHeaders(axiosInstance, { brand: globalStore.brand });

                this.medias = (await axiosInstance.get(this.apiMedias)).data?.map((m: iMedia) => ({ ...m, media_support_code: m.media_category_code }));
            } catch (error) {
                console.log(error);
            }
        },

        async getCategoriesAndMetacategories(categoriesCode: string) {
            const response = await axiosInstance.get(this.apiMetacatAndCat(categoriesCode));
            this.categoriesAndMeta = response.data;
        },

        async getCategories() {
            try {
                const cat = await axiosInstance.get(this.apiCategories);
                this.categories = cat.data.data;
            } catch (error) {
                console.log(error);
            }
        },

        async getModels() {
            try {
                this.models = (await axiosInstance.get(this.apiModels)).data.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getBanners() {
            try {
                this.banners = (await axiosInstance.get(this.apiBanners)).data;
            } catch (error) {
                console.log(error);
            }
        },
        async getBrandsGroups() {
            try {
                this.brandsGroups = (await axiosInstance.get(this.apiBrandsGroups)).data;
            } catch (error) {
                console.log(error);
            }
        },
        async uploadBanner(file: File) {
            try {
                const globalStore = useGlobalStore();
                const formData = new FormData();

                formData.append('', file);
                formData.append('filename', file.name);
                formData.append('brand', globalStore.brand);
                formData.append('path', 'crea');

                const newBannerUrl = (await axiosInstance.post(this.apiUploadBanner as string, formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data.url;

                return newBannerUrl;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async associateCreaToModel(crea_code: string, model_code: string) {
            try {
                const newAssociation = (await axiosInstance.post(this.apiAssociateCreaToModel(crea_code, model_code))).data;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async associateCreaToCategory(crea_code: string, crea_category_code: string) {
            try {
                const newAssociation = (await axiosInstance.post(this.apiAssociateCreaToCategory(crea_code, crea_category_code))).data;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async dissociateCreaToCategory(crea_to_category_code: string) {
            try {
                const newDissociation = (await axiosInstance.delete(this.apiDissociateCreaToCategory(crea_to_category_code))).data;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async createTheme(payload: any, models: string[]) {
            try {
                const newTheme = await axiosInstance.post(this.apiThemes, payload);

                // Associate theme to models
                if (models) {
                    for await (const model_code of models) {
                        await this.associateCreaToModel(payload.crea_code, model_code);
                    }
                }

                // Associate theme to categories
                const { categoriesToAdd } = payload;

                delete payload.categories;
                delete payload.categoriesToRemove;
                delete payload.categoriesToAdd;

                if (categoriesToAdd) {
                    for await (const category_code of categoriesToAdd) {
                        await this.associateCreaToCategory(payload.crea_code, category_code);
                    }
                }

                await this.getThemes();

                return payload.crea_code;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async updateTheme(themeCode: string, payload: any, stepIndex: number) {
            try {
                if (stepIndex === 0) {
                    const { categoriesToRemove, categoriesToAdd } = payload;

                    if (categoriesToRemove?.length > 0) {
                        const categoryStore = useCategoryStore();
                        const creaToCategoryCodesToDelete = categoryStore.categories
                            .filter((c: any) => categoriesToRemove.includes(c.crea_category_code))
                            .map((c: any) => c.creas?.find((cc: any) => cc.crea_code === themeCode)?.crea_to_category_code);

                        // Dissociate theme to categories
                        for await (const crea_to_category_code of creaToCategoryCodesToDelete) {
                            await this.dissociateCreaToCategory(crea_to_category_code);
                        }
                    }

                    // Associate theme to categories
                    if (categoriesToAdd?.length > 0) {
                        for await (const category_code of categoriesToAdd) {
                            await this.associateCreaToCategory(themeCode, typeof category_code === 'string' ? category_code : category_code.crea_category_code);
                        }
                    }
                }

                delete payload.categories;
                delete payload.categoriesToRemove;
                delete payload.categoriesToAdd;

                const updatedTheme = await axiosInstance.patch(this.apiPatchTheme(themeCode), payload);

                return themeCode;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },
        async deleteTheme(themeCode: string) {
            try {
                if (themeCode) {
                    const deletedTheme = await axiosInstance.delete(this.apiDeleteTheme(themeCode));

                    await this.getThemes();

                    return 'delete successfully theme' + themeCode;
                }
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },

        async getTheme(crea_code: string) {
            try {
                const theme = await axiosInstance.get(this.apiTheme(crea_code));
                this.themeDetails = theme.data;
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        },

        async formatThemeDataForCreate(payload: any) {
            const { thumbnail_image: thumbnailImage } = payload;

            if (thumbnailImage) {
                const url_picture = await this.uploadBanner(thumbnailImage);
                payload.url_picture = url_picture;
                delete payload.thumbnail_image;

                payload.url_banner = payload.details.banners && payload.details.banners[0] ? this.apiBannerFormat(payload.details.banners[0], '300x250') : '';
                payload.url_banner_code = JSON.stringify(payload.details.banners || []);
                payload.banner_fields = '{}';
                payload.banner_details = JSON.stringify(payload.details.banners?.length && payload.media_support ? this.mapMedias(payload.media_support, payload.details.banners) : {});

                payload.details = JSON.stringify(payload.details);

                if (!payload.media_support) payload.media_support = [];

                payload.crea_code = this.createCreaCode();
            } else {
                return false;
            }

            return payload;
        },
        async formatThemeDataForEdit(payload: any, themeCode: string, stepIndex: number): Promise<any> {
            if (typeof payload.details === 'string') {
                try {
                    payload.details = JSON.parse(payload.details);
                } catch (error) {
                    payload.details = {};
                }
            }

            switch (stepIndex) {
                case 0:
                    delete payload.categories;
                    delete payload.thumbnail_image;
                    break;

                case 1:
                    try {
                        payload.banner_details = JSON.stringify(payload.details.banners?.length && payload.media_support ? this.mapMedias(payload.media_support, payload.details.banners) : {});
                    } catch (error) {
                        payload.banner_details = '{}';
                    }

                    delete payload.categories;
                    delete payload.thumbnail_image;
                    break;

                case 2:
                    if (typeof payload.details.banners === 'string') {
                        try {
                            payload.details.banners = JSON.parse(payload.details.banners);
                        } catch (error) {
                            payload.details.banners = [];
                        }
                    }

                    if (!Array.isArray(payload.details.banners)) {
                        payload.details.banners = [];
                    }

                    payload.url_banner_code = JSON.stringify(payload.details.banners);
                    try {
                        payload.banner_details = JSON.stringify(payload.details.banners.length && payload.media_support ? this.mapMedias(payload.media_support, payload.details.banners) : {});
                    } catch (error) {
                        payload.banner_details = '{}';
                    }

                    if (payload.thumbnail_image && typeof payload.thumbnail_image !== 'string') {
                        try {
                            const newImageUrl = await this.uploadBanner(payload.thumbnail_image);
                            if (newImageUrl) {
                                payload.url_picture = newImageUrl;
                            } else {
                                console.warn('⚠️ `uploadBanner` returned an invalid URL, keeping existing `url_picture`.');
                            }
                        } catch (error) {
                            console.error('❌ Error uploading `thumbnail_image`:', error);
                        }
                    }
                    delete payload.thumbnail_image;
                    break;

                default:
                    break;
            }

            try {
                if (typeof payload.details.banners === 'string') {
                    try {
                        payload.details.banners = JSON.parse(payload.details.banners);
                    } catch (error) {
                        payload.details.banners = [];
                    }
                }

                if (!Array.isArray(payload.details.banners)) {
                    payload.details.banners = [];
                }

                payload.details = JSON.stringify(payload.details);
            } catch (error) {
                payload.details = '{}';
            }

            return payload;
        },
        createCreaCode() {
            let crea_code = randomAlphaNumeric().toUpperCase();
            while (this.themes.some((theme: any) => theme.crea_code.toUpperCase() === crea_code)) {
                crea_code = randomAlphaNumeric().toUpperCase();
            }

            return crea_code;
        },
        mapMedias(medias: string[], banners: string[] | string): Record<string, string> {
            if (!medias || medias.length === 0 || !banners || banners.length === 0) return {};

            // Ensure banners is always an array
            const bannersArray = Array.isArray(banners) ? banners : JSON.parse(banners);

            return medias.reduce((acc: Record<string, string>, media_code: string) => {
                const media = this.apiBannerDetails.find((b) => b.media_code === media_code);
                if (media) {
                    // Select the first valid banner_code
                    const banner_code = bannersArray[0] || '';
                    acc[media_code] = this.apiBannerFormat(banner_code, media.media_format);
                }
                return acc;
            }, {});
        },
        async init() {
            await this.getThemes();
        },
        async initCreateThemeData() {
            const categoryStore = useCategoryStore();
            const customerStore = useCustomerStore();

            // Step 1
            await this.getActivations();
            await this.getModels();
            await this.getBrandsGroups();
            await categoryStore.getCategories();
            await categoryStore.getMetaCategories();
            await customerStore.getTradesModels();
            await customerStore.getTrades();

            // Step 2
            await this.getMedias();

            // Step 3
            await this.getBanners();
        },
    },
});
