import { axiosInstance, setHeaders } from '@/axiosInstance';
import { iPos } from '@/interfaces/global';
import { useAuthStore, useGlobalStore } from '@/store';
import { defineStore } from 'pinia';

export const usePosStore = defineStore('usePosStore', {
    state: () => ({
        posList: [] as iPos[],
    }),
    getters: {
        apiPosList() {
            const globalStore = useGlobalStore();
            return `/customers/brands/${globalStore.brand}/pos`;
        },
        apiPos() {
            const globalStore = useGlobalStore();
            return (pointCode: string) => `/customers/brands/${globalStore.brand}/pos/${pointCode}`;
        },
        apiUsersOfPos() {
            const globalStore = useGlobalStore();
            return (pointCode: string) => `/customers/users/brands/${globalStore.brand}/pos/${pointCode}`;
        },
        apiDeletePos() {
            return (code: string) => `/customers/user-of-pos/${code}`;
        },
        apiPosDetailsListByBrand() {
            return (uid: string, brand: string) => `/customers/user/${uid}/brands/${brand}/posDetails`;
        },
        apiPointOfSaleByBrand() {
            return (brand: string) => `/customers/pointofsale/brands/${brand}`;
        },
        apiAddUserToPos() {
            const globalStore = useGlobalStore();
            return (pointCode: string) => `/customers/users/brands/${globalStore.brand}/pos/${pointCode}`;
        },
    },
    actions: {
        async getPosList() {
            try {
                const authStore = useAuthStore();
                setHeaders(axiosInstance, { Authorization: `Bearer ${authStore.getToken()}` });
                const pos = (await axiosInstance.get(this.apiPosList)).data;
                this.posList = [...pos];
            } catch (error) {
                this.posList = [];
                console.log(error);
            }
        },

        async getPosDetailsByBrand(uid: string, brand: string) {
            try {
                const authStore = useAuthStore();
                setHeaders(axiosInstance, { Authorization: `Bearer ${authStore.getToken()}` });
                const posDetails = await axiosInstance.get(this.apiPosDetailsListByBrand(uid, brand));
                return posDetails.data;
            } catch (error) {
                this.posList = [];
                console.log(error);
            }
        },

        async getPos(pos: iPos) {
            try {
                const posDetails = (await axiosInstance.get(this.apiPos(pos.point_code))).data ?? {};
                return {
                    ...pos,
                    ...posDetails,
                };
            } catch (error) {
                console.log(error);
            }
        },

        async getUsersOfPos(pointCode: string) {
            try {
                return (await axiosInstance.get(this.apiUsersOfPos(pointCode))).data;
            } catch (error) {
                console.log(error);
            }
        },

        async getPointOfSaleByBrand(brand: string) {
            try {
                const pointofsaleByBrand = await axiosInstance.get(this.apiPointOfSaleByBrand(brand));
                return pointofsaleByBrand.data ?? [];
            } catch (error) {
                console.log(error);
            }
        },

        async updateUserRole(pointCode: string, uid: string, code: string, role: number) {
            try {
                return (await axiosInstance.put(this.apiUsersOfPos(pointCode), { uid, code, role })).data;
            } catch (error) {
                console.log(error);
            }
        },

        async addUsersToPos(pos: iPos, users: string[]) {
            try {
                await axiosInstance.post(this.apiUsersOfPos(pos.point_code), { uids: users });
                const updatedPos = await this.getPos(pos);
                this.getPosList();
                return updatedPos;
            } catch (error) {
                console.log(error);
            }
        },

        async init() {
            await this.getPosList();
            await useGlobalStore().getFinancialGroups();
        },

        async deleteUserOfPos(code: string) {
            try {
                await axiosInstance.delete(this.apiDeletePos(code));
                this.getPosList();
            } catch (error) {
                throw error;
            }
        },
    },
});
