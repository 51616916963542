import { iCategory, iCreaDetails } from '@/interfaces/global';
import { Ref } from 'vue';

type ThemeCode = string | string[];

type Category = {
    creas?: Array<{ crea_code: string }>;
    crea_category_code: string;
};

const getAssociatedCategoryCodes = (categories: Category[], targetThemeCode: ThemeCode): Set<string> => {
    const themeCodes = Array.isArray(targetThemeCode) ? targetThemeCode : [targetThemeCode];

    const matchingCategories = categories.filter((category) => {
        if (!category.creas) return false;

        return category.creas.some((crea) => themeCodes.includes(crea.crea_code));
    });

    const categoryCodes = matchingCategories.map((category) => category.crea_category_code);

    return new Set(categoryCodes);
};

const handleCategoryReduction = (alreadyAssociatedCats: Set<string>, categoriesFromValue: any[], dataValue: Ref<iCreaDetails>) => {
    const sanitizedCategoriesFromValue = categoriesFromValue.some((category) => category.hasOwnProperty('crea_category_code'))
        ? categoriesFromValue.map((category) => category?.crea_category_code)
        : [...categoriesFromValue];

    const catsToRemove = [...alreadyAssociatedCats].filter((c) => !sanitizedCategoriesFromValue.includes(c));
    const catsToAdd = sanitizedCategoriesFromValue.filter((c) => !alreadyAssociatedCats.has(c));

    dataValue.value.categoriesToRemove = catsToRemove;
    dataValue.value.categoriesToAdd = catsToAdd;
};

const handleCategoryAddition = (alreadyAssociatedCats: Set<string>, categoriesFromValue: string[], dataValue: Ref<iCreaDetails>) => {
    const catsToAdd = categoriesFromValue.filter((c) => !alreadyAssociatedCats.has(c));
    dataValue.value.categoriesToAdd = catsToAdd;
};

export const updateCategories = (id: number, value: { categories?: string[] }, themeCode: string | string[], categories: Ref<Category[]>, dataValue: Ref<iCreaDetails>) => {
    {
        if (id !== 0 || !value.categories || !themeCode) return;

        const alreadyAssociatedCats = getAssociatedCategoryCodes(categories.value, themeCode);
        const categoriesFromValue = value.categories;

        if (categoriesFromValue.length <= alreadyAssociatedCats.size) {
            handleCategoryReduction(alreadyAssociatedCats, categoriesFromValue, dataValue);
        } else {
            handleCategoryAddition(alreadyAssociatedCats, categoriesFromValue, dataValue);
        }
    }
};
