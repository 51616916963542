<template>
    <section class="manage-categories">
        <div class="categories-main">
            <div class="categories-main__header">
                <h1 class="fsb-typo-heading-1">{{ t('manageCategories') }}</h1>
                <ui-button variant="primary" :label="t('createGroup')" :on-click="openModalAdd" />
            </div>
            <div class="categories-main__create-new" v-if="!metaCategoriesCount">
                <p>{{ t('noCategory') }}</p>
                <ui-button variant="primary" :label="t('createCategory')" :on-click="openModalAdd" />
            </div>
            <div class="categories-main__list" v-else>
                <div class="categories-main__list__header">
                    <search-input
                        id="search-input-categories"
                        width="20%"
                        :placeholder="t('searchCategory')"
                        :search-in-list="[
                            { list: categories, key: 'name' },
                            { list: metaCategories, key: 'name' },
                        ]"
                        @update:searchInList="updateFilterLists"
                    />
                    <toggle-expand-button :expanded="expandAllCategoriesList" :on-click="toggleExpandCategoriesList" />
                </div>

                <transition-group name="list" tag="div" class="categories-main__list__content" v-if="updatedMetaCategories.length">
                    <div v-for="(metacat, index) in updatedMetaCategories" :key="`meta-category-accordion-${metacat.name}`">
                        <meta-category-accordion
                            ref="metaCatsAccordion"
                            :meta-category="metacat"
                            :expanded="expandAllCategoriesList"
                            :positions-buttons-group-options="{
                                upDisabled: index === 0,
                                downDisabled: index === updatedMetaCategories.length - 1,
                                currentIndex: index,
                                nextIndex: index + 1,
                                previousIndex: index - 1,
                                elementsArray: updatedMetaCategories,
                            }"
                            :open-modal-assign="openModalAssignWithCategoryCode"
                            @update:elements-array="onSwapMetacategories"
                            @edit:meta-category="onEditMetaCategory"
                            @delete:meta-category="onDeleteMetaCategory"
                            @delete:category="onDeleteCategory"
                        />
                    </div>
                </transition-group>

                <nothing-found v-else :text="t('noCategoryFound')" :cta="{ label: t('createGroup'), variant: 'primary', onClick: openModalAdd }" />
            </div>

            <category-modal :modal-show="modalShowAdd" :close-modal="closeModalAdd" type="create" />
            <category-modal :modal-show="modalShowEdit" :close-modal="onCloseModalEdit" type="edit" :meta-category="metaCategoryToEdit" />
            <category-modal-delete
                :modal-show="modalShowDelete"
                :close-modal="onCloseModalDelete"
                :meta-category="selectedMetaCategory"
                :meta-category-code="metaCategoryToDeleteCode"
                :category-code="categoryToDeleteCode"
            />
            <category-modal-assign :modal-show="modalShowAssign" :close-modal="closeModalAssignAndResetCategoryCode" :category-code="selectedCategoryCode" />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { ComputedRef, Ref, ref, watch, computed, inject } from 'vue';

    import CategoryModal from '@/components/CategoriesUi/CategoryModal.vue';
    import CategoryModalDelete from '@/components/CategoriesUi/CategoryModalDelete.vue';
    import CategoryModalAssign from '@/components/CategoriesUi/CategoryModalAssign.vue';
    import ToggleExpandButton from '@/components/CategoriesUi/ToggleExpandButton.vue';
    import MetaCategoryAccordion from '@/components/CategoriesUi/MetaCategoryAccordion.vue';

    import { useI18n } from 'vue-i18n';
    import { useCategoryStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { iCategory, iMetaCategoryDetails } from '@/interfaces/global';
    import { uniqBy } from 'lodash';

    const { t } = useI18n({ useScope: 'global' });

    const categoryStore = useCategoryStore();

    const { categories, metaCategories } = storeToRefs(categoryStore);

    const useModalDelete = inject('useModal');
    const { modalShow: modalShowDelete, openModal: openModalDelete, closeModal: closeModalDelete } = useModalDelete();

    const useModalAdd = inject('useModal');
    const { modalShow: modalShowAdd, openModal: openModalAdd, closeModal: closeModalAdd } = useModalAdd();

    const useModalEdit = inject('useModal');
    const { modalShow: modalShowEdit, openModal: openModalEdit, closeModal: closeModalEdit } = useModalEdit();

    const useModalAssign = inject('useModal');
    const { modalShow: modalShowAssign, openModal: openModalAssign, closeModal: closeModalAssign } = useModalAssign();

    const selectedCategoryCode: Ref<string> = ref('');

    const categoryToDeleteCode: Ref<string> = ref('');
    const metaCategoryToDeleteCode: Ref<string> = ref('');

    const metaCategoryToEdit: Ref<iMetaCategoryDetails | null> = ref(null);

    const openModalAssignWithCategoryCode = (categoryCode: string | undefined) => {
        if (categoryCode) {
            selectedCategoryCode.value = categoryCode;
            openModalAssign();
        }
    };

    const closeModalAssignAndResetCategoryCode = () => {
        selectedCategoryCode.value = '';
        closeModalAssign();
    };

    const metaCategoriesCount: ComputedRef<number> = computed(() => metaCategories.value.length);

    const expandAllCategoriesList = ref(true);

    const selectedMetaCategory: Ref<iMetaCategoryDetails | null> = ref(null);

    const metaCatsAccordion: Ref<any> = ref(null);

    const toggleExpandCategoriesList = () => {
        expandAllCategoriesList.value = !expandAllCategoriesList.value;
    };

    const updatedMetaCategories: Ref<iMetaCategoryDetails[]> = ref([...metaCategories.value].sort((a, b) => a.display_order - b.display_order));

    const onSwapMetacategories = ({ elementsArray }: { elementsArray: any[] }) => {
        // updatedMetaCategories.value = elementsArray;
    };

    const onEditMetaCategory = ({ metaCategory }: { metaCategory: iMetaCategoryDetails }) => {
        metaCategoryToEdit.value = metaCategory;

        setTimeout(() => {
            openModalEdit();
        }, 64);
    };

    const onDeleteMetaCategory = ({ metaCategory }: { metaCategory: iMetaCategoryDetails }) => {
        metaCategoryToDeleteCode.value = metaCategory.crea_metacategory_code;
        categoryToDeleteCode.value = '';
        openModalDelete();
    };

    const onDeleteCategory = ({ categoryCode }: { categoryCode: string }) => {
        const categoryToDelete: iCategory | undefined = categories.value.find((cat: iCategory) => cat.crea_category_code === categoryCode);

        if (categoryToDelete) {
            categoryToDeleteCode.value = categoryToDelete.crea_category_code;
            metaCategoryToDeleteCode.value = '';
            openModalDelete();
        }
    };

    const onCloseModalDelete = () => {
        metaCategoryToDeleteCode.value = '';
        categoryToDeleteCode.value = '';
        closeModalDelete();
    };

    const onCloseModalEdit = () => {
        metaCategoryToEdit.value = null;
        closeModalEdit();
    };

    const updateFilterLists = ({ searchResult, searchInput }: { searchResult: any[]; searchInput: string }) => {
        if (searchResult.length && searchInput.length) {
            const [categoriesResult, metaCategoriesResult] = searchResult;

            const metaCategoriesContainingCatResult = metaCategories.value.filter((metaCat: any) => {
                const categoriesCodes = categoriesResult?.map((cat: any) => cat.crea_category_code);
                return metaCat.creacategories.some((cat: any) => categoriesCodes?.includes(cat.crea_category_code));
            });

            updatedMetaCategories.value = uniqBy(
                [...(metaCategoriesResult ?? []), ...(metaCategoriesContainingCatResult ?? [])].sort((a, b) => a.display_order - b.display_order),
                'crea_metacategory_code'
            );
        } else if (searchInput.length) {
            updatedMetaCategories.value = [];
        } else {
            updatedMetaCategories.value = [...metaCategories.value].sort((a, b) => a.display_order - b.display_order);
        }
    };

    watch(
        () => metaCategories.value,
        (metaCats) => {
            updatedMetaCategories.value = [...metaCats].sort((a, b) => a.display_order - b.display_order);
        }
    );
</script>

<style lang="scss" scoped>
    .categories-main {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__create-new {
            padding: 24px 8px;
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                margin-bottom: 16px;
            }
        }

        &__list {
            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 24px;
            }

            &__content {
                position: relative;
            }
        }
    }
</style>
