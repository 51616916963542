<template>
    <div class="theme-tab" v-if="readyToShowThemes">
        <div class="theme-tab__header">
            <search-input
                id="themes-search-input"
                :placeholder="t('searchForTheme')"
                width="20%"
                :search-in-list="[{ list: computedThemes, key: 'label' }]"
                @update:searchInList="updateFilterThemes"
            />
            <switch-btn @switcher-value="filterThemeSwitch" />
        </div>

        <div class="theme-tab__table">
            <b-table hover :items="themeItems" :fields="themeFields" @row-clicked="themeDetails" show-empty borderless>
                <template #empty>
                    <nothing-found :text="t('noThemeFound')" />
                </template>
                <template #emptyfiltered>
                    <nothing-found :text="t('noThemeFound')" />
                </template>

                <template #head(themeName)="data">
                    <div class="head--theme-name">
                        <base-checkbox />
                        <span class="fsb-typo-heading-6">{{ data.label }}</span>
                    </div>
                </template>
                <template #head(actions)="data">
                    <div class="head--action fsb-typo-heading-6">{{ data.label }}</div>
                </template>
                <template #head()="data">
                    <div class="cell--head">
                        <span class="fsb-typo-heading-6">{{ data.label }}</span>
                    </div>
                </template>

                <template #cell(themeName)="data">
                    <div class="cell--theme-name">
                        <base-checkbox />
                        <span class="fsb-typo-para-medium">{{ data.value }}</span>
                    </div>
                </template>
                <template #cell(brand)="data">
                    <div class="cell--128 fsb-typo-para-medium">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(media)="data">
                    <div class="cell--128">
                        <span class="fsb-typo-para-medium" :class="{ 'cell--media': data.value.count > 0 }" :id="`theme-${data.value.theme}`"> {{ data.value.count }}</span>
                        <base-tooltip v-if="data.value.count" :target="`theme-${data.value.theme}`" :offset="-132">
                            <p v-for="media in data.value.mediasList" :key="`theme-${data.value.theme}-media-${media}`" class="fsb-typo-para-regular">{{ media }}</p>
                        </base-tooltip>
                    </div>
                </template>
                <template #cell(adcleekAvailability)="data">
                    <div class="cell--240 fsb-typo-para-medium">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(actions)="data">
                    <div class="cell--160 cell--action fsb-typo-para-medium">
                        <icon-button icon-type="delete" variant="white" :on-click="() => deleteTheme(data.value)" />
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import dayjs from 'dayjs';
    import isBetween from 'dayjs/plugin/isBetween';

    import { Ref, ComputedRef, ref, computed, watch } from 'vue';
    import { useThemeStore, useGlobalStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import router from '@/router/index';
    import { useI18n } from 'vue-i18n';
    import SwitchBtn from '@/components/SwitchBtn.vue';
    import { BrandGroups } from '@/interfaces/global';

    interface Props {
        triggerOpenModalDelete: () => void;
        readyToShowThemes: boolean;
    }

    const props = defineProps<Props>();

    const emit = defineEmits(['update:deleteTheme']);

    dayjs.extend(isBetween);

    const { t } = useI18n({ useScope: 'global' });
    const themeStore = useThemeStore();
    const globalStore = useGlobalStore();

    const { themes, medias, brandsGroups } = storeToRefs(themeStore);
    const { brand } = storeToRefs(globalStore);
    //Active filter button
    const ACTIVE_FILTER: { [key: string]: number } = { inactive: 0, active: 1, all: -1 };
    let activeFilter = ref(ACTIVE_FILTER.all);

    const filterThemeSwitch = (themeStatus: string) => {
        activeFilter.value = ACTIVE_FILTER[themeStatus];
    };

    //Theme fields
    const themeFields = [
        { key: 'themeName', label: t('themeName'), sortable: true },
        { key: 'brand', label: t('brand'), sortable: true },
        { key: 'media', label: t('medias'), sortable: true },
        { key: 'adcleekAvailability', label: t('adcleekAvailability'), sortable: true },
        { key: 'actions', label: t('actions') },
    ];

    const computedThemes: ComputedRef<any[]> = computed(() => themes.value);

    const filterThemes: Ref<any[]> = ref(themes.value);

    const updateFilterThemes = ({ searchResult, searchInput }: { searchResult: any[]; searchInput: [] }) => {
        filterThemes.value = !searchInput ? computedThemes.value : searchResult[0];
    };

    //Filter theme items
    let themeFilter = ref('');
    const themeItems = computed(() => {
        let tab: any = [];
        filterThemes.value?.forEach((theme) => {
            const isActive = dayjs().isBetween(theme.validity_start_date, theme.validity_end_date);

            let brand = '';
            if (theme.details?.groups?.length && theme.details?.groups.includes('all')) {
                brand = t('allBrands');
            } else if (theme.details?.groups?.length) {
                brand = brandsGroups.value.find((brand: BrandGroups) => brand.code === theme.details?.groups[0])?.name as string;
            } else {
                brand = '--';
            }

            tab.push({
                code: theme.crea_code,
                isActive,
                themeName: theme.label,
                brand: brand,
                media: {
                    count: theme.media_support && Object.keys(theme.media_support).length ? Object.keys(theme.media_support).length : 0,
                    mediasList: theme.media_support ? Object.values(theme.media_support).map((code: string) => medias.value.find((media) => media.media_support_code === code)?.name ?? code) : null,
                    theme: theme.crea_code,
                },
                adcleekAvailability: `${t('from')} ${dayjs(theme.validity_start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${t('until')} ${dayjs(theme.validity_end_date, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY'
                )}`,
                actions: theme.crea_code,
            });
        });

        tab = tab.filter(
            (t: any) =>
                ((activeFilter.value == 1 && t.isActive == true) || (activeFilter.value == 0 && t.isActive == false) || activeFilter.value == -1) &&
                t.themeName.toLowerCase().includes(themeFilter.value.toLocaleLowerCase())
        );

        return tab;
    });

    //themeDetails
    const themeDetails = (item: any) => {
        router.push({ name: 'ThemeDetails', params: { brand: brand.value, themeCode: item.code } });
    };

    const deleteTheme = async (themeCode: string) => {
        emit('update:deleteTheme', { themeCode });
        props.triggerOpenModalDelete();
    };

    watch(
        () => themes.value,
        (newThemes) => {
            if (newThemes) {
                filterThemes.value = newThemes;
            }
        }
    );
</script>

<style lang="scss">
    .fsb-base-tooltip {
        .tooltip-inner {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .theme-tab {
        padding: 0px;
        margin: 0px;

        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &__table {
            table {
                border-collapse: collapse;
                border-radius: 16px;
                background-color: var(--grayscale-white);

                span.sr-only {
                    opacity: 0 !important;
                    visibility: hidden;
                }

                thead {
                    tr {
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: var(--grayscale-gray-light);
                        }
                    }
                }

                tbody {
                    tr {
                        cursor: pointer;
                        border-top: 12px solid transparent;
                        border-bottom: 12px solid transparent;
                        position: relative;
                        transition: background-color ease-in-out var(--base-transition-duration);

                        &:hover {
                            background-color: var(--grayscale-gray-light);
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: -12px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: var(--grayscale-gray-light);
                        }
                    }
                }

                th,
                td {
                    padding: 12px;
                    vertical-align: middle;
                }

                .cell {
                    &--theme-name {
                        display: flex;
                        align-items: center;
                        width: calc((100vw - var(--nav-bar-width) - var(--padding) - 128px * 2 - 240px - 160px) * 0.72);
                    }
                    &--action {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    &--media {
                        border-bottom: 1px solid var(--grayscale-gray-dark);
                    }
                    &--128 {
                        min-width: 128px;
                    }
                    &--160 {
                        min-width: 160px;
                    }
                    &--240 {
                        min-width: 240px;
                    }
                }

                .head {
                    &--theme-name {
                        display: flex;
                        align-items: center;
                    }
                    &--action {
                        text-align: right;
                    }
                }
            }
        }
    }
</style>
