<template>
    <base-modal
        :modal-show="modalShow"
        id="category-modal-delete"
        :title="t('delete')"
        hide-header-close
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="closeModal"
        :show-secondary-button="false"
        :primary-label="t('confirm')"
        :on-primary-button-click="updateStoreCategories"
        @close-modal="closeModal"
    >
        <p>{{ t('deleteConfirmationCategory') }}</p>
    </base-modal>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import { useCategoryStore } from '@/store';
    import { iMetaCategoryDetails } from '@/interfaces/global';

    interface Props {
        metaCategory: iMetaCategoryDetails | null;
        metaCategoryCode?: string;
        categoryCode?: string;
        modalShow: boolean;
        closeModal: () => void;
    }

    const props = defineProps<Props>();

    const { t } = useI18n({ useScope: 'global' });

    const categoryStore = useCategoryStore();

    const updateStoreCategories = () => {
        // delete existing metacategory
        if (props.metaCategoryCode) {
            categoryStore.deleteMetaCategory(props.metaCategoryCode);
        }

        // delete existing category
        if (props.categoryCode) {
            categoryStore.deleteCategory(props.categoryCode);
        }

        props.closeModal();
    };
</script>

<!-- UNSCOPED for Modal style -->
<style lang="scss">
    #category-modal-delete {
    }
</style>
