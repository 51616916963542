<template>
    <base-modal
        :modal-show="modalShow"
        :id="`category-modal-${type}`"
        :title="modalTitle"
        hide-header-close
        :with-max-height="catsCount > 6"
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="onCloseModal"
        :show-secondary-button="false"
        :primary-label="modalValid"
        :on-primary-button-click="updateStoreCategories"
        @close-modal="onCloseModal"
    >
        <b-form>
            <simple-input id="groupName" :label="t('groupName')" :placeholder="t('inputGroupNamePlaceholder')" :default-value="metaCategoryName" required @update:value="onUpdateMetaCategoryName" />
            <list-categories-inputs
                id="categoriesList"
                :label="t('categoriesList')"
                :placeholder="t('inputCategoryNamePlaceholder')"
                :default-value="categoriesList"
                @update:categories="updateCategories"
                @remove:categories="updateToRemoveCategories"
            />
            <p v-if="dataInvalid">{{ t('checkRequiredFields') }}</p>
        </b-form>
    </base-modal>
</template>

<script lang="ts" setup>
    import { computed, ComputedRef, ref, Ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useCategoryStore } from '@/store';

    import ListCategoriesInputs from '@/components/CategoriesUi/ListCategoriesInputs.vue';
    import { iCategory, iCreaCategory, iMetaCategoryDetails } from '@/interfaces/global';
    import { storeToRefs } from 'pinia';
    import { isNumber } from 'lodash';

    interface Props {
        type: 'edit' | 'create';
        metaCategory?: iMetaCategoryDetails | null;
        modalShow: boolean;
        closeModal: () => void;
    }

    const props = defineProps<Props>();

    const { t } = useI18n({ useScope: 'global' });

    const categoryStore = useCategoryStore();

    const { categories: storedCategories, metaCategories } = storeToRefs(categoryStore);

    const modalTitle: ComputedRef<string> = computed(() => {
        return props.type === 'create' ? t('createCategory') : t('editCategory');
    });

    const modalValid: ComputedRef<string> = computed(() => {
        return props.type === 'create' ? t('create') : t('save');
    });

    const dataInvalid: Ref<boolean> = ref(false);

    const metaCategoryName: Ref<string | null> = ref(props.type === 'create' ? null : props.metaCategory?.name ?? null);
    const metaCategoryCode: Ref<string | null> = ref(props.type === 'create' ? null : props.metaCategory?.crea_metacategory_code ?? null);

    const categoriesList: Ref<iCategory[]> = ref(
        props.type === 'create'
            ? []
            : props.metaCategory
            ? props.metaCategory?.creacategories?.map((cat: any) => storedCategories.value.find((c) => c.crea_category_code === cat.crea_category_code)).filter((cat) => cat !== undefined)
            : []
    );
    const categoriesToAdd: Ref<(iCategory | iCreaCategory)[]> = ref([]);
    const categoriesToUpdate: Ref<(iCategory | iCreaCategory)[]> = ref([]);
    const categoriesToRemove: Ref<(iCategory | iCreaCategory)[]> = ref([]);

    const resetFields = () => {
        metaCategoryName.value = null;
        metaCategoryCode.value = null;
        categoriesList.value = [];
    };

    const onCloseModal = () => {
        props.closeModal();
        resetFields();
    };

    const catsCount: ComputedRef<number> = computed(() => categoriesList.value?.length ?? 0);

    const updateCategories = ({ categories, updatedCategory }: { categories?: iCategory[]; updatedCategory?: iCategory }) => {
        if (props.type === 'create') {
            if (categories) categoriesList.value = categories;
        } else if (props.type === 'edit') {
            if (categories) {
                const diffByCode = categories.filter((cat: any) => {
                    if (props.metaCategory && props.metaCategory.creacategories) {
                        return (
                            !props.metaCategory.creacategories.find((c: any) => c.crea_category_code === cat.crea_category_code) ||
                            !props.metaCategory.creacategories.find((c: any) => c.creacategory_to_metacategory_code === cat.crea_category_code)
                        );
                    }
                    return false;
                });

                categoriesToAdd.value = diffByCode.filter((val: any) => !!val && !val.hasOwnProperty('crea_category_code') && val.name.length > 0);
            }

            if (updatedCategory && updatedCategory.name !== '') {
                const updatedCategoryIndex: number | undefined = props.metaCategory?.creacategories?.findIndex((cat: any) => cat.crea_category_code === updatedCategory.crea_category_code);
                if (isNumber(updatedCategoryIndex) && updatedCategoryIndex !== -1) {
                    categoriesToUpdate.value[updatedCategoryIndex] = updatedCategory;
                } else if (updatedCategory.crea_category_code) {
                    categoriesToUpdate.value.push(updatedCategory);
                }
            }
        }
    };

    const updateToRemoveCategories = (value: any) => {
        if (props.type === 'edit') {
            const { tempRemovedCategory } = value;

            categoriesToRemove.value.push(tempRemovedCategory);
        }
    };

    const updateStoreCategories = async () => {
        if (metaCategoryName.value === null || metaCategoryName.value === '') {
            dataInvalid.value = true;
        } else {
            dataInvalid.value = false;

            if (props.type === 'create') {
                const categoriesNames: string[] = categoriesList.value.map((cat: iCategory) => cat.name);
                await categoryStore.addMetaCategory(
                    metaCategoryName.value as string,
                    categoriesNames.filter((catname: string) => catname.length > 0)
                );
            } else if (props.type === 'edit') {
                await categoryStore.updateMetaCategory(
                    props.metaCategory?.crea_metacategory_code as string,
                    metaCategoryName.value as string,
                    categoriesToUpdate.value.filter((cat: any) => cat && cat.hasOwnProperty('crea_category_code')) as iCategory[],
                    categoriesToAdd.value.map((cat: iCategory) => cat.name) as string[],
                    categoriesToRemove.value.filter((cat: any) => cat && cat.hasOwnProperty('crea_category_code')) as iCategory[]
                );
            }

            onCloseModal();
        }
    };

    const onUpdateMetaCategoryName = ({ value }: { value: string | number }) => {
        metaCategoryName.value = value as string;
    };

    watch(
        () => props.metaCategory,
        (metaCategory: any) => {
            if (metaCategory) {
                metaCategoryName.value = metaCategory.name;
                categoriesList.value = metaCategory.creacategories
                    ?.map((cat: any) => {
                        const storedCat: iCategory | undefined = storedCategories.value.find((c) => c.crea_category_code === cat.crea_category_code);
                        if (storedCat) {
                            return { ...storedCat };
                        } else {
                            return undefined;
                        }
                    })
                    .filter((cat: any) => cat !== undefined) as iCategory[];
            } else {
                resetFields();
            }
        }
    );
</script>

<!-- UNSCOPED for Modal style -->
<style lang="scss">
    #category-modal-create,
    #category-modal-edit {
        .fsb-base-modal {
            &__content {
                overflow: hidden;
                display: flex;
                flex-direction: column;

                &.--with-max-height {
                    .list-categories-inputs__fields {
                        margin-bottom: 56px;
                    }
                }
            }
        }

        form {
            & > * {
                margin-bottom: 16px;
            }

            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
    }
</style>
