<template>
    <section class="admin-home">
        <h1 class="fsb-typo-heading-1" v-if="brandNotSelected">{{ t('welcome') }}</h1>
        <div class="admin-home__content">
            <brand-selector @update:select-brand="onBrandSelected" v-if="brandNotSelected" />

            <users-manager />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { Ref, ref } from 'vue';
    import BrandSelector from '@/components/HomeUi/BrandsSelector.vue';
    import UsersManager from '@/components/HomeUi/UsersManager.vue';

    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });

    const brandNotSelected: Ref<boolean> = ref(true);

    const onBrandSelected = () => {
        brandNotSelected.value = false;
    };
</script>

<style scoped lang="scss">
    .admin-home {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__content {
            display: flex;
            gap: 40px;
            margin-top: 16px;

            & > * {
                width: 50%;

                .brands-selector {
                    width: 100%;
                }
            }
        }
    }
</style>
