<template>
    <div class="content-header">
        <div class="blocIconLeft" v-if="!isRightIcon">
            <icon-button :icon-type="iconName" size="sm" @click="launchAction" />
            <div>
                <h1 class="fsb-typo-heading-1">{{ title }}</h1>
            </div>
        </div>
        <div class="blocIconRight" v-else>
            <div>
                <h5 class="fsb-typo-para-medium">{{ email }}</h5>
                <h1 class="fsb-typo-heading-1">{{ firstName }} {{ lastName }}</h1>
            </div>
            <icon-button v-if="isRightIcon" :icon-type="iconName" size="sm" @click="launchAction" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    interface Props {
        iconName: string;
        email?: string;
        firstName?: string;
        lastName?: string;
        isRightIcon: boolean;
        title?: string;
    }
    const props = withDefaults(defineProps<Props>(), {
        iconName: 'close',
        isRightIcon: true,
    });

    const emit = defineEmits(['actionButton']);
    const launchAction = () => {
        emit('actionButton');
    };
</script>

<style lang="scss">
    .content-header {
        .blocIconRight {
            display: flex;
            justify-content: space-between;
            // padding-bottom: 40px;
            width: 100%;

            > div > h5 {
                color: var(--grayscale-gray-dark);
            }
        }
        .blocIconLeft {
            display: flex;
            align-items: center;
            > div {
                margin-left: 10px;
            }
        }
    }
</style>
