<template>
    <ui-button ref="buttonRef" :label="computedLabel" variant="secondary" class="toggle-expand-button" :on-click="onClick">
        <template #prefix-icon>
            <font-awesome-icon v-if="!expanded" icon="fa-solid fa-chevron-down" />
            <font-awesome-icon v-else icon="fa-solid fa-chevron-up" />
        </template>
    </ui-button>
</template>

<script lang="ts" setup>
    import UiButton from '@/components/SharedUi/UiButton.vue';
    import { ComputedRef, Ref, ref, computed } from 'vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });

    interface Props {
        expanded: boolean;
        onClick: () => void;
    }
    const props = withDefaults(defineProps<Props>(), {
        expanded: false,
    });

    const computedLabel: ComputedRef<string> = computed(() => {
        return props.expanded ? t('collapse') : t('expand');
    });

    const buttonRef: Ref<HTMLElement | null> = ref(null);
</script>

<style scoped lang="scss">
    .toggle-expand-button {
        padding: 10px 12px;

        color: var(--grayscale-black);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;

        .svg-inline--fa {
            width: 10px;
            height: 10px;
        }
    }
</style>
