<template>
    <div class="create-theme step-3">
        <multi-select
            class="fsb-typo-heading-6"
            id="select-banners"
            :label="t('selectBannerKit')"
            :field-label-text="t('banners')"
            :search-label="t('searchOperation')"
            :items="bannersOptions"
            :required="findIsRequired('select-banners')"
            :with-custom-nothing="{
                text: t('noBannerFound'),
            }"
            @update:value="($evt: any) => onUpdateValue({...$evt, id: 'select-banners'})"
        />
        <div class="create-theme__upload-thumbnail">
            <field-label :label="t('bannerThumbnail')" for-field="bannerPreview" required class="fsb-typo-heading-6" />
            <figure class="upload-thumbnail">
                <img :src="thumbnailImage" alt="banner preview" />
                <div>
                    <div class="upload-button">
                        <input ref="inputFileRef" type="file" name="file" id="file" class="inputfile" accept="image/jpeg, image/png, image/gif" @change="onUploadFile" />
                        <label class="fsb-typo-btn-principal" for="file">{{ !thumbnailImage || thumbnailImage === PLACEHOLDER ? t('addThumbnail') : t('editThumbnail') }}</label>
                    </div>
                </div>
            </figure>
        </div>
    </div>
</template>

<script lang="ts" async setup>
    import { ref, computed, ComputedRef, Ref, watch, onMounted } from 'vue';
    import { useThemeStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';
    import { iBanner, iMultiSelectOption } from '@/interfaces/global';
    import { iDataValidation } from '@/interfaces/global';
    import { findIsRequired as _findIsRequired, checkDataValid, formatDataValue } from '@/helpers/dataValidation';

    const PLACEHOLDER = 'https://placehold.co/198x198/png';

    const props = defineProps({
        themeDetails: {
            type: Object,
            default: null,
        },
    });

    const emit = defineEmits(['data-validation', 'update:value']);

    const findIsRequired = (id: string) => _findIsRequired(id, dataValidation.value);

    const dataValidation: Ref<iDataValidation[]> = ref([
        { id: 'select-banners', isValid: true, required: false, keyDataValue: 'details.banners', defaultValue: props.themeDetails ? props.themeDetails.url_banner_code : null },
        { id: 'upload-banner', isValid: false, required: true, keyDataValue: 'thumbnail_image', defaultValue: props.themeDetails ? props.themeDetails.url_picture : null },
    ]);

    const isDataValid: ComputedRef<boolean> = computed(() => checkDataValid(dataValidation.value));

    const onUpdateValue = ({ value, id }: { value: any; id: string }) => {
        const index = dataValidation.value.findIndex((d: iDataValidation) => d.id === id);
        dataValidation.value[index].isValid = value !== '';

        emit('update:value', { value: { ...formatDataValue(dataValidation.value[index], value) }, id });
    };

    watch(isDataValid, (value: boolean) => emit('data-validation', { isDataValid: value }));

    const { t } = useI18n({ useScope: 'global' });

    const themeStore = useThemeStore();

    const { banners } = storeToRefs(themeStore);

    const bannersOptions: ComputedRef<iMultiSelectOption[]> = computed(() => {
        const url_banner_code = props.themeDetails && props.themeDetails.url_banner_code ? props.themeDetails.url_banner_code : [];

        return banners.value.map((ban: iBanner) => ({ ...ban, value: ban.code, label: ban.name, selected: props.themeDetails ? url_banner_code.includes(ban.code) : false }));
    });

    const thumbnailImage: Ref<string> = ref(props.themeDetails ? props.themeDetails.url_picture : PLACEHOLDER);

    const inputFileRef: Ref<any> = ref(null);

    const onUploadFile = () => {
        if (inputFileRef.value && inputFileRef.value.files.length) {
            const image = inputFileRef.value.files[0];
            thumbnailImage.value = URL.createObjectURL(image);
            onUpdateValue({
                value: {
                    ...formatDataValue(dataValidation.value.find((d: iDataValidation) => d.id === 'upload-banner') as iDataValidation, image),
                },
                id: 'upload-banner',
            });
        }
    };

    onMounted(() => {
        if (props.themeDetails) {
            dataValidation.value.forEach((entry: iDataValidation) => {
                emit('update:value', { value: { ...formatDataValue(entry, entry.defaultValue) }, id: entry.id });
            });
        }
    });
</script>

<style lang="scss">
    .step-3 {
        & > * {
            margin-bottom: 8px;
        }

        .upload-thumbnail {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            & > div {
                flex: 1;
                display: flex;
                justify-content: center;
            }

            img {
                width: 198px;
                height: 198px;
                object-fit: cover;
                border-radius: 12px;
            }

            .upload-button {
                .inputfile {
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;

                    & + label {
                        display: block;
                        padding: 12px 16px !important;
                        border-radius: 12px;
                        transition: all ease-in-out 0.32s;
                        // width: 132px;
                        text-align: center;

                        border: none !important;
                        outline: none !important;
                        box-shadow: none !important;
                        cursor: pointer;
                        background-color: var(--grayscale-background);
                    }

                    &:focus + label,
                    & + label:hover {
                        // background-color: red;
                    }
                }
            }
        }
    }
</style>
