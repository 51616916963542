import { axiosInstance } from '@/axiosInstance';
import fsb from '@adcleek/front-storybook';
import { FrontZonesLib } from '@adcleek/front-zones-lib';
import { createPinia } from 'pinia';
import { createApp, markRaw } from 'vue';
import { LmpPlugin } from 'vue3-lmp-plugin';
import App from './App.vue';
import i18nModule from './helpers/translations';
import router from './router';
import { useAuthStore } from './store';

const app = createApp(App);
const pinia = createPinia();

pinia.use(({ store }) => {
    store.router = markRaw(router);
});

app.use(pinia);
app.use((await i18nModule()) as any);
app.use(LmpPlugin, { axiosInstance, router });
app.use(router);
app.use(FrontZonesLib);

const authStore = useAuthStore();
authStore.init();

fsb.install(app);

app.mount('#app');
