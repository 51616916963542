<template>
    <div>
        <sidebar :show-sidebar="show" @updateShowSidebar="updateShowSidebarValues()">
            <template v-slot:body>
                <header-infos :email="userDetails.email" :first-name="userDetails.firstname" :last-name="userDetails.lastname" @actionButton="show = false" />
                <div class="first-bloc">
                    <infos-title :title="t('infosProfil')" :label-icon="t('editCategory')" />
                    <div class="profil-infos">
                        <div>
                            <h5 class="fsb-typo-para-big text-color-black">{{ t('name') }}</h5>
                            <h5 class="fsb-typo-para-big text-color-gray">{{ userDetails.lastname }}</h5>
                        </div>
                        <div>
                            <h5 class="fsb-typo-para-big text-color-black">{{ t('firstName') }}</h5>
                            <h5 class="fsb-typo-para-big text-color-gray">{{ userDetails.firstname }}</h5>
                        </div>
                        <div>
                            <h5 class="fsb-typo-para-big text-color-black">{{ t('email') }}</h5>
                            <h5 class="fsb-typo-para-big text-color-gray">{{ userDetails.email }}</h5>
                        </div>
                        <div v-if="isGlobalUser">
                            <h5 class="fsb-typo-para-big text-color-black">{{ t('role') }}</h5>
                            <h5 class="fsb-typo-para-big text-color-gray">{{ userDetails.role || '--' }}</h5>
                        </div>
                    </div>
                </div>
                <div class="second-bloc">
                    <div v-if="!isGlobalUser">
                        <div class="title">
                            <h3 class="fsb-typo-heading-3">{{ t('pos') }} ({{ userPosCount || 0 }})</h3>
                            <p class="fsb-typo-link-medium all-user-pos-link" v-if="userPosCount" @click="showPosListUserSidebar = true">{{ t('allPos') }}</p>
                        </div>
                        <div class="pos-infos">
                            <b-list-group v-if="userPosCount">
                                <b-list-group-item v-for="pos in userDetails.pointofinterest.filter(Boolean).slice(-4)" :key="pos.point_code">
                                    <list-item-with-role-label
                                        :main-text="pos.details.name"
                                        :secondary-text="pos.details.point_code + ' - ' + pos.details.city"
                                        :role="pos.role"
                                        :enable-icon-group-mode="false"
                                        btn-icon="chevron-right"
                                    />
                                </b-list-group-item>
                            </b-list-group>
                            <div v-else class="noPosBloc">
                                <p class="fsb-typo-para-medium">{{ t('noPosFound') }}</p>
                                <ui-button :label="t('add')" variant="primary" @click="addPos" />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <infos-title :title="t('rrmpRole')" icon-name="add" @actionButton="openModalAddBrand" />
                        <template v-if="rights.length">
                            <div class="brand-infos" v-for="(userRights, index) in rights" :key="userRights.brand">
                                <edit-brand
                                    v-if="userRights.isEditing"
                                    :uid="userDetails.uid"
                                    :brand="userRights.brand"
                                    v-model:rights="userRights.rights"
                                    @close-edit="userRights.isEditing = false"
                                    @delete-brand="rights.splice(index, 1)"
                                    style="margin-bottom: 10px"
                                />
                                <div v-else>
                                    <div>
                                        <div class="brand-icon-bloc">
                                            <img :src="`${logoUrlPath}${userRights.brand}.svg`" />
                                            <h5 class="fsb-typo-para-big" style="color: #1f1f1f">
                                                {{ userRights.brand }}
                                            </h5>
                                        </div>
                                        <div class="brand-actions-bloc">
                                            <icon-button icon-type="pin" @click="getPosDetailsByBrand(userDetails.uid, userRights.brand)" />
                                            <icon-button icon-type="edit" @click="() => (userRights.isEditing = true)" />
                                        </div>
                                    </div>

                                    <div v-for="rights in userRights.rights" :key="rights.service || 0">
                                        <h5 v-if="rights.service" class="fsb-typo-para-big text-color-black">{{ t(rights.service) }}</h5>
                                        <h5 v-if="rights.role" class="fsb-typo-para-big text-color-gray">{{ t(rights.role) }}</h5>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="third-bloc">
                    <ui-button
                        :label="isGlobalUser ? t('delete') : t('deleteOnBrand')"
                        variant="danger"
                        class="deleteBtn"
                        :on-click="openModalDelete"
                        style="background-color: #f5dbdb; color: #1f1f1f"
                    />
                    <ui-button v-if="!isGlobalUser" :label="t('duplicateUser')" variant="secondary" class="duplicateBtn" :on-click="openUserDuplicateModal" />
                </div>
            </template>
        </sidebar>

        <user-modal-delete :modal-show="modalShowDelete" :user="userDetails" :action="isGlobalUser ? eUserAction.DELETE : eUserAction.REMOVE_BRAND" :close-modal="closeUserDeleteModal" />

        <pos-list-of-user v-model:show-sidebar="showPosListUserSidebar" :user-uid="props.userDetails.uid" :pointofinterest="pointofinterest" :brand="currentBrand" @closeSidebar="updateShowSidebarValues" />

        <modal-with-check-box
            v-model:show-modal="showModalAddBrand"
            :title="t('addBrand')"
            :placeholder-of-search-input="t('searchBrandPlaceholder')"
            :list="brandList"
            @on-right-button="addBrandToUser"
        />
        <user-modal-duplicate :modal-show="modalShowDuplicate" :user-details="userDetails" @close-modal="closeUserModalDuplicate" />
    </div>
</template>

<script lang="ts" setup>
    import { iMultiSelectOption, iPointofinterest, iRights, iUser } from '@/interfaces/global';
    import { useGlobalStore, usePosStore, useUsersStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { ComputedRef, Ref, computed, inject, ref, watch, PropType } from 'vue';
    import { useI18n } from 'vue-i18n';
    import ListItemWithRoleLabel from '../SharedUi/ListItemWithRoleLabel.vue';
    import ModalWithCheckBox from '../SharedUi/ModalWithCheckBox.vue';
    import HeaderInfos from '../Sidebar/HeaderInfos.vue';
    import InfosTitle from '../Sidebar/InfosTitle.vue';
    import EditBrand from './EditBrand.vue';
    import PosListOfUser from './PosListOfUser.vue';
    import UserModalDelete from './UserModalDelete.vue';
    import { eUserAction } from '@/interfaces/global';
    import UserModalDuplicate from './UserModalDuplicate.vue';

    const logoUrlPath = process.env.VUE_APP_BRAND_LOGO_URL;

    const snackBar: { open(variant: string, text: string, delay?: number): void } | undefined = inject('snackBar');
    const useModalDelete = inject('useModal') as any;
    const { t } = useI18n({ useScope: 'global' });
    const posStore = usePosStore();
    const globalStore = useGlobalStore();
    const usersStore = useUsersStore();

    const { modalShow: modalShowDelete, openModal: openModalDelete, closeModal: closeModalDelete } = useModalDelete();

    const useModalDuplicate = inject('useModal') as any;
    const { modalShow: modalShowDuplicate, openModal: openModalDuplicate, closeModal: closeModalDuplicate } = useModalDuplicate();

    const props = defineProps({
        showSidebar: Boolean,
        userDetails: {
            type: Object as PropType<iUser>,
            default() {
                return {};
            },
        },
        isGlobalUser: Boolean,
    });

    const emit = defineEmits(['updateShowSidebar', 'updateUserRights']);

    const show: Ref<boolean> = ref(props.showSidebar);
    const brandList: Ref<iMultiSelectOption[]> = ref([]);

    const showModalAddBrand = ref(false);

    const rights: ComputedRef<(iRights & { isEditing?: boolean })[]> = computed(() => props.userDetails.rights || []);

    const userPosCount: ComputedRef<number> = computed(() => props.userDetails.pointofinterest?.filter(Boolean).length ?? 0);

    watch(
        () => props.showSidebar,
        (value: boolean) => {
            show.value = value;
        }
    );

    const updateShowSidebarValues = () => {
        showPosListUserSidebar.value = false;
        show.value = false;
        emit('updateShowSidebar');
    };

    const closeUserDeleteModal = () => {
        show.value = false;
        closeModalDelete();
    };

    const openUserDuplicateModal = (user: iUser) => {
        openModalDuplicate();
    };

    const closeUserModalDuplicate = () => {
        closeModalDuplicate();
    };

    const addPos = () => {
        showPosListUserSidebar.value = true;
    };

    const pointofinterest: Ref<iPointofinterest[]> = ref([] as iPointofinterest[]);
    const { brand } = storeToRefs(globalStore);
    const currentBrand: Ref<string> = ref(brand.value);
    const showPosListUserSidebar: Ref<boolean> = ref(false);

    watch(
        () => props.userDetails,
        (user: iUser) => {
            pointofinterest.value = user.pointofinterest;
        }
    );

    const getPosDetailsByBrand = async (uid: string, brand: string) => {
        try {
            const pos = await posStore.getPosDetailsByBrand(uid, brand);
            pointofinterest.value = pos;
            currentBrand.value = brand;
            showPosListUserSidebar.value = true;
            globalStore.getFinancialGroups(brand);
            globalStore.setBrand(brand);
        } catch (error) {
            console.log(error);
        }
    };

    const openModalAddBrand = async () => {
        const brands = (await usersStore.getBrands()).filter((brand) => rights.value.find((right) => right.brand === brand.code) === undefined);
        brandList.value = brands.map((brand) => {
            return { value: brand.code, label: brand.label, selected: false };
        });
        showModalAddBrand.value = true;
    };

    const addBrandToUser = async (brands: iMultiSelectOption[]) => {
        try {
            if (brands.length > 0) {
                await usersStore.addBrands(
                    props.userDetails.uid,
                    brands.map((brand) => brand.value)
                );
                snackBar?.open('success', t('successMessageOfAddedBrand'));
                emit('updateUserRights', props.userDetails.uid);
            }
            showModalAddBrand.value = false;
        } catch (error) {
            console.log(error);
        }
    };
</script>

<style lang="scss">
    .first-bloc,
    .second-bloc {
        .title,
        .profil-infos > div,
        .brand-infos > div > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
        }
        .profil-infos {
            padding-bottom: 40px;
        }
        .brand-infos {
            &:not(:last-child) {
                border-bottom: 1px solid var(--grayscale-gray-regular, #dbdbdb);
                margin-bottom: 12px;
            }

            .brand-icon-bloc {
                display: flex;
                align-items: center;
                img {
                    height: 20px;
                    margin-right: 10px;
                }
            }
            .brand-actions-bloc {
                display: flex;
                > button {
                    margin-left: 10px;
                }
            }
        }
        .all-user-pos-link {
            cursor: pointer;
            font-weight: 100;
            font-size: 15px;
        }
    }
    .list-group {
        border-radius: 10px !important;
        .list-group-item {
            padding: 18px !important;
        }
    }
    .third-bloc {
        float: right;
        margin-top: 30px;

        .deleteBtn {
            span {
                color: black !important;
                font-weight: 500 !important;
            }
        }
        .duplicateBtn {
            span {
                font-weight: 500 !important;
            }
        }
    }
    .noPosBloc {
        text-align: center;
        p {
            margin-bottom: 20px;
        }
    }

    .text-color-black {
        color: var(--grayscale-black) !important;
    }
    .text-color-gray {
        color: var(--grayscale-gray-dark) !important;
    }
</style>
