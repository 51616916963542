<template>
    <div>
        <sidebar class="pos-panel" :show-sidebar="Boolean(showPosPanel)" @updateShowSidebar="emit('update:showPosPanel', false)">
            <template #body>
                <transition mode="out-in" name="fadeX">
                    <pos-panel-header
                        v-if="currentStatus.panelType === 'details'"
                        :title="posData.details?.name"
                        :subtitle="posData.point_code"
                        is-header-of-details
                        @action-close-button="emit('update:showPosPanel', false)"
                    />
                    <pos-panel-header
                        v-else
                        :title="t('usersList')"
                        :show-back-button="currentStatus.showBackButton"
                        :show-close-button="currentStatus.showCloseButton"
                        @action-back-button="goBack"
                        @action-close-button="emit('update:showPosPanel', false)"
                    />
                </transition>

                <transition mode="out-in" name="fadeX">
                    <user-list-of-pos v-if="currentStatus.panelType === 'userList'" :point-code="posData.point_code" v-model:users-of-pos="posData.users" @show-add-user="showAddUser" />
                    <add-users-to-pos v-else-if="currentStatus.panelType === 'addUser'" :pos="posData" v-model:user-list="posData.users" @go-back="goBack" />
                    <pos-details v-else-if="currentStatus.panelType === 'details'" :pos="posData" @show-user-list="showUserList" />
                </transition>
            </template>
        </sidebar>
    </div>
</template>

<script lang="ts" setup>
    import { iPos } from '@/interfaces/global';
    import { computed, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import AddUsersToPos from './AddUsersToPos.vue';
    import PosDetails from './PosDetails.vue';
    import PosPanelHeader from './PosPanelHeader.vue';
    import UserListOfPos from './UserListOfPos.vue';

    const { t } = useI18n({ useScope: 'global' });

    const props = defineProps<{
        showPosPanel: 'details' | 'userList' | false;
        pos: iPos;
    }>();

    const emit = defineEmits(['update:showPosPanel', 'back']);

    const posData = ref(props.pos);
    const statusStack = ref([
        {
            showBackButton: false,
            showCloseButton: true,
            panelType: props.showPosPanel as 'details' | 'userList' | 'addUser',
        },
    ]);

    const currentStatus = computed(() => statusStack.value.slice(-1)[0]);

    const showAddUser = () => {
        statusStack.value.push({
            showBackButton: true,
            showCloseButton: false,
            panelType: 'addUser',
        });
    };

    const showUserList = () => {
        statusStack.value.push({
            showBackButton: true,
            showCloseButton: false,
            panelType: 'userList',
        });
    };

    const goBack = () => {
        statusStack.value.pop();
    };
</script>

<style lang="scss" scoped></style>
