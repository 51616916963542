<template>
    <div class="category-item-line" v-if="categoryDetails">
        <p>{{ categoryDetails.name }}</p>
        <div class="category-item-line__theme-manager">
            <div>
                <span class="fsb-typo-para-regular" :class="{ '--no-theme': !themeCount }">{{ !themeCount ? t('noTheme') : t('themeCount', { count: themeCount }) }}</span>
                <icon-button :icon-type="themeCount ? 'edit' : 'add'" variant="white" :on-click="() => openModalAssign(categoryCode)" />
            </div>
        </div>
        <div class="category-item-line__toolbar">
            <icon-button icon-type="delete" variant="white" :on-click="() => onClickDelete(categoryCode)" />

            <position-buttons-group
                v-if="positionsButtonsGroupOptions"
                :up-disabled="positionsButtonsGroupOptions.upDisabled"
                :down-disabled="positionsButtonsGroupOptions.downDisabled"
                :current-index="positionsButtonsGroupOptions.currentIndex"
                :next-index="positionsButtonsGroupOptions.nextIndex"
                :previous-index="positionsButtonsGroupOptions.previousIndex"
                :elements-array="positionsButtonsGroupOptions.elementsArray"
                @update:elements-array="onSwapElements"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { iCategory } from '@/interfaces/global';
    import { ComputedRef, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useCategoryStore } from '@/store';
    import { storeToRefs } from 'pinia';

    import PositionButtonsGroup from '@/components/SharedUi/PositionButtonsGroup.vue';

    const categoryStore = useCategoryStore();

    const { categories } = storeToRefs(categoryStore);

    const { t } = useI18n({ useScope: 'global' });

    interface Props {
        categoryCode: string;
        positionsButtonsGroupOptions?: {
            upDisabled: boolean;
            downDisabled: boolean;
            currentIndex: number;
            nextIndex: number;
            previousIndex: number;
            elementsArray: any[];
        };
        openModalAssign: (categoryCode: string) => void;
    }

    const props = defineProps<Props>();

    const emit = defineEmits(['update:elementsArray', 'delete:category']);

    const categoryDetails: ComputedRef<iCategory | undefined> = computed(() => categories.value.find((cat) => cat.crea_category_code === props.categoryCode));

    const themeCount: ComputedRef<number | undefined> = computed(() => categoryDetails.value?.creas?.length);

    const onSwapElements = ({ elementsArray }: { elementsArray: any[] }) => {
        emit('update:elementsArray', { elementsArray });
    };

    const onClickDelete = (categoryCode: string) => {
        emit('delete:category', { categoryCode });
    };
</script>

<style scoped lang="scss">
    .category-item-line {
        * {
            font-family: Inter;
            font-style: normal;
            letter-spacing: -0.4px;
            color: var(--Primary);
            margin: 0;
        }

        padding: 12px;
        padding-left: 32px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        &:not(:last-child) {
            border-bottom: 1px solid var(--grayscale-gray-light);
        }

        & > p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            flex: 0.32;
            max-width: 32%;
        }

        &__theme-manager {
            position: relative;
            flex: 1;

            & > div {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            span {
                color: var(--grayscale-black);
                margin-right: 8px;
                min-width: 80px;
                text-align: center;

                &.--no-theme {
                    color: var(--grayscale-gray-dark);
                    font-style: italic;
                }
            }

            .icon-button {
                margin-left: 16px;
            }
        }

        &__toolbar {
            display: flex;

            & > *:not(:last-child) {
                margin-right: 4px;
            }
        }
    }
</style>
