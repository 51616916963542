<template>
    <base-modal
        :modal-show="modalShow"
        id="user-modal-delete"
        :title="t('delete')"
        hide-header-close
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="closeModal"
        :show-secondary-button="false"
        :primary-label="t('confirm')"
        :on-primary-button-click="deleteUser"
        @close-modal="closeModal"
    >
        <p>{{ t('deleteConfirmationUser', { user: username }) }}</p>
    </base-modal>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import { computed, inject, watch } from 'vue';
    import { iUser } from '@/interfaces/global';
    import { useUsersStore, useGlobalStore } from '@/store';
    import { eUserAction } from '@/interfaces/global';

    const snack: any = inject('snackBar');

    interface Props {
        user?: iUser | null;
        modalShow: boolean;
        action: eUserAction;
        closeModal: () => void;
    }

    const props = defineProps<Props>();

    const { t } = useI18n({ useScope: 'global' });

    const usersStore = useUsersStore();
    const globalStore = useGlobalStore();

    const username = computed<string>(() => {
        if (props.user) {
            return `${props.user.firstname} ${props.user.lastname}`;
        }
        return '';
    });

    const deleteUser = async () => {
        if (props.user) {
            try {
                if (props.action === eUserAction.REMOVE_BRAND) {
                    await usersStore.deleteBrandOfUser(props.user.uid, globalStore.brand);
                    usersStore.getUsers();
                } else {
                    await usersStore.deleteUser(props.user.uid);
                    usersStore.getUsersMultiBrands();
                }
                snack.open('success', t('userSuccessfullyDeleted'));
            } catch (e) {
                snack.open('alert', t('userDeleteFailled'));
            }
            props.closeModal();
        }
    };

    watch(
        () => props.modalShow,
        () => {
            usersStore.getUsersMultiBrands();
        },
        {
            immediate: true,
        }
    );
</script>

<!-- UNSCOPED for Modal style -->
<style lang="scss">
    #user-modal-delete {
        p {
            line-height: 18px;
        }
    }
</style>
