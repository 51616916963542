<template>
    <div class="admin-nav-bar">
        <router-link
            v-for="link in themesManager"
            :key="`router-link-theme-manager-${link.name}`"
            class="fsb-typo-btn-secondary link"
            :class="{ '--selected': currentRoute === link.name }"
            :to="{ name: link.name, params: { brandCode: currentBrand } }"
        >
            {{ link.label }}
        </router-link>
    </div>
</template>

<script setup lang="ts">
    import { Ref, ref, ComputedRef, computed } from 'vue';
    import { useGlobalStore } from '@/store';
    import { useI18n } from 'vue-i18n';
    import { useRoute } from 'vue-router';
    import { storeToRefs } from 'pinia';

    import { iThemeLink } from '@/interfaces/global';

    const { t } = useI18n({ useScope: 'global' });

    const route = useRoute();

    const currentRoute: ComputedRef<string> = computed(() => route.name as string);

    const globalStore = useGlobalStore();
    const { brand: currentBrand } = storeToRefs(globalStore);

    const themesManager: Ref<iThemeLink[]> = ref([
        {
            name: 'ManageTheme',
            label: t('allThemes'),
        },
        {
            name: 'ManageCategories',
            label: t('categories'),
        },
    ]);
</script>

<style lang="scss">
    .admin-nav-bar {
        margin-top: 8px;
        .--selected {
            border-radius: 8px;
            background: var(--grayscale-gray-regular) !important;
        }
    }
</style>
