<template>
    <div>
        <transition name="fadeY" mode="out-in">
            <div class="pos-users-list__header">
                <h3 class="fsb-typo-heading-5">{{ t('users') }} ({{ usersOfPos.length }})</h3>
                <ui-button :label="t('add')" left-icon="add" small @click="emit('showAddUser')"></ui-button>
            </div>
        </transition>
        <transition name="fadeY" mode="out-in">
            <b-list-group>
                <user-card
                    class="group-item"
                    v-for="(user, index) in users"
                    :key="user.uid"
                    v-model:user="users[index]"
                    :index="index"
                    :point-code="pointCode"
                    wrapper="b-list-group-item"
                    @delete-user-from-list="users.splice(index, 1)"
                ></user-card>
            </b-list-group>
        </transition>
    </div>
</template>

<script lang="ts" setup>
    import { iUserOfPos } from '@/interfaces/global';
    import { ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import UserCard from './UserCard.vue';

    const { t } = useI18n({ useScope: 'global' });

    const props = defineProps<{
        pointCode: string;
        usersOfPos: iUserOfPos[];
    }>();

    const emit = defineEmits(['showAddUser']);

    const users = ref(props.usersOfPos);
</script>

<style lang="scss" scoped>
    .group-item {
        border: none;
        border-bottom: 2px solid #f6f6f6;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
</style>
