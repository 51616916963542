<template>
    <div class="filter-switch">
        <div class="filter-switch__btn selected fsb-typo-btn-principap" @click="() => filterSwitch('all')" id="filter-theme-switchBtn_all" value="all">{{ t('all') }}</div>
        <div class="filter-switch__btn fsb-typo-btn-principap" @click="() => filterSwitch('active')" id="filter-theme-switchBtn_active" value="active">{{ t('active') }}</div>
        <div class="filter-switch__btn fsb-typo-btn-principap" @click="() => filterSwitch('inactive')" id="filter-theme-switchBtn_inactive" value="inactive">
            {{ t('inactive') }}
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    
    const emit = defineEmits(['switcherValue'])
    const filterSwitch = (status: string) => {
        emit('switcherValue', status);
        toggleActiveTheme(`filter-theme-switchBtn_${status}`);
    }

    const toggleActiveTheme = (btnId: string) => {
        const selectedBtn = document.getElementsByClassName('filter-switch__btn selected');
        selectedBtn[0]?.classList.remove('selected');
        const newSelectedBtn = document.getElementById(btnId);
        newSelectedBtn?.classList.add('selected');
    };

    const { t } = useI18n({ useScope: 'global' });
</script>

<style lang="scss">

    .filter-switch {
        border-radius: 12px;
        background-color: var(--grayscale-white);
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border: thin solid var(--grayscale-gray-regular);
        box-sizing: border-box;

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            padding: 12px 16px;
            color: var(--grayscale-black);
            background-color: var(--grayscale-white);
            transition: all ease-in-out var(--base-transition-duration);
            cursor: pointer;

            &.selected {
                background-color: var(--grayscale-black);
                border-radius: 12px;
                color: var(--grayscale-white);
            }
        }
    }

</style>