<template>
    <transition name="fadeX" mode="out-in">
        <fsb-dropdown
            ref="financialGroupDropdown"
            v-if="financialGroups.length"
            class="fin-groups-filter"
            :linkedInput="t('financialGroup')"
            :list="DROPDOWN_FIN_GROUPS"
            label-change-on-item-click
            close-dropdown-on-item-click
            close-dropdown-on-click-outside
        />
    </transition>
</template>

<script setup lang="ts">
    import { ref, computed, watch, defineExpose } from 'vue';
    import { useGlobalStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { iFinancialGroup, iGenericOption, iSelectedGroup } from '@/interfaces/global';

    import { useI18n } from 'vue-i18n';

    interface Props {
        itemsToFilter: iGenericOption[];
    }

    const props = defineProps<Props>();

    const emits = defineEmits(['update:financialGroup']);

    const { t } = useI18n({ useScope: 'global' });

    const globalStore = useGlobalStore();
    const { financialGroups } = storeToRefs(globalStore);

    const itemsToFilterContainPos = computed(() => props.itemsToFilter.some((item) => item.hasOwnProperty('pointofinterest')));

    const selectedGroup = ref([] as iSelectedGroup[]);
    const DROPDOWN_FIN_GROUPS = computed(() =>
        financialGroups.value.map((group: iFinancialGroup) => ({
            label: group.name,
            value: group.code,
            onClick: () => {
                if (selectedGroup.value.findIndex((g: any) => g.value === group.code) === -1) {
                    selectedGroup.value.push({ label: group.name, value: group.code });
                } else {
                    selectedGroup.value = selectedGroup.value.filter((g: any) => g.value !== group.code);
                }
            },
        }))
    );

    watch(
        () => selectedGroup.value,
        (groups) => {
            let result: iGenericOption[] = [];

            if (groups.length === 0) {
                emits('update:financialGroup', null);
            } else {
                for (const group of groups) {
                    const itemsContainingFinGroup = props.itemsToFilter.filter((item) => {
                        if (item.value || !itemsToFilterContainPos.value) {
                            return item.value?.includes(group.label);
                        } else {
                            const finGroupOfItem = item?.pointofinterest?.map((pos) => pos?.details?.financial_group);

                            return finGroupOfItem.filter(Boolean)?.includes(group.label);
                        }
                    });

                    result = [...result, ...itemsContainingFinGroup];
                }
                emits('update:financialGroup', [...new Set(result)]);
            }
        }
    );

    const financialGroupDropdown = ref(null);
    const resetFinancialDropdown = () => {
        for (const financialSelected of selectedGroup.value) {
            financialGroupDropdown.value?.onRemoveItem(financialSelected);
        }
        selectedGroup.value = [];
    };

    defineExpose({
        resetFinancialDropdown,
    });
</script>

<style lang="scss">
    .fin-groups-filter {
        > div {
            width: 200px !important;
            cursor: pointer;
        }
    }
</style>
