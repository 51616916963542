<template>
    <base-modal
        :modal-show="modalShow"
        id="theme-modal-edit"
        :title="t('editTheme')"
        hide-header-close
        :with-max-height="stepIndex === 1"
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="closeModal"
        :show-secondary-button="false"
        :primary-label="t('save')"
        :on-primary-button-click="onClickValidate"
        :is-primary-button-disabled="!isDataValid"
        @close-modal="closeModal"
    >
        <b-form class="form-create-theme">
            <create-theme-step-1
                v-show="stepIndex === 0"
                :theme-details="themeDetails"
                @update:value="($evt: any) => onUpdateValue({...$evt, id: 0})"
                @data-validation="($evt: any) => onDataValidation({...$evt, id: 0})"
            />

            <create-theme-step-2
                v-show="stepIndex === 1"
                :theme-details="themeDetails"
                @update:value="($evt: any) => onUpdateValue({...$evt, id: 1})"
                @data-validation="($evt: any) => onDataValidation({...$evt, id: 1})"
            />

            <create-theme-step-3
                v-show="stepIndex === 2"
                :theme-details="themeDetails"
                @update:value="($evt: any) => onUpdateValue({...$evt, id: 2})"
                @data-validation="($evt: any) => onDataValidation({...$evt, id: 2})"
            />
        </b-form>
    </base-modal>
</template>

<script lang="ts" setup>
    import { ref, computed, ComputedRef, Ref, watch, toRaw } from 'vue';
    import { useThemeStore, useCategoryStore, useGlobalStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';
    import { iCreaDetails, iDataValidation } from '@/interfaces/global';
    import { checkDataValid } from '@/helpers/dataValidation';
    import router from '@/router';
    import { useRoute } from 'vue-router';
    import { cloneDeep, isEqual } from 'lodash';

    import { updateCategories } from '@/helpers/categories';

    import CreateThemeStep1 from './CreateThemeStep1.vue';
    import CreateThemeStep2 from './CreateThemeStep2.vue';
    import CreateThemeStep3 from './CreateThemeStep3.vue';

    const { t } = useI18n({ useScope: 'global' });

    const route = useRoute();
    const { themeCode } = route.params;

    interface Props {
        stepIndex: number;
        themeCode: string;
        modalShow: boolean;
        closeModal: () => void;
    }

    const props = defineProps<Props>();

    const globalStore = useGlobalStore();
    const themeStore = useThemeStore();
    const categoryStore = useCategoryStore();

    const { brand } = storeToRefs(globalStore);
    const { themes } = storeToRefs(themeStore);
    const { categories } = storeToRefs(categoryStore);

    const themeDetails: ComputedRef<any> = computed(() => {
        return themes.value.find((t: iCreaDetails) => t.crea_code === props.themeCode) || null;
    });

    const dataValidation: Ref<iDataValidation[]> = ref([
        {
            id: 0,
            isValid: true,
        },
        {
            id: 1,
            isValid: true,
        },
        {
            id: 2,
            isValid: true,
        },
    ]);

    const dataValue: Ref<iCreaDetails> = ref({});
    const firstDataValue: Ref<iCreaDetails> = ref({});

    const isDataValid: ComputedRef<boolean> = computed(() => {
        if (!dataValue.value?.media_support) {
            return false;
        } else {
            return (
                checkDataValid(dataValidation.value, props.stepIndex) &&
                (themeDetails.value.media_support.length != dataValue.value.media_support.length || themeDetails.value.url_picture != dataValue.value.thumbnail_image || checkDataValidation.value)
            );
        }
    });

    const checkDataValidation = computed(() => {
        const normalizedFirstDataValue = normalize(firstDataValue.value);
        const normalizedDataValue = normalize(dataValue.value);
        return !isEqual(normalizedFirstDataValue, normalizedDataValue);
    });

    const normalize = (data: iCreaDetails) => {
        const rawData = toRaw(data);
        return {
            categories: rawData.categories
                ? rawData.categories
                      .map((c: any) => c.crea_category_code || c)
                      .filter((c: string) => c != undefined)
                      .sort()
                : [],
            categoriesToAdd: rawData.categoriesToAdd ? toRaw(rawData.categoriesToAdd).sort() : [],
            categoriesToRemove: rawData.categoriesToRemove ? toRaw(rawData.categoriesToRemove).sort() : [],
            details: {
                models: rawData.details?.models ? toRaw(rawData.details.models).sort() : [],
                operation_code: rawData.details?.operation_code ? toRaw(rawData.details.operation_code).sort() : [],
                trade: rawData.details?.trade ? toRaw(rawData.details.trade).sort() : [],
                groups: rawData.details?.groups ? toRaw(rawData.details.groups).sort() : [],
                banners: rawData.details?.banners ? [toRaw(rawData.details.banners)] : [],
            },
            end_date: rawData.end_date,
            label: rawData.label,
            start_date: rawData.start_date,
            validity_end_date: rawData.validity_end_date,
            validity_start_date: rawData.validity_start_date,
        };
    };

    const onDataValidation = ({ isDataValid, id }: { isDataValid: boolean; id: number }) => {
        const index = dataValidation.value.findIndex((d: iDataValidation) => d.id === id);
        dataValidation.value[index].isValid = isDataValid;
    };

    const onUpdateValue = ({ value, id = 0 }: { value: iCreaDetails; id: number }) => {
        const { details: valueDetails, ...restValue } = value;

        dataValue.value = {
            ...dataValue.value,
            ...restValue,
            details: {
                ...dataValue.value.details,
                ...valueDetails,
            },
        };

        if (id == 0 && value.hasOwnProperty('categories') && themeCode) {
            updateCategories(id, value, themeCode, categories, dataValue);
        }

        if (!Object.keys(firstDataValue.value).length || !Object.keys(firstDataValue.value).includes('thumbnail_image')) {
            firstDataValue.value = { ...dataValue.value };
        }
    };

    const editTheme = async (stepIndex: number) => {
        const payload = cloneDeep(dataValue.value);
        const { thumbnail_image: thumbnailImage } = dataValue.value;
        payload.thumbnail_image = thumbnailImage;

        const themeData = await themeStore.formatThemeDataForEdit(payload, props.themeCode, stepIndex);

        if (themeData) {
            await themeStore.updateTheme(props.themeCode, payload, stepIndex);
        }

        categoryStore.getCategories();
        categoryStore.getMetaCategories();

        // themeStore.getTheme(props.themeCode);
        themeStore.getThemes();
        themeStore.getMedias();

        router.push({ name: 'ThemeDetails', params: { brand: brand.value, themeCode: props.themeCode } });

        props.closeModal();
    };

    const onClickValidate = async () => {
        await editTheme(props.stepIndex);
    };

    themeStore.initCreateThemeData();

    watch(
        () => brand.value,
        (newBrand, oldBrand) => {
            if (newBrand !== oldBrand) {
                themeStore.initCreateThemeData();
            }
        }
    );
</script>

<!-- UNSCOPED for Modal style -->
<style lang="scss">
    #theme-modal-edit {
        .modal-body {
            .--with-max-height {
                overflow: hidden !important;
                display: flex;
                flex-direction: column;

                form {
                    overflow: hidden !important;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
</style>
