<template>
    <div class="manage-themes">
        <div class="theme-main">
            <div class="theme-main__header">
                <h1 class="fsb-typo-heading-1">{{ t('manageThemes') }}</h1>
                <ui-button variant="primary" :label="t('createTheme')" @click="openModal" />
            </div>

            <div class="theme-main__content">
                <theme-tab class="themeExist" :trigger-open-modal-delete="openModalDelete" @update:delete-theme="setThemeToDelete" v-if="!!brand" :ready-to-show-themes="showThemes" />

                <div class="no-theme" v-else>
                    <p class="fsb-typo-para-regular">{{ t('noThemeMessage') }}</p>
                    <ui-button variant="primary" :label="t('createTheme')" @click="openModal" />
                </div>
            </div>
        </div>

        <theme-modal-create :modal-show="modalShow" :close-modal="closeModal" />
        <theme-modal-delete :modal-show="modalShowDelete" :closeModal="closeModalDelete" :theme-code="themeToDelete" />
    </div>
</template>

<script lang="ts" setup>
    import { inject, ref, Ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useGlobalStore } from '@/store';
    import { storeToRefs } from 'pinia';

    import ThemeTab from '@/components/ThemesUi/ThemeTab.vue';
    import ThemeModalCreate from '@/components/ThemesUi/ThemeModalCreate.vue';
    import ThemeModalDelete from '@/components/ThemesUi/ThemeModalDelete.vue';

    const { t } = useI18n({ useScope: 'global' });

    const globalStore = useGlobalStore();
    const { isBrandNotFound, brand } = storeToRefs(globalStore);

    const useModal = inject('useModal');
    const { modalShow, openModal, closeModal } = useModal();
    const { modalShow: modalShowDelete, openModal: openModalDelete, closeModal: closeModalDelete } = useModal();

    const themeToDelete: Ref<string> = ref('');
    const setThemeToDelete = ({ themeCode }: { themeCode: string }) => {
        themeToDelete.value = themeCode;
    };

    const showThemes: Ref<boolean> = ref(true);

    watch(
        () => brand.value,
        () => {
            showThemes.value = false;
            setTimeout(() => {
                showThemes.value = true;
            }, 640);
        }
    );
</script>

<style lang="scss">
    .theme-main {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__content {
            margin-top: 24px;

            .no-theme {
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 16px;
            }
        }
    }
</style>
