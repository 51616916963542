<template>
    <div class="title" :class="{ '--padding': paddingBottom }">
        <h3 class="fsb-typo-heading-3">{{ title }}</h3>
        <ui-button v-if="labelIcon && isRightIcon" :label="labelIcon" :rightIcon="iconName" :variant="variant" @click="launchAction" />
        <ui-button v-else-if="labelIcon && !isRightIcon" :label="labelIcon" :leftIcon="iconName" :variant="variant" @click="launchAction" />
        <span v-else>
            <icon-button v-if="!hideIcon" :icon-type="iconName" size="sm" @click="launchAction" />
        </span>
    </div>
</template>

<script lang="ts" setup>
    interface Props {
        iconName?: string;
        variant?: string;
        labelIcon?: string;
        isRightIcon?: boolean;
        title?: string;
        paddingBottom?: boolean;
        hideIcon?: boolean;
    }
    const props = withDefaults(defineProps<Props>(), {
        iconName: 'edit',
        variant: 'secondary',
        isRightIcon: true,
        title: 'Informations de profil',
        paddingBottom: true,
        hideIcon: false,
    });

    const emit = defineEmits(['actionButton']);
    const launchAction = () => {
        emit('actionButton');
    };
</script>

<style lang="scss">
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.--padding {
            padding-bottom: 20px;
        }
    }
</style>
