import { iDataValidation } from '@/interfaces/global';
import { createObject } from '@/helpers/tools';

export const findIsRequired = (id: string, dataValidationArr: iDataValidation[]) => {
    const entry: iDataValidation | undefined = dataValidationArr.find((d: iDataValidation) => d.id === id);
    return entry ? entry.required : false;
};

export const checkDataValid = (dataValidationArr: iDataValidation[], id?: number | string) => {
    const isCheckAllDataValid = id === undefined;
    if (isCheckAllDataValid) {
        return dataValidationArr.every((d: iDataValidation) => (d.required ? d.isValid : true));
    } else {
        const dataValidation = dataValidationArr.find((d: iDataValidation) => d.id === id);
        return !!(dataValidation && dataValidation.isValid);
    }
};

export const checkIsValidForEntry = (entry: iDataValidation, value?: any, checkValue?: () => boolean) => {
    const { required } = entry;
    const checkValueFn = checkValue || (() => !!value);
    const newIsValid = required ? checkValueFn() : true;
    return newIsValid;
};

export const formatDataValue = (entry: iDataValidation, value: any) => {
    const { id, keyDataValue, formatValueAsArray } = entry;

    let dataValue: any = {};

    if (keyDataValue) {
        dataValue = createObject(keyDataValue, formatValueAsArray ? [value] : value);
    } else {
        dataValue = { [id]: formatValueAsArray ? [value] : value };
    }

    return dataValue;
};
