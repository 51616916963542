import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_GW_ADMIN_URL,
});

const setHeaders = (axiosInstance: AxiosInstance, headers: AxiosRequestHeaders) => {
    axiosInstance.defaults.headers.common = {
        ...axiosInstance.defaults.headers.common,
        ...headers,
    };
};

export { axiosInstance, setHeaders };
