<template>
    <span class="check-field" :class="getStatus">●</span>
</template>

<script lang="ts" setup>
    import { defineProps, computed, ComputedRef } from 'vue';

    const props = withDefaults(
        defineProps<{
            status?: boolean;
        }>(),
        {
            status: false,
        }
    );

    const getStatus: ComputedRef<string> = computed(() => {
        if (props.status == true) return 'ok';
        else {
            return 'nok';
        }
    });
</script>

<style scoped lang="scss">
    .check-field {
        display: inline-block;
        margin-left: 4px;

        &.ok {
            color: green;
            font-size: 8px;
        }

        &.nok {
            color: red;
            font-size: 8px;
        }
    }
</style>
