<template>
    <section v-if="themeDetails" class="theme-details">
        <div class="theme-details__header">
            <icon-button icon-type="chevron-left" variant="white" :on-click="goBack" />
            <h1 class="fsb-typo-heading-1">{{ themeLabel }}</h1>
        </div>

        <separator-gap :height="24" />

        <div class="theme-details__content">
            <base-card>
                <div class="card__header">
                    <h2 class="fsb-typo-heading-4">{{ t('informations') }}</h2>
                    <icon-button icon-type="edit" :on-click="() => handleEdit(0)" />
                </div>
                <div class="card__content">
                    <div class="card__content__section">
                        <p class="section__header fsb-typo-para-regular">{{ t('themeName') }}</p>
                        <p class="section__content fsb-typo-para-medium">{{ themeLabel }}</p>
                    </div>

                    <div class="card__content__section">
                        <p class="section__header fsb-typo-para-regular">{{ t('operation') }}</p>
                        <p class="section__content fsb-typo-para-medium">{{ operation?.name || t('undefined') }}</p>
                    </div>

                    <div class="card__content__section--trade-brand">
                        <div class="section__content d-flex justify-content-between">
                            <div>
                                <p class="section__header--brand fsb-typo-para-regular">{{ t('brand') }}</p>
                                <div class="categories__badges fsb-typo-para-medium" v-if="brandsTheme.length">
                                    <span v-for="brand in brandsTheme" :key="brand">{{ brand }}</span>
                                </div>
                                <p class="section__content fsb-typo-para-medium" v-else>
                                    {{ t('undefined') }}
                                </p>
                            </div>
                            <div>
                                <p class="section__header--trade fsb-typo-para-regular">{{ t('trade') }}</p>
                                <p class="section__content fsb-typo-para-medium" v-if="tradesTheme.length">
                                    <span v-for="trade in tradesTheme" :key="trade">{{ trade }}</span>
                                </p>
                                <p class="section__content fsb-typo-btn-principal" v-else>{{ t('undefined') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="card__content__section">
                        <p class="section__header fsb-typo-para-regular">{{ t('products') }}</p>
                        <div class="categories__badges fsb-typo-para-medium" v-if="products.length">
                            <tag-label v-for="product in products" :key="`theme-model-${product}`" :label="product" custom-color="var(--grayscale-gray-regular)" />
                        </div>
                        <p class="section__content fsb-typo-para-medium" v-else>
                            {{ t('undefined') }}
                        </p>
                    </div>

                    <div class="card__content__section--period">
                        <p class="fsb-typo-heading-6">{{ t('availablePeriodOnAdcleek') }}</p>
                        <div class="section__content">
                            <div>
                                <p class="section__header fsb-typo-para-regular">{{ t('startDate') }}</p>
                                <p class="section__content fsb-typo-btn-principal">{{ themeDetails.validity_start_date }}</p>
                            </div>
                            <div>
                                <p class="section__header fsb-typo-para-regular">{{ t('endDate') }}</p>
                                <p class="section__content fsb-typo-btn-principal">{{ themeDetails.validity_end_date }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="card__content__section--period">
                        <p class="fsb-typo-heading-6">{{ t('campaignPeriod') }}</p>
                        <div class="section__content">
                            <div>
                                <p class="section__header fsb-typo-para-regular">{{ t('startDate') }}</p>
                                <p class="section__content fsb-typo-btn-principal">{{ themeDetails.start_date }}</p>
                            </div>
                            <div>
                                <p class="section__header fsb-typo-para-regular">{{ t('endDate') }}</p>
                                <p class="section__content fsb-typo-btn-principal">{{ themeDetails.end_date }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="card__content__section">
                        <p class="fsb-typo-heading-6">{{ t('categories') }}</p>

                        <div class="categories" v-if="(categoriesList.length === 1 && categoriesList[0].cats.length) || categoriesList.length > 1">
                            <div v-for="metacat in categoriesList" :key="`theme-catagory-${metacat.crea_metacategory_code}`" class="categories__item">
                                <p class="categories__meta fsb-typo-para-regular" v-if="metacat.cats.length">{{ metacat.name }}</p>
                                <div class="categories__badges">
                                    <tag-label v-for="cat in metacat.cats" :key="`theme-catagory-${cat.crea_category_code}`" :label="cat.name" custom-color="var(--grayscale-gray-regular)" />
                                </div>
                            </div>
                        </div>
                        <div class="categories" v-else>
                            <p>{{ t('undefined') }}</p>
                        </div>
                    </div>
                </div>
            </base-card>
            <base-card>
                <div class="card__header">
                    <h2 class="fsb-typo-heading-4">{{ t('medias') }} {{ mediasCount }}</h2>
                    <icon-button icon-type="edit" :on-click="() => handleEdit(1)" />
                </div>

                <div class="card__content">
                    <nothing-found v-if="themeMediaData.length === 0" :text="t('noMediaAvailable')" />

                    <div v-else class="medias">
                        <div v-for="(item, index) in themeMediaData" :key="`media-item-${index}`" class="medias__item">
                            <separator-gap :height="4" with-border />
                            <p>
                                <ad-icon :icon="item.media_support_code" size="large"></ad-icon>
                                <span class="fsb-typo-btn-principal">{{ item.name ?? item.media_support_code }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </base-card>
            <base-card>
                <div class="card__header">
                    <h2 class="fsb-typo-heading-4">{{ t('bannersAndThumbnail') }}</h2>
                    <icon-button icon-type="edit" :on-click="() => handleEdit(2)" />
                </div>
                <div class="card__content">
                    <nothing-found v-if="!themeThumbnail" :text="t('noThumbnailAvailable')" />
                    <img v-else :src="themeThumbnail" alt="" />
                </div>
            </base-card>
        </div>

        <theme-modal-edit :modal-show="modalShow" :close-modal="closeModal" :step-index="editThemeStepIndex" :theme-code="themeCode" />
    </section>
    <nothing-found v-else :text="t('loadingTheme')" />
</template>

<script lang="ts" setup>
    import { ref, computed, ComputedRef, Ref, inject } from 'vue';
    import { useRoute } from 'vue-router';
    import router from '@/router/index';
    import { useThemeStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { useCategoryStore, useCustomerStore } from '@/store';

    import ThemeModalEdit from '@/components/ThemesUi/ThemeModalEdit.vue';

    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });

    const route = useRoute();
    const { themeCode } = route.params;
    const themeStore = useThemeStore();
    const categoryStore = useCategoryStore();
    const customerStore = useCustomerStore();
    const { activations, medias, models, themes, brandsGroups } = storeToRefs(themeStore);
    const { categories, metaCategories } = storeToRefs(categoryStore);
    const { trades } = storeToRefs(customerStore);

    const useModal = inject('useModal');

    const { modalShow, openModal, closeModal } = useModal();

    const themeLabel = computed(() => themeDetails.value?.label || t('undefined'));

    const themeDetails = computed(() => {
        return themes.value.find((theme) => theme.crea_code === themeCode);
    });

    const editThemeStepIndex: Ref<number> = ref(0);

    const categoriesList: ComputedRef<any[]> = computed(() => {
        const metaCats = [{ name: t('noMetacategory'), cats: [] }];

        if (themeDetails.value) {
            if (categories.value && metaCategories.value) {
                const catsList = categories.value.filter((c: any) => c.creas?.findIndex((link: any) => link.crea_code === themeDetails.value.crea_code) !== -1);
                if (catsList.length > 0) {
                    catsList.forEach((cat) => {
                        if (cat.metas && cat.metas.length) {
                            cat.metas.forEach((meta) => {
                                const { details: metaCatDetails } = meta;

                                if (metaCatDetails) {
                                    metaCatDetails.cats = [];
                                    const metaCatIndex = metaCats.findIndex((m) => m.crea_metacategory_code === metaCatDetails.crea_metacategory_code);
                                    if (metaCatIndex === -1) {
                                        metaCatDetails.cats.push(cat);
                                        metaCats.push(metaCatDetails);
                                    } else {
                                        metaCats[metaCatIndex].cats.push(cat);
                                    }
                                }
                            });
                        } else {
                            metaCats[0].cats.push(cat);
                        }
                    });
                }
            }
        }

        return metaCats;
    });

    //get label value associated with a specific operation_code
    const operation: ComputedRef<any> = computed(() => {
        if (activations.value && activations.value.length) {
            if (themeDetails.value?.details?.operation_code) {
                const { operation_code } = themeDetails.value?.details;
                const activationsArray = Object.values(activations.value);

                if (typeof operation_code === 'string') return activationsArray.find((a) => a.operations?.find((o) => o.code === operation_code))?.operations?.find((o) => o.code === operation_code);
                else if (typeof operation_code === 'object') {
                    return activationsArray.find((a) => a.operations?.find((o) => operation_code.includes(o.code)))?.operations?.find((o) => operation_code.includes(o.code));
                } else return '';
            } else return '';
        } else {
            return '';
        }
    });

    const products: ComputedRef<string[]> = computed(() => {
        if (models.value && models.value.length) {
            return themeDetails.value?.details?.models?.map((modelCode) => models.value.find((m) => m.model_code === modelCode)?.name).filter(Boolean) || [];
        } else {
            return [];
        }
    });

    const tradesTheme = computed(() => {
        if (trades.value && trades.value.length) {
            return themeDetails.value?.details?.trade?.map((tradeCode: string) => trades.value.find((t) => t.trade_code === tradeCode)?.label) || [];
        } else return [];
    });

    const brandsTheme = computed(() => {
        if (themeDetails.value?.details?.groups && brandsGroups.value && brandsGroups.value.length) {
            return themeDetails.value?.details?.groups?.map((groupCode: string) => {
                if (groupCode !== 'all') {
                    return brandsGroups.value.find((group) => group.code === groupCode)?.name || null;
                } else {
                    return t('allBrands');
                }
            });
        } else return [];
    });

    //it returns an array of data for each media related to the theme
    const themeMediaData = computed(() => {
        return themeDetails.value?.media_support?.map((code) => medias.value.find((media) => media.media_support_code === code)).filter(Boolean) || [];
    });

    const mediasCount: ComputedRef<string> = computed(() => `(${themeMediaData.value.length})`);

    const themeThumbnail: ComputedRef<string> = computed(() => themeDetails?.value?.url_picture ?? '');

    const handleEdit = (stepIndex: number) => {
        editThemeStepIndex.value = stepIndex;
        openModal();
    };

    const goBack = () => {
        router.go(-1);
    };

    themeStore.getThemes();
    categoryStore.getCategories();
    categoryStore.getMetaCategories();
    themeStore.getMedias();

    // TODO: for long term run, we should switch to
    // using this get single theme
    // themeStore.getTheme(themeCode as string);
</script>

<style lang="scss">
    .theme-details {
        display: flex;
        flex-direction: column;

        &__header {
            display: flex;
            gap: 16px;
            justify-content: flex-start;
            align-items: center;
        }

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 24px;
            width: 100%;

            img {
                width: 292px !important;
                height: 292px !important;
                max-width: none;
            }

            .fsb-base-card {
                flex: 1;

                &:first-child,
                &:last-child {
                    max-width: 32vw;
                }

                &:last-child {
                    overflow: hidden;

                    .card__content {
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .card {
                &__header {
                    display: flex;
                    gap: 16px;
                    justify-content: flex-start;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                &__content {
                    flex: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .fsb-base-iframe {
                        align-self: center;
                    }

                    .medias {
                        display: flex;
                        flex-direction: column;

                        &__item {
                            transition: background-color ease-in-out var(--base-transition-duration);
                            background-color: transparent;

                            & > p {
                                width: 100%;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 8px;
                                gap: 8px;
                            }
                        }
                    }

                    .categories {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        margin-top: 4px;

                        &__item {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                        }

                        &__meta {
                            color: var(--grayscale-gray-dark);
                        }

                        &__badges {
                            display: flex;
                            gap: 8px;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                        }
                    }

                    &__section {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        .card__header {
                            color: var(--grayscale-gray-dark);
                        }

                        .section__header {
                            color: var(--grayscale-gray-dark);
                        }

                        &--period,
                        &--trade-brand {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .section__content {
                                display: flex;
                                width: 100%;
                                justify-content: flex-start;
                                gap: 16px;

                                & > div {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 4px;
                                    width: 50%;

                                    .section__header {
                                        &--brand,
                                        &--trade {
                                            color: var(--grayscale-gray-dark);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
