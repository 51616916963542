interface Mandate {
    [key: string]: any | any[];
    validity: number;
}
export default function (mandates: Mandate[]) {
    // Get the current year
    const currentYear = new Date().getFullYear();

    // Sort mandates by validity year in descending order
    mandates.sort((a, b) => b.validity - a.validity);

    // Find the mandate with the validity year closest to the current year
    for (const mandate of mandates) {
        if (mandate.validity <= currentYear) {
            return mandate;
        }
    }

    // If no mandate is found, return null or handle as needed
    return null;
}
