import { iRole } from '@/interfaces/global';

export const ROLE_LIST: iRole[] = [
    {
        value: 1,
        color: '#DBE2F5',
        label: 'principal',
    },
    {
        value: 2,
        color: '#F5DBEB',
        label: 'secondary',
    },
    {
        value: 3,
        color: '#DBF5E2',
        label: 'rrmpRole',
    },
    {
        value: 9,
        color: '#F5DBDB',
        label: 'adcleek',
    },
];

export const MAX_USERS_MULTI_BRANDS = 10;
