<template>
    <base-modal
        :modal-show="modalShow"
        id="theme-modal-create"
        :title="t('createTheme')"
        hide-header-close
        :with-max-height="stepIndex === 1"
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="onCloseModal"
        :secondary-label="t('previous')"
        :on-secondary-button-click="previousStep"
        :show-secondary-button="stepIndex > 0"
        :primary-label="stepIndex !== 2 ? t('next') : t('create')"
        :on-primary-button-click="onClickValidate"
        :is-primary-button-disabled="!isDataValid"
        @close-modal="onCloseModal"
    >
        <b-form class="form-create-theme">
            <transition :name="transitionName" mode="out-in">
                <create-theme-step-1 v-show="stepIndex === 0" @update:value="($evt: any) => onUpdateValue({...$evt, id: 0})" @data-validation="($evt: any) => onDataValidation({...$evt, id: 0})" />
            </transition>
            <transition :name="transitionName" mode="out-in">
                <create-theme-step-2 v-show="stepIndex === 1" @update:value="($evt: any) => onUpdateValue({...$evt, id: 1})" @data-validation="($evt: any) => onDataValidation({...$evt, id: 1})" />
            </transition>
            <transition :name="transitionName" mode="out-in">
                <create-theme-step-3 v-show="stepIndex === 2" @update:value="($evt: any) => onUpdateValue({...$evt, id: 2})" @data-validation="($evt: any) => onDataValidation({...$evt, id: 2})" />
            </transition>
        </b-form>
    </base-modal>
</template>

<script lang="ts" setup>
    import { ref, computed, ComputedRef, Ref, watch } from 'vue';
    import { useThemeStore } from '@/store';
    import { useGlobalStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';
    import { iDataValidation } from '@/interfaces/global';
    import { checkDataValid } from '@/helpers/dataValidation';
    import router from '@/router';
    import { cloneDeep } from 'lodash';

    import CreateThemeStep1 from './CreateThemeStep1.vue';
    import CreateThemeStep2 from './CreateThemeStep2.vue';
    import CreateThemeStep3 from './CreateThemeStep3.vue';

    interface Props {
        modalShow: boolean;
        closeModal: () => void;
    }

    const props = defineProps<Props>();

    const { t } = useI18n({ useScope: 'global' });

    const globalStore = useGlobalStore();
    const themeStore = useThemeStore();

    const { brand } = storeToRefs(globalStore);

    const stepIndex: Ref<number> = ref(0);

    const incrementing: Ref<boolean> = ref(true);

    const transitionName: ComputedRef<string> = computed(() => `${incrementing.value ? 'next' : 'previous'}Step`);

    const dataValidation: Ref<iDataValidation[]> = ref([
        {
            id: 0,
            isValid: false,
        },
        {
            id: 1,
            isValid: true,
        },
        {
            id: 2,
            isValid: false,
        },
    ]);

    const isDataValid: ComputedRef<boolean> = computed(() => {
        return checkDataValid(dataValidation.value, stepIndex.value);
    });

    const onDataValidation = ({ isDataValid, id }: { isDataValid: boolean; id: number }) => {
        const index = dataValidation.value.findIndex((d: iDataValidation) => d.id === id);
        dataValidation.value[index].isValid = isDataValid;
    };

    const dataValue: Ref<any> = ref({});

    const onUpdateValue = ({ value, id }: { value: any; id: number }) => {
        const { details } = dataValue.value;
        const valueHasDetails = value.hasOwnProperty('details');

        dataValue.value = { ...dataValue.value, ...(valueHasDetails ? { details: { ...details, ...value.details } } : value) };

        if (id === 0 && value.hasOwnProperty('categories')) {
            if (!dataValue.value.hasOwnProperty('categoriesToAdd')) {
                dataValue.value.categoriesToAdd = [];
            }

            dataValue.value.categoriesToAdd = [...value.categories];
        }
    };

    const previousStep = () => {
        if (stepIndex.value >= 0) {
            stepIndex.value = stepIndex.value - 1;
            incrementing.value = false;
        }
    };

    const nextStep = () => {
        if (stepIndex.value < 2) {
            stepIndex.value = stepIndex.value + 1;
            incrementing.value = true;
        }
    };

    const createTheme = async () => {
        const payload = cloneDeep(dataValue.value);
        const { thumbnail_image: thumbnailImage } = dataValue.value.thumbnail_image;
        payload.thumbnail_image = thumbnailImage;

        const themeData = await themeStore.formatThemeDataForCreate(payload);

        if (themeData) {
            const { models }: { models: string[] } = dataValue.value.details;

            const newThemeCode: string = await themeStore.createTheme(themeData, models);

            if (newThemeCode) {
                router.push({ name: 'ThemeDetails', params: { brand: brand.value, themeCode: newThemeCode } });
            }
        }

        props.closeModal();
    };

    const onClickValidate = async () => {
        if (stepIndex.value === 2) {
            await createTheme();
        } else {
            nextStep();
        }
    };

    const onCloseModal = () => {
        stepIndex.value = 0;
        props.closeModal();
    };

    themeStore.initCreateThemeData();

    watch(
        () => brand.value,
        (newBrand, oldBrand) => {
            if (newBrand !== oldBrand) {
                themeStore.initCreateThemeData();
            }
        }
    );
</script>

<!-- UNSCOPED for Modal style -->
<style lang="scss">
    #theme-modal-create {
        .modal-body {
            .--with-max-height {
                overflow: hidden !important;
                display: flex;
                flex-direction: column;

                form {
                    overflow: hidden !important;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
</style>
