<template>
    <base-modal
        :modal-show="modalShow"
        id="category-modal-assign"
        :title="t('addTheme')"
        hide-header-close
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="closeModal"
        :show-secondary-button="false"
        :primary-label="t('add')"
        :on-primary-button-click="saveThemes"
        size="lg"
        @close-modal="closeModal"
    >
        <div class="category-modal-assign__search">
            <multi-select
                class="fsb-typo-heading-6"
                id="select-assign-themes"
                :items="formattedThemes"
                :search-label="t('selectTheme')"
                :field-label-text="t('campaignTheme')"
                @update:value="($evt: any) => updateSelectedThemes($evt)"
                with-search-bar
            />
        </div>
        <div class="category-modal-assign__themes">
            <p class="fsb-typo-heading-6">{{ t('selectedThemes') }}</p>
            <div class="category-modal-assign__themes-container" v-if="selectedThemes.length">
                <p class="category-modal-assign__theme" v-for="theme in selectedThemes" :key="`selected-theme-${theme.crea_code}`">
                    <span class="fsb-typo-btn-principal">{{ theme.details?.label ?? theme.label }}</span>
                    <icon-button icon-type="delete" variant="tertiary" :on-click="() => softDeleteTheme(theme)" />
                </p>
            </div>
        </div>

        <modal-delete v-model:show-modal="showModalDelete" @delete="deselectTheme">
            <p>
                {{ t('irreversibleAction') }}
            </p>
        </modal-delete>
    </base-modal>
</template>

<script lang="ts" setup>
    import { ref, Ref, ComputedRef, computed, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useCategoryStore, useThemeStore, useGlobalStore } from '@/store';
    import { iCategory, iMultiSelectOption } from '@/interfaces/global';
    import { storeToRefs } from 'pinia';

    import ModalDelete from '../SharedUi/ModalDelete.vue';

    interface Props {
        modalShow: boolean;
        closeModal: () => void;
        categoryCode: string;
    }

    const props = defineProps<Props>();

    const { t } = useI18n({ useScope: 'global' });

    const categoryStore = useCategoryStore();
    const { categories } = storeToRefs(categoryStore);

    const themeStore = useThemeStore();
    const { themes } = storeToRefs(themeStore);

    const categoryDetails: ComputedRef<any | undefined> = computed(() => categories.value.find((cat) => cat.crea_category_code === props.categoryCode));

    const formattedThemes: Ref<iMultiSelectOption[]> = ref(
        themes.value.map((t: any) => ({
            value: t.crea_code,
            label: t.label,
            selected: categoryDetails.value?.creas?.findIndex((cc) => cc.crea_code === t.crea_code) !== -1,
        }))
    );

    const selectedThemes: ComputedRef<any[]> = computed(() => {
        return formattedThemes.value.filter((t) => t.selected);
    });

    const showModalDelete: Ref<boolean> = ref(false);

    const themeToDelete: Ref<string | null> = ref(null);

    const updateSelectedThemes = (newSelectedThemes: { value: string[] }) => {
        formattedThemes.value.forEach((theme) => {
            theme.selected = newSelectedThemes.value.includes(theme.value);
        });
    };

    const softDeleteTheme = (theme: string) => {
        showModalDelete.value = true;
        themeToDelete.value = theme;
    };

    const deselectTheme = () => {
        if (themeToDelete.value) {
            formattedThemes.value = formattedThemes.value.map((theme) => {
                if (theme.value === themeToDelete.value.value) {
                    return { ...theme, selected: false };
                }
                return theme;
            });
            themeToDelete.value = null;
        }
        setTimeout(() => {
            showModalDelete.value = false;
        }, 100);
    };

    const saveThemes = async () => {
        const newThemes = selectedThemes.value.filter((t) => {
            return !categoryDetails.value?.creas?.some((cc: any) => cc.crea_code === t.value);
        });

        for await (const newTheme of newThemes) {
            await themeStore.associateCreaToCategory(newTheme.value, props.categoryCode);
        }

        const creaToCategoryCodesToDelete =
            categoryDetails.value?.creas?.filter((cc: any) => !selectedThemes.value.some((t) => t.value === cc.crea_code))?.map((cc: any) => cc.crea_to_category_code) || [];

        if (creaToCategoryCodesToDelete.length === 0) {
            console.log('✅ No themes to remove.');
        } else {
            for await (const crea_to_category_code of creaToCategoryCodesToDelete) {
                await themeStore.dissociateCreaToCategory(crea_to_category_code);
            }
        }

        categoryStore.getCategories();
        categoryStore.getMetaCategories();

        props.closeModal();
    };

    watch(
        () => categoryDetails.value,
        (newCategoryDetails) => {
            if (newCategoryDetails) {
                console.log(newCategoryDetails);
            }
        }
    );

    watch([() => themes.value, () => categoryDetails.value, () => props.modalShow], ([newThemes, newCategoryDetails, modalShow]) => {
        if (!modalShow) return;
        formattedThemes.value = newThemes.map((t: any) => ({
            value: t.crea_code,
            label: t.label,
            selected: !!newCategoryDetails?.creas?.some((cc) => cc.crea_code === t.crea_code),
        }));
    });
</script>

<!-- UNSCOPED for Modal style -->
<style lang="scss">
    #category-modal-assign {
        .modal-body {
            overflow: hidden;
            display: flex;
            flex-direction: row;

            .fsb-base-modal__content {
                display: flex;
                flex-direction: row;
                gap: 16px;
                width: 100%;

                & > * {
                    flex: 1;
                }
            }

            p {
                margin: 0;
                padding: 0;
            }

            .category-modal-assign {
                &__themes {
                    overflow: hidden;
                    flex-grow: 1;

                    gap: 0;

                    & > p:first-child {
                        margin-bottom: 8px;
                    }

                    &-container {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        overflow-y: scroll;
                        max-height: 320px;
                    }
                }

                &__theme {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: transparent;
                }
            }
        }
    }
</style>
