<template>
    <div class="box">
        <div class="box-header">
            <div class="brand-and-title">
                <img :src="`https://resources.adcleek.it/global/brandLogo/${brand}.svg`" />
                <h5 class="fsb-typo-para-big" style="font-weight: 500">{{ brand }}</h5>
            </div>
            <icon-button icon-type="delete" size="12" variant="white" @click="showDeleteModal = true" />
        </div>
        <div class="box-content">
            <div v-for="data in rightsData" :key="data.service" class="select-bloc">
                <h5 class="fsb-typo-para-big" style="color: #1f1f1f">{{ t(data.service) }}</h5>
                <fsb-dropdown v-model="data.selectedRole" :linked-input="true" :list="data.roles" close-dropdown-on-item-click close-dropdown-on-click-outside select-one :full-with="true" />
            </div>
            <div class="buttons-bloc">
                <ui-button :label="t('cancel')" variant="secondary" @click="emit('closeEdit')" />
                <ui-button :label="t('confirm')" variant="primary" @click="updateRolesOfBrand" />
            </div>
        </div>

        <modal-delete v-model:show-modal="showDeleteModal" @delete="deleteBrand">
            <p>{{ t('modalMessageToDeleteBrand1', { brand }) }}</p>
            <p>{{ t('modalMessageToDeleteBrand2') }}</p>
        </modal-delete>
    </div>
</template>

<script lang="ts" setup>
    import { iOption } from '@/interfaces/global';
    import { useUsersStore } from '@/store';
    import { Ref, inject, onMounted, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import ModalDelete from '../SharedUi/ModalDelete.vue';

    const snackBar: { open(variant: string, text: string, delay?: number): void } | undefined = inject('snackBar');
    const { t } = useI18n({ useScope: 'global' });
    const usersStore = useUsersStore();

    const props = defineProps<{
        uid: string;
        brand: string;
        rights: {
            service: string;
            role: string;
        }[];
    }>();

    const emit = defineEmits(['update:rights', 'closeEdit', 'deleteBrand']);

    const rightsData: Ref<{ service: string; roles?: iOption[]; selectedRole: iOption[] }[]> = ref([]);
    const showDeleteModal = ref(false);

    const deleteBrand = async () => {
        try {
            await usersStore.deleteBrandOfUser(props.uid, props.brand);
            emit('deleteBrand');
        } catch (error) {
            console.log(error);
        }
    };

    const updateRolesOfBrand = async () => {
        const { rolesToUpdate, newRights } = getRolesToUpdateAndNewRights();
        try {
            if (rolesToUpdate.length > 0) {
                await usersStore.updateUserRoles(props.uid, props.brand, rolesToUpdate);
                emit('update:rights', newRights);
            }
            snackBar?.open('success', t('successMessageOfModifications'));
            emit('closeEdit');
        } catch (error) {
            console.log(error);
        }
    };

    const getRolesToUpdateAndNewRights = () => {
        const rolesToUpdate: { roleValue: string; rightId: string }[] = [];
        const newRights: { service: string; role: string }[] = [];
        const roleByService = props.rights.reduce((acc: { [x: string]: string }, item) => {
            return { ...acc, [item.service]: item.role };
        }, {});

        rightsData.value.forEach((data) => {
            if (data.selectedRole[0].role !== roleByService[data.service]) {
                rolesToUpdate.push({
                    roleValue: data.selectedRole[0].value,
                    rightId: data.selectedRole[0].rightId,
                });
            }
            newRights.push({
                service: data.service,
                role: data.selectedRole[0].role,
            });
        });
        return { rolesToUpdate, newRights };
    };

    const getRightsData = async () => {
        const rolesByService = await usersStore.getRolesByService(props.rights.map((item) => item.service));
        if (rolesByService) {
            rightsData.value.forEach((data) => {
                const roles = rolesByService[data.service].roles.map(({ value, role }) => {
                    return { value, role, label: t(role), rightId: rolesByService[data.service].rightId };
                });
                data.roles = roles;
                data.selectedRole[0] = roles.find((roleData) => roleData.role === props.rights.find((item) => item.service === data.service)?.role) || { value: '' };
            });
        }
    };

    onMounted(async () => {
        rightsData.value = props.rights.map(({ service, role }) => {
            return { service, selectedRole: [{ value: '', label: role ? t(role) : t('undefined') }] };
        });
        await getRightsData();
    });
</script>

<style lang="scss" scoped>
    .box {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--grayscale-gray-regular, #dbdbdb);
        padding: 0 !important;
        border-radius: 6px;

        .box-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 2px 18px 2px 18px;

            .brand-and-title {
                display: flex;
                align-items: center;

                img {
                    height: 20px;
                    margin-right: 10px;
                }
            }
        }

        .box-content {
            display: flex;
            flex-direction: column;
            background-color: var(--grayscale-gray-light);
            width: 100%;
            padding: 8px 18px 8px 18px;

            .select-bloc {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                padding-bottom: 9px;

                :deep(.fsb-dropdown-linked-input__label) {
                    color: black !important;
                }
            }
        }

        .buttons-bloc {
            display: flex;
            justify-content: center;
            padding-top: 6px;
        }
    }
</style>
