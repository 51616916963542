import axios from 'axios';
import { createI18n } from 'vue-i18n';

const DEFAULT_APP_LANGUAGE = 'fr';

const navLanguage: string = window.navigator.language.split('-')[0] || DEFAULT_APP_LANGUAGE;

const getTranslations = () => axios.get(process.env.VUE_APP_TRANSLATIONS_URL + '/admin/?lang=' + navLanguage + '&index.count=100000000');

const i18nModule = () =>
    new Promise(async (resolve, reject) => {
        try {
            getTranslations().then((response: any) => {
                const translationsRaw: any[] = response.data;

                const translations = {};
                const lang = ['fr', 'en', 'de', 'es', 'it'];

                lang.forEach((language) => {
                    (translations as any)[language] = {};
                });

                translationsRaw.forEach((translation: any) => {
                    (translations as any)[translation.lang][translation.name] = translation.value;
                });
                (window as any).tr = translations;

                const i18n = createI18n({
                    locale: navLanguage,
                    messages: translations,
                    legacy: false,
                });

                resolve(i18n);
            });
        } catch (error) {
            reject(error);
        }
    });

export { navLanguage as APP_LOCALE };

export default i18nModule;
