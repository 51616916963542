<template>
    <tag-label v-bind="tagProps" class="tag-label--subscription"></tag-label>
</template>

<script lang="ts">
    export const SUBSCRIPTION_TAG = (activeStatus: number, subscriptionStatus: number) => {
        if (activeStatus === 1) {
            return {
                variant: 'green',
                label: 'activeSubscribed',
            };
        }

        switch (subscriptionStatus) {
            case 0:
                return {
                    variant: 'gray',
                    label: 'inactiveSubscribed',
                };

            case 1:
                return {
                    variant: 'green',
                    label: 'activeSubscribed',
                };

            case 2:
                return {
                    customColor: 'var(--grayscale-gray-regular)',
                    label: 'inactiveUnsubscribed',
                };

            default:
                return {
                    variant: '',
                    label: '',
                };
        }
    };
</script>

<script setup lang="ts">
    import { computed, ComputedRef } from 'vue';

    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });

    interface Props {
        subscriptionStatus: number;
        activeStatus: number;
    }

    const props = defineProps<Props>();

    const tagProps: ComputedRef<{ variant: string; label: string }> = computed(() => {
        const tag = SUBSCRIPTION_TAG(props.activeStatus, props.subscriptionStatus);

        return {
            ...tag,
            label: t(tag.label),
        };
    });
</script>
