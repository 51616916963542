<template>
    <div class="manage-pos">
        <div v-show="!zoneStore.showZoneEditorOfPos" class="pos-main">
            <div class="pos-main__header">
                <h1 class="fsb-typo-heading-1">{{ t('posList') }}</h1>
                <div class="pos-main__dropdown">
                    <fsb-dropdown
                        :linked-button="linkedButtonExport"
                        :list="EXPORT_DROPDOWN_LIST"
                        :select-one="true"
                        :close-dropdown-on-item-click="true"
                        dropdown-position="left"
                        close-dropdown-on-click-outside
                        no-select
                        :list-width="220"
                        :offset="{ left: -110 }"
                    />
                </div>
            </div>

            <div class="theme-main__content">
                <pos-list />
            </div>
        </div>
        <zone-editor-of-pos v-if="zoneStore.showZoneEditorOfPos" />
    </div>
</template>

<script setup lang="ts">
    import PosList from '@/components/PosUi/PosList.vue';
    import ZoneEditorOfPos from '@/components/PosUi/ZoneEditorOfPos.vue';
    import { useZoneStore } from '@/store/views/zone';
    import { ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });

    const zoneStore = useZoneStore();

    const onExport = async (value: string) => {
        if (value) {
            if (value === 'exportPosListA') {
                //
            } else if (value === 'exportPosListB') {
                //
            }
        }
    };

    const EXPORT_DROPDOWN_LIST = [
        {
            label: t('allPosList'),
            onClick: () => onExport('exportPosList'),
        },
    ];

    const linkedButtonExport = ref({
        type: 'ui',
        variant: 'white',
        label: t('export'),
        icon: 'chevron-down',
    });
</script>

<style lang="scss">
    .pos-main {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__dropdown {
            display: flex;
            gap: 16px;

            > div {
                width: 100px !important;
            }
        }
    }
</style>
