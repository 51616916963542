<template>
    <section class="users-manager">
        <div class="users-manager__actions">
            <div class="users-manager__header">
                <h2 class="fsb-typo-heading-3">{{ t('manageUsers') }}</h2>
                <ui-button variant="white" left-icon="add" :label="t('create')" :on-click="openUserModal"></ui-button>
            </div>

            <search-input ref="searchInputEl" id="users-manager-search-input" full-width :placeholder="t('searchForUser')" inner-search-with-key="name" @update:searchInList="updateFilterUser" />
        </div>

        <div class="users-manager__list">
            <p class="users-manager__count fsb-typo-btn-secondary">{{ finalUsersTable.length }} / {{ totalUsers }} {{ t('users') }}</p>
            <dynamic-table
                :fields="usersFields"
                :items="finalUsersTable"
                unique-key="uid"
                with-actions-column
                :enable-checkbox="false"
                @item-clicked="openUserInfo"
                hide-actions-header
                hide-pagination
                :empty-text="t('noUser')"
                :empty-filtered-text="t('noUserFound')"
            >
                <template #actions="user">
                    <icon-button icon-type="chevron-right" variant="white" @click="() => openUserInfo(user.item.index, user.item)" />
                </template>
            </dynamic-table>
        </div>

        <add-user-modal :modal-show="showUserModal" @close-modal="closeUserModal"></add-user-modal>
        <user-details-infos :show-sidebar="userPanelShow" :user-details="userDetails" is-global-user @updateShowSidebar="userPanelShow = false" @update-user-rights="updateUserRights" />
    </section>
</template>

<script lang="ts" setup>
    import { iUser } from '@/interfaces/global';
    import { useUsersStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { computed, ref, Ref, watch, provide } from 'vue';
    import { useI18n } from 'vue-i18n';

    import AddUserModal from '../Modal/AddUser.vue';
    import UserDetailsInfos from '../UsersUi/UserDetailsInfos.vue';

    const { t } = useI18n({ useScope: 'global' });

    const usersStore = useUsersStore();

    const { usersMultiBrands, usersMultiBrandsCount: totalUsers } = storeToRefs(usersStore);

    const filterUsers: Ref<iUser[]> = ref(usersMultiBrands.value);

    const finalUsersTable = computed(() => {
        const finalResult = filterUsers.value.map((user: iUser) => {
            return {
                ...user,
                fullName: `${user.firstname} ${user.lastname}`,
            };
        });

        return finalResult.reverse();
    });

    const usersFields = [
        { key: 'fullName', label: t('name'), sortable: true },
        { key: 'email', label: t('email'), sortable: true },
    ];

    const searchInputEl = ref(null);
    let showUserModal: Ref<boolean> = ref(false);

    const openUserModal = () => {
        showUserModal.value = true;
    };

    const closeUserModal = () => {
        showUserModal.value = false;
    };

    const updateFilterUser = async ({ searchInput }: { searchInput: string }) => {
        await usersStore.getUsersMultiBrandsWithFilter(searchInput);
    };

    const userPanelShow: Ref<boolean> = ref(false);
    const userDetails: Ref<iUser> = ref({} as iUser);

    //Navigate to first configurated page
    const openUserInfo = async (index: number, user: iUser) => {
        // Navigate to user panel

        userDetails.value = user;
        await updateUserRights(user.uid);

        userPanelShow.value = !userPanelShow.value;
    };

    provide('userClicked', userDetails);

    const updateUserRights = async (uid: string) => {
        try {
            userDetails.value.rights = (await usersStore.getUserRights(uid)) || [];
        } catch (e) {
            console.log(e);
        }
    };

    watch(
        () => usersMultiBrands.value,
        (newUsersList) => {
            filterUsers.value = newUsersList;
        },
        {
            deep: true,
            immediate: true,
        }
    );
</script>

<style lang="scss">
    .users-manager {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__actions {
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: column;
            margin-bottom: 8px;

            & > * {
                width: 100%;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: 8px;

            & > * {
                width: 100%;
            }
        }

        &__count {
            text-align: right;
            text-transform: lowercase;
        }
    }
</style>
