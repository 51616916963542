<template>
    <tag-label v-bind="tagProps" class="tag-label--mandate"></tag-label>
</template>

<script lang="ts">
    export const MANDATE_TAG = (mandateStatus: number, mandateValidity: number) => {
        const currentYear = new Date().getFullYear();

        if (mandateValidity < currentYear) {
            return {
                variant: 'red',
                label: 'expired',
            };
        }

        switch (mandateStatus) {
            case 10:
            case 20:
                return {
                    customColor: '#E8F8FF',
                    label: 'pending',
                };

            case 40:
            case 50:
                return {
                    variant: 'green',
                    label: 'validated',
                };

            case 0:
            default:
                return {
                    customColor: 'var(--grayscale-gray-regular)',
                    label: 'nonCreated',
                };
        }
    };
</script>

<script setup lang="ts">
    import { computed, ComputedRef } from 'vue';

    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });

    interface Props {
        mandateStatus: number;
        validity: number;
    }

    const props = defineProps<Props>();

    const tagProps: ComputedRef<{ variant: string; label: string; customColor: string | undefined }> = computed(() => {
        const tag = MANDATE_TAG(props.mandateStatus, props.validity);
        return {
            ...tag,
            label: t(tag.label),
        };
    });
</script>
