<template>
    <div class="pos-users-list">
        <div class="pos-users-list__header">
            <h3 class="fsb-typo-heading-3">{{ t('users') }} ({{ usersCounts }})</h3>
            <a class="pos-users-list__all-users fsb-typo-para-regular" @click="() => emits('switchToUsersList')">{{ t('allUsers') }}</a>
        </div>

        <div class="pos-users-list__content" v-if="users">
            <user-card
                v-for="(user, index) in users.slice(0, MAX_USERS_TO_DISPLAY)"
                :key="`pos-users-list__user--${user.uid}`"
                :class="{ '--first': index === 0, '--last': index === MAX_USERS_TO_DISPLAY - 1 }"
                v-model:user="users[index]"
                :index="index"
                :point-code="pointCode"
                @delete-user-from-list="users.splice(index, 1)"
            ></user-card>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { iUserOfPos } from '@/interfaces/global';
    import { ComputedRef, computed, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import UserCard from './UserCard.vue';

    const MAX_USERS_TO_DISPLAY = 5;
    const { t } = useI18n({ useScope: 'global' });

    interface Props {
        usersList: iUserOfPos[] | undefined;
        pointCode: string;
    }

    const props = defineProps<Props>();

    const emits = defineEmits(['switchToUsersList']);

    const usersCounts: ComputedRef<number> = computed(() => props.usersList?.length || 0);

    const users = ref(props.usersList);

    watch(
        () => props.usersList,
        (newVal) => {
            if (newVal) {
                users.value = newVal;
            }
        }
    );
</script>

<style lang="scss">
    .pos-users-list {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 8px;
        }

        &__all-users.fsb-typo-para-regular {
            color: var(--grayscale-black);
            text-decoration: underline !important;
            cursor: pointer;
        }

        &__content {
            .user-card {
                &.--first {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                &.--last {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
                &:not(.--last):not(.--first) {
                    border-radius: 0;
                }
                &.--first.--last {
                    border-radius: 12px;
                }
            }
        }
    }
</style>
