<template>
    <div class="manage-users">
        <div class="user-main">
            <div class="user-main__header">
                <h1 class="fsb-typo-heading-1">{{ t('users') }}</h1>
                <div class="user-main__dropdown justify-content-end" style="width: 600px">
                    <fsb-dropdown
                        :linked-button="linkedButtonCreate"
                        :list="CREATE_DROPDOWN_LIST"
                        :select-one="true"
                        :close-dropdown-on-item-click="true"
                        close-dropdown-on-click-outside
                        no-select
                        :list-width="220"
                        :offset="{ right: 10 }"
                    />
                    <fsb-dropdown
                        :linked-button="linkedButtonExport"
                        :list="EXPORT_DROPDOWN_LIST"
                        :select-one="true"
                        :close-dropdown-on-item-click="true"
                        dropdown-position="left"
                        close-dropdown-on-click-outside
                        no-select
                        :list-width="220"
                        :offset="{ right: -10 }"
                    />
                </div>
            </div>

            <div class="theme-main__content">
                <users-list />
            </div>
        </div>
        <add-user-modal :modal-show="showUserModal" @close-modal="closeUserModal" :brand="brand"></add-user-modal>
    </div>
</template>

<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { ref, Ref } from 'vue';
    import UsersList from '@/components/UsersUi/UsersList.vue';
    import AddUserModal from '@/components/Modal/AddUser.vue';
    import { useUsersStore, useGlobalStore } from '@/store';
    import { storeToRefs } from 'pinia';

    const globalStore = useGlobalStore();
    const { brand }: string = storeToRefs(globalStore);
    const { t } = useI18n({ useScope: 'global' });

    const usersStore = useUsersStore();

    const onExport = async (value: string) => {
        if (value) {
            if (value === 'exportUsers') {
                await usersStore.getBrandListUsers();
            } else if (value === 'exportUsersWithPos') {
                await usersStore.getBrandListUsersWithPos();
            }
        }
    };

    let showUserModal: Ref<boolean> = ref(false);

    const openUserModal = () => {
        showUserModal.value = true;
    };

    const closeUserModal = () => {
        showUserModal.value = false;
    };
    const CREATE_DROPDOWN_LIST = [
        {
            label: 'Ajouter un utilisateur',
            onClick: openUserModal,
        },
        {
            label: 'Importer',
            onClick: () => console.log('Importer'),
        },
    ];

    const EXPORT_DROPDOWN_LIST = [
        {
            label: t('allUsers'),
            onClick: () => onExport('exportUsers'),
        },
        {
            label: t('allUsersWithPos'),
            onClick: () => onExport('exportUsersWithPos'),
        },
    ];

    const linkedButtonCreate = ref({
        type: 'ui',
        variant: 'white',
        label: t('create'),
        icon: 'chevron-down',
    });

    const linkedButtonExport = ref({
        type: 'ui',
        variant: 'white',
        label: t('export'),
        icon: 'chevron-down',
    });
</script>

<style lang="scss">
    .user-main {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__dropdown {
            display: flex;
            gap: 16px;

            > div {
                width: 100px !important;
            }
        }
    }
</style>
