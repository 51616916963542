<template>
    <base-modal
        :modal-show="modalShow"
        id="category-modal-delete"
        :title="t('delete')"
        hide-header-close
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="closeModal"
        :show-secondary-button="false"
        :primary-label="t('confirm')"
        :on-primary-button-click="deleteTheme"
        @close-modal="closeModal"
    >
        <p>{{ t('deleteConfirmationTheme') }}</p>
    </base-modal>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import { useThemeStore } from '@/store';

    interface Props {
        themeCode: string;
        modalShow: boolean;
        closeModal: () => void;
    }

    const props = defineProps<Props>();

    const { t } = useI18n({ useScope: 'global' });

    const themeStore = useThemeStore();

    const deleteTheme = async () => {
        // delete existing metacategory
        await themeStore.deleteTheme(props.themeCode);

        props.closeModal();
    };
</script>

<!-- UNSCOPED for Modal style -->
<style lang="scss">
    #theme-modal-delete {
    }
</style>
