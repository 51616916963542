<template>
    <div class="brand-search-list">
        <div>
            <search-input id="search-input" :placeholder="placeholderOfSearchInput" width="100%" @update:value="filterList" />
            <separator-gap :height="15" />
            <div class="checkbox-all">
                <checkbox-all :list="filteredList" key-name="selected" @select="() => changeAllOptionValues(true)" @unselect="() => changeAllOptionValues(false)" />
            </div>
        </div>
        <separator-gap with-border />
        <div>
            <div v-if="filteredList.length > 0">
                <base-checkbox
                    v-for="option in filteredList"
                    :key="`option-${option.index}`"
                    :name="`option-${option.index}`"
                    class="checkbox"
                    v-model:checked="option.selected"
                    :label="option.label"
                />
                <separator-gap :height="20" />
            </div>
            <nothing-found v-else :text="textItemsNotFound || t('noBrandFound')" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { formatForSearchValue } from '@/helpers/tools';
    import { iMultiSelectOption } from '@/interfaces/global';
    import { Ref, onMounted, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import SearchInput from './SearchInput.vue';

    const props = defineProps<{
        textItemsNotFound?: string;
        placeholderOfSearchInput: string;
        list: iMultiSelectOption[];
    }>();

    const emit = defineEmits(['on-item-selected']);

    const { t } = useI18n({ useScope: 'global' });

    const formattedList: Ref<iMultiSelectOption[]> = ref([]);
    const filteredList: Ref<iMultiSelectOption[]> = ref([]);

    const changeAllOptionValues = (selected: boolean) => {
        filteredList.value.forEach((option) => {
            option.selected = selected;
        });
    };

    const filterList = ({ value }: { value: string }) => {
        if (value) {
            filteredList.value = formattedList.value.filter((option) => formatForSearchValue(option.label).includes(formatForSearchValue(value)));
        } else {
            filteredList.value = formattedList.value;
        }
    };

    onMounted(() => {
        formattedList.value = props.list.map((option, index) => {
            return { ...option, index };
        });
        filteredList.value = formattedList.value.slice();
    });

    watch(
        () => formattedList.value,
        (value) => {
            const allSelectedOptions = value.filter((option) => option.selected);
            emit('on-item-selected', allSelectedOptions);
        },
        { deep: true }
    );
</script>

<style lang="scss" scoped>
    :deep(.simple-input__field) {
        height: 40px;
    }

    :deep(.fsb-separator-gap.--border) {
        border-bottom-width: 0.1px !important;
    }

    .base-modal-content {
        gap: 2px !important;
        max-height: 380px;

        .checkbox-all {
            display: flex;
            align-items: center;
            height: 32px;
            background-color: var(--grayscale-white);
            border-radius: 8px;
        }

        .checkbox {
            padding-top: 10px;
        }
    }
</style>
