<template>
    <div class="zone-container">
        <infos-title :title="t('diffusionZones')" :label-icon="t('create')" icon-name="add" :padding-bottom="false" @action-button="() => showZoneEditorOfPos(ActionType.CREATION)" />
        <div class="zone-list">
            <div class="zone-item">
                <p class="fsb-typo-para-regular">{{ t('defaultZone') }}</p>
            </div>
            <div v-for="(zone, index) in zoneList" :key="index" class="zone-item">
                <p class="fsb-typo-para-regular">{{ zone.zoneName }}</p>
                <div>
                    <icon-button icon-type="edit" size="12" @click="() => showZoneEditorOfPos(ActionType.EDITION, zone.code)" />
                    <icon-button icon-type="view" size="12" @click="() => showZoneEditorOfPos(ActionType.VISUALISATION, zone.code)" />
                </div>
                <base-toggle :name="index" :default-checked="zone.active" @toggle:change="changeStatus" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useZoneStore } from '@/store/views/zone';
    import { ActionType } from '@adcleek/front-zones-lib';
    import { storeToRefs } from 'pinia';
    import { onMounted, onUnmounted } from 'vue';
    import { useI18n } from 'vue-i18n';
    import InfosTitle from '../Sidebar/InfosTitle.vue';

    const props = defineProps<{
        pointCode: string;
        posName: string;
    }>();

    const { t } = useI18n({ useScope: 'global' });

    const zoneStore = useZoneStore();
    const { zoneList } = storeToRefs(zoneStore);

    const changeStatus = async ({ name: index, value }: { name: number; value: boolean }) => {
        await zoneStore.updateStatus(index, value);
    };

    const showZoneEditorOfPos = async (actionType: ActionType, zoneCode?: string) => {
        await zoneStore.initZoneEditor(props.pointCode, zoneCode);
        zoneStore.actionType = actionType;
        zoneStore.posName = props.posName;
        zoneStore.showZoneEditorOfPos = true;
    };

    onMounted(() => zoneStore.getZoneList(props.pointCode));

    onUnmounted(() => (zoneList.value = []));
</script>

<style scoped lang="scss">
    .zone-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .zone-list {
        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .zone-item {
            height: 50px;
            padding: 4px 8px 4px 4px;
            gap: 24px;

            div {
                gap: 8px;
            }

            p {
                width: 100%;
                padding: 8px;
            }
        }
    }
</style>
