import { axiosInstance, setHeaders } from '@/axiosInstance';
import { downloadFile } from '@/helpers/tools';
import { iRolesByService, iUser, UserPointsOfSale } from '@/interfaces/global';
import { useAuthStore, useGlobalStore } from '@/store';
import { defineStore } from 'pinia';
import { MAX_USERS_MULTI_BRANDS } from '@/utils/constants';

export const useUsersStore = defineStore('useUsersStore', {
    state: () => ({
        users: [] as iUser[],
        globalUsers: [] as iUser[],
        usersMultiBrands: [] as any,
        usersMultiBrandsCount: 0 as number,
    }),
    getters: {
        apiUsers() {
            const globalStore = useGlobalStore();
            return `/users/users-pos-by-brand/${globalStore.brand}`;
        },
        apiBrandListUsers() {
            const globalStore = useGlobalStore();
            return `/customers/brandListUsers?brand=${globalStore.brand}`;
        },
        apiBrandListUsersWithPos() {
            const globalStore = useGlobalStore();
            return `/customers/brandListUsersWithPos?brand=${globalStore.brand}`;
        },
        apiDeleteUser() {
            return (userId: string) => `/users/${userId}`;
        },
        apiPatchUser() {
            return (userId: string) => `/users/admin/update-info/${userId}`;
        },
        apiRolesByService() {
            return (services: string[]) => `/users/roles-by-service?services=${services}`;
        },
        apiUpdateRoles() {
            return '/users/roles';
        },
        apiDeleteBrandOfUser() {
            return (uid: string, brand: string) => `/users/${uid}/brands/${brand}`;
        },
        apiDeleteOnePosOfUser() {
            return (uid: string, brand: string, pos: string) => `/users/${uid}/brands/${brand}/pos/${pos}`;
        },
        apiUserRights() {
            return (uid: string) => `/users/user-rights/${uid}`;
        },
        apiBrands() {
            return '/users/brands';
        },
        apiGlobalUsers() {
            return '/users/global-users';
        },
        apiGlobalUsersLimit() {
            return `/users/global-users?limit=${MAX_USERS_MULTI_BRANDS}`;
        },
        apiTotalUsersCount() {
            return '/users/global-users?count=true';
        },
        apiUserPos() {
            return '/users/pos';
        },
        apiUserByEmail() {
            return (email: string) => `/users/user-by-email?email=${email}`;
        },
    },
    actions: {
        getUserByEMail(email: string) {
            return axiosInstance.get(this.apiUserByEmail(email));
        },
        async getUsersMultiBrands(withLimit = true) {
            const authStore = useAuthStore();
            setHeaders(axiosInstance, { Authorization: `Bearer ${authStore.getToken()}` });
            const response = (await axiosInstance.get(withLimit ? this.apiGlobalUsersLimit : this.apiGlobalUsers)).data || {};
            this.usersMultiBrandsCount = response.count || 0;
            this.usersMultiBrands = response.users || [];
        },

        async getUsersMultiBrandsWithFilter(search: string) {
            this.usersMultiBrands = (await axiosInstance.get(`${this.apiGlobalUsers}?search=${search || ''}`)).data?.users || [];
        },

        async getUsers() {
            try {
                const authStore = useAuthStore();
                setHeaders(axiosInstance, { Authorization: `Bearer ${authStore.getToken()}` });
                const users = (await axiosInstance.get(this.apiUsers)).data;
                const uniqUsersUid = new Set(users.map((user: iUser) => user.uid));

                const uniqUsers = [] as iUser[];

                uniqUsersUid.forEach((uid) => {
                    const user = users.find((user: iUser) => user.uid === uid);
                    if (user) {
                        uniqUsers.push(user);
                    }
                });

                this.users = uniqUsers.sort((a: any, b: any) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });
            } catch (error) {
                this.users = [];
                console.log(error);
            }
        },

        async getUserRights(uid: string) {
            try {
                const authStore = useAuthStore();
                setHeaders(axiosInstance, { Authorization: `Bearer ${authStore.getToken()}` });
                const userRights = (await axiosInstance.get(this.apiUserRights(uid))).data;
                const finalRights = [];
                for (const userRight of userRights) {
                    if (userRight.rights.length) {
                        finalRights.push(userRight);
                    }
                }
                return finalRights;
            } catch (error) {
                this.users = [];
                console.log(error);
            }
        },

        async init() {
            await this.getUsers();
            await useGlobalStore().getFinancialGroups();
        },

        async getRolesByService(services: string[]): Promise<iRolesByService | undefined> {
            try {
                return (await axiosInstance.get(this.apiRolesByService(services))).data;
            } catch (error) {
                console.log(error);
            }
        },

        async updateUserRoles(uid: string, brand: string, rolesToUpdate: { roleValue: string; rightId: string }[]) {
            try {
                await axiosInstance.put(this.apiUpdateRoles, { uid, brand, rolesToUpdate });
            } catch (error) {
                throw error;
            }
        },

        async deleteBrandOfUser(uid: string, brand: string) {
            try {
                await axiosInstance.delete(this.apiDeleteBrandOfUser(uid, brand));
            } catch (error) {
                throw error;
            }
        },

        async deleteOnePosOfUser(user:string, brand: string, pos: string) {
            try {
                await axiosInstance.delete(this.apiDeleteOnePosOfUser(user, brand, pos));
                this.getUsers();
            } catch (error) {
                throw error;
            }
        },

        async deleteUser(userId: string) {
            try {
                await axiosInstance.delete(this.apiDeleteUser(userId));

                const globalStore = useGlobalStore();
                if (globalStore.brand) {
                    this.getUsers();
                } else {
                    this.getUsersMultiBrands();
                }
            } catch (error) {
                console.log(error);
            }
        },

        async editUser(userId: string, data: any) {
            try {
                await axiosInstance.patch(this.apiPatchUser(userId), data);
                this.getUsers();
            } catch (error) {
                console.log(error);
            }
        },

        async getBrandListUsers() {
            try {
                const globalStore = useGlobalStore();
                const file = await axiosInstance.get(this.apiBrandListUsers, {
                    responseType: 'arraybuffer',
                });

                downloadFile(file.data, `Liste Utilisateurs ${globalStore.brand.toUpperCase()}.xlsx`);
            } catch (error) {}
        },

        async getBrandListUsersWithPos() {
            try {
                const globalStore = useGlobalStore();
                const file = await axiosInstance.get(this.apiBrandListUsersWithPos, { responseType: 'arraybuffer' });

                downloadFile(file.data, `Liste Utilisateurs avec PDV ${globalStore.brand.toUpperCase()}.xlsx`);
            } catch (error) {}
        },

        async checkEmailAvailability(email: string, brand: string | null = null) {
            try {
                let url = `/users/check-email-availability?email=${email}`;
                if (brand) url += `&brand=${brand}`;
                const validity = await axiosInstance.get(url);
                return validity;
            } catch (e) {
                throw e;
            }
        },
        async createUser(form: any) {
            try {
                return await axiosInstance.post(`/users/create-user-with-brands`, form);
            } catch (e) {
                throw e;
            }
        },
        async duplicateUser(form: any) {
            try {
                return await axiosInstance.post(`/users/duplicate-user-with-brands`, form);
            } catch (e) {
                throw e;
            }
        },
        async getBrands(): Promise<{ code: string; label: string }[]> {
            try {
                return (await axiosInstance.get(this.apiBrands)).data;
            } catch (error) {
                console.log(error);
                return [];
            }
        },

        async addBrands(uid: string, brands: string[]): Promise<{ name: string; active: number }[]> {
            try {
                return (await axiosInstance.post(this.apiBrands, { uid, brands })).data;
            } catch (error) {
                throw error;
            }
        },

        async addUserPos(posInfos: UserPointsOfSale[]) {
            try {
                return (await axiosInstance.post(this.apiUserPos, posInfos)).data;
            } catch (error) {
                throw error;
            }
        },
    },
});
