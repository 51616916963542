import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AdminHome from '@/views/adminHome.vue';
import ManageTheme from '@/views/manageTheme.vue';
import ThemeDetails from '@/views/themeDetails.vue';
import ManageCategories from '@/views/manageCategories.vue';
import ManageUsers from '@/views/manageUsers.vue';
import ManagePos from '@/views/managePos.vue';
import Login from '@/views/login/loginIndex.vue';
import { useCategoryStore, useGlobalStore, useAuthStore, useUsersStore, usePosStore } from '@/store';
import { useThemeStore } from '@/store/views/theme';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'start',
        meta: { showNavBar: false },
        component: AdminHome,
    },
    {
        path: '/home',
        name: 'AdminHome',
        meta: { showNavBar: false },
        component: AdminHome,
    },
    {
        path: '/brand/:brandCode/theme',
        name: 'ManageTheme',
        meta: { showNavBar: true },
        component: ManageTheme,
    },
    {
        path: '/brand/:brandCode/theme/:themeCode',
        name: 'ThemeDetails',
        meta: { showNavBar: true },
        component: ThemeDetails,
    },
    {
        path: '/brand/:brandCode/categories',
        name: 'ManageCategories',
        meta: { showNavBar: true },
        component: ManageCategories,
    },
    { path: '/brand/:brandCode/users', name: 'ManageUsers', meta: { showNavBar: true }, component: ManageUsers },
    { path: '/brand/:brandCode/pos', name: 'ManagePos', meta: { showNavBar: true }, component: ManagePos },
    {
        path: '/brand/:brandCode/users',
        name: 'ManageUsers',
        meta: { showNavBar: true },
        component: ManageUsers,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const manageBrandSet = (brand: string, routeName: string) => {
    if (brand) {
        const globalStore = useGlobalStore();
        const categoryStore = useCategoryStore();
        const themeStore = useThemeStore();
        const usersStore = useUsersStore();
        const posStore = usePosStore();

        try {
            globalStore.setBrand(brand as string);

            switch (routeName) {
                case 'ManageTheme':
                    themeStore.init();
                    break;

                case 'ManageCategories':
                    categoryStore.init();
                    themeStore.getThemes();
                    break;

                case 'ManageUsers':
                    usersStore.init();
                    break;

                case 'ManagePos':
                    usersStore.init();
                    posStore.init();
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log(error);
            globalStore.setBrandNotFound(true);
        }
    }
};

router.beforeEach((to, from, next) => {
    const { brandCode: brand } = to.params;
    const { name: routeName } = to;
    if (to.path === '/loading') return next('/home');
    const isIframe = window !== window.parent;

    if (to.name === 'AdminHome' || to.name === 'start') {
        const userStore = useUsersStore();
        userStore.getUsersMultiBrands();
    }

    if (process.env.NODE_ENV === 'development' && !isIframe) {
        const authStore = useAuthStore();
        const token = authStore.getToken();

        if (to.name !== 'login') {
            if (!token || !authStore.isTokenValid(14400000)) {
                return next({ name: 'login' });
            } else if (!to.name) {
                return next('/home');
            } else {
                manageBrandSet(brand as string, routeName as string);

                return next();
            }
        }
    }
    if (to.name === 'start') {
        return next({ name: 'share-token' });
    }

    manageBrandSet(brand as string, routeName as string);

    next();
});

export default router;
