<template>
    <div class="create-theme step-2">
        <div class="step-2__header">
            <search-input id="search-input-media" :placeholder="t('searchMedia')" full-width @update:value="filterMedias" />
            <separator-gap :height="16" />
            <div class="step-2__header__select-all">
                <checkbox-all
                    name="checkbox-all-media"
                    :list="filteredMedias"
                    @select="
                        () => {
                            changeAllOptionValues(true);
                            onUpdateValue('checkbox-medias');
                        }
                    "
                    @unselect="
                        () => {
                            changeAllOptionValues(false);
                            onUpdateValue('checkbox-medias');
                        }
                    "
                />
                <span class="media-count">{{ t('selectedMediasCount', { count: selectedMedias.length }) }} </span>
            </div>
        </div>
        <separator-gap with-border />
        <div class="step-2__content">
            <div v-if="filteredMedias.length > 0">
                <base-checkbox
                    v-for="(opt, index) in filteredMedias"
                    :key="`fsb-group-checkboxed-media-opt-${index}`"
                    :name="`fsb-group-checkboxed-media-opt-${index}`"
                    v-model:checked="opt.checked"
                    :label="opt.text"
                    :disabled="opt.disabled"
                    @update:checked="() => onUpdateValue('checkbox-medias')"
                />
            </div>
            <nothing-found v-else :text="t('noMediaFound')" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { checkDataValid, checkIsValidForEntry, formatDataValue } from '@/helpers/dataValidation';
    import { formatForSearchValue } from '@/helpers/tools';
    import { iDataValidation, iGenericOption, iMedia } from '@/interfaces/global';
    import { useThemeStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { computed, ComputedRef, onMounted, onUpdated, Ref, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps({
        themeDetails: {
            type: Object,
            default: null,
        },
    });

    const emit = defineEmits(['data-validation', 'update:value']);

    const { t } = useI18n({ useScope: 'global' });

    const themeStore = useThemeStore();
    const { medias } = storeToRefs(themeStore);

    const formattedMedias: Ref<iGenericOption[]> = ref(
        medias.value.map((m: iMedia) => ({
            value: m.media_support_code,
            text: m.name,
            checked: props.themeDetails ? props.themeDetails.media_support.includes(m.media_support_code) : false,
        }))
    );

    const filteredMedias: Ref<iGenericOption[]> = ref(
        medias.value.map((m: iMedia) => ({
            value: m.media_support_code,
            text: m.name,
            checked: props.themeDetails ? props.themeDetails.media_support.includes(m.media_support_code) : false,
        }))
    );

    const dataValidation: Ref<iDataValidation[]> = ref([
        {
            id: 'checkbox-medias',
            isValid: true,
            required: false,
            keyDataValue: 'media_support',
            defaultValue: props.themeDetails ? props.themeDetails.media_support : null,
        },
    ]);

    const selectedMedias = computed(() => filteredMedias.value.filter((media) => media.checked));

    const isDataValid: ComputedRef<boolean> = computed(() => checkDataValid(dataValidation.value));

    const onUpdateValue = (id: string) => {
        const index = dataValidation.value.findIndex((d: iDataValidation) => d.id === id);
        dataValidation.value[index].isValid = checkIsValidForEntry(dataValidation.value[index]);
        emit('update:value', {
            value: {
                ...formatDataValue(
                    dataValidation.value[index],
                    selectedMedias.value.map((o: any) => o.value)
                ),
            },
            id,
        });
    };

    watch(isDataValid, (value: boolean) => emit('data-validation', { isDataValid: value }));

    const filterMedias = ({ value }: { value: string }) => {
        if (value) {
            filteredMedias.value = formattedMedias.value.filter((option) => formatForSearchValue(option.text).includes(formatForSearchValue(value)));
        } else {
            filteredMedias.value = formattedMedias.value;
        }
    };

    const changeAllOptionValues = (checked: boolean) => {
        filteredMedias.value.forEach((option) => {
            option.checked = checked;
        });

        checkDataValid(dataValidation.value);
    };

    onMounted(() => {
        checkDataValid(dataValidation.value);

        if (props.themeDetails) {
            dataValidation.value.forEach((entry: iDataValidation) => {
                emit('update:value', { value: { ...formatDataValue(entry, entry.defaultValue) }, id: entry.id });
            });
        }
    });

    watch(
        () => medias.value,
        (newMedias) => {
            formattedMedias.value = newMedias.map((m: iMedia) => ({
                value: m.media_support_code,
                text: m.name ?? m.media_support_code,
                checked: props.themeDetails ? props.themeDetails.media_support.includes(m.media_support_code) : false,
            }));

            filteredMedias.value = newMedias.map((m: iMedia) => {
                return {
                    value: m.media_support_code,
                    text: newMedias.find((media) => media.media_support_code === m.media_support_code)?.name,
                    checked: props.themeDetails?.media_support?.includes(m.media_support_code),
                };
            });
        },
        { immediate: true }
    );
</script>

<style lang="scss">
    .step-2 {
        overflow: hidden !important;
        display: flex;
        flex-direction: column;

        &__header {
            &__select-all {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;
            }
        }

        &__content {
            overflow-y: auto;
            overflow-x: hidden;
            height: 1000000;
        }

        .--unchecked {
            &::before {
                background-color: none !important;
            }
        }
    }
</style>
