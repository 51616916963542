import { axiosInstance } from '@/axiosInstance';
import { iFinancialGroup } from '@/interfaces/global';
import { defineStore } from 'pinia';
import { useAuthStore } from '.';

export const useGlobalStore = defineStore('useGlobalStore', {
    state: () => ({
        medias: {} as any,
        brand: '' as string,
        isBrandNotFound: true as boolean,
        financialGroups: [] as iFinancialGroup[],
    }),
    getters: {
        brands() {
            const authStore = useAuthStore();

            const user = typeof authStore.user === 'string' ? JSON.parse(authStore.user) : authStore.user;
            const brands = user && user.brands ? Object.keys(user.brands) : [];

            const index = brands.indexOf('global');
            brands.splice(index, 1);

            return brands;
        },
        apiFinGroups() {
            return (brand: string) => `/customers/${brand}/financialGroups`;
        },
    },
    actions: {
        setBrand(brand: string) {
            this.brand = brand;
        },
        setBrandNotFound(isBrandNotFound: boolean) {
            this.isBrandNotFound = isBrandNotFound;
        },
        async getMedias(brand: string) {
            const mediaUrl = `/crea/admin/medias?brand=${brand}`;
            const response = await axiosInstance.get(mediaUrl);
            this.medias = response.data;
        },

        async getFinancialGroups(brand?: string) {
            try {
                brand = brand || this.brand;
                const financialGroups = (await axiosInstance.get(this.apiFinGroups(brand))).data;
                if (financialGroups) {
                    this.financialGroups = financialGroups;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
});
