<template>
    <div>
        <sidebar :show-sidebar="showSidebar" @updateShowSidebar="hideSidebar">
            <template v-slot:body>
                <h1 class="fsb-typo-heading-1 pageTitle">{{ t('addPosTitle') }}</h1>
                <div v-if="allPos.length">
                    <checkboxes-list
                        ref="checkboxesListComponent"
                        v-if="!showRoleBloc"
                        title=""
                        id="pointofsale"
                        :search-input-placeholder="t('codeOrPosCode')"
                        :search-keys="['point_code', 'name']"
                        :items="allPos"
                        :items-to-filter="allPos"
                        unique-key="unique_point_code"
                        :confirm-label="t('next')"
                        :selected-label="t('posSelected')"
                        :confirm-selection-fn="() => (showRoleBloc = true)"
                        :cancel-selection-fn="() => emit('update:showSidebar', false)"
                        :show-separator="false"
                        @onUpdateSelectedItems="updatePosSelected"
                        :nothing-found-label="t('noPosFound')"
                    >
                        <template #default="{ item }">
                            <div class="checkbox-card__content pos-bloc">
                                <p class="fsb-typo-btn-principal text-color-gray">{{ item.point_code }}</p>
                                <p class="fsb-typo-btn-principal text-color-black">{{ item.name }}</p>
                                <tag-label class="tag-label" v-if="!item.active" :label="t(formatActiveValue(item.active))" custom-color="#707070" />
                            </div>
                        </template>
                    </checkboxes-list>
                    <div v-if="showRoleBloc" class="managementRoleBloc">
                        {{ t('manageRole') }}

                        <ui-button :label="t('confirm')" @click="addUserPos()"></ui-button>
                    </div>
                </div>
                <div v-else class="spinnerBloc">
                    <b-spinner></b-spinner>
                </div>
            </template>
        </sidebar>
    </div>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import CheckboxesList from '../SharedUi/CheckboxesList.vue';
    import { iPointofinterest, UserPointsOfSale, PointsOfSale } from '@/interfaces/global';
    import { computed, ref, Ref, inject } from 'vue';
    import { iUser } from '@/interfaces/global';
    import { useGlobalStore, useUsersStore } from '@/store';

    const globalStore = useGlobalStore();
    const usersStore = useUsersStore();

    const { t } = useI18n({ useScope: 'global' });

    const props = defineProps<{
        showSidebar: boolean;
        brand: string;
        pointofsale: iPointofinterest[];
    }>();

    const emit = defineEmits(['update:showSidebar', 'closeSidebar']);

    const activeValue: { [key: number]: string } = { 0: 'inactive', 1: 'active' };
    const formatActiveValue = (value: number) => {
        return activeValue[value] ?? 'undefined';
    };

    const allPos = computed(() =>
        props.pointofsale.map((pos: iPointofinterest) => {
            return {
                unique_point_code: pos.unique_point_code,
                point_code: pos.details.point_code,
                name: pos.details.name,
                value: pos.details.financial_group,
                active: pos.active,
            };
        })
    );

    const showRoleBloc = ref(false);
    const checkboxesListComponent = ref(null);
    const hideSidebar = () => {
        emit('update:showSidebar', false);
        showRoleBloc.value = false;
        checkboxesListComponent.value?.cancelSelection();
    };

    const posSelected = ref([] as PointsOfSale[]);
    const updatePosSelected = ({ selectedItems }: PointsOfSale[]) => {
        posSelected.value = selectedItems;
    };

    const currentUser = inject('userClicked') as Ref<iUser>;
    const addUserPos = async () => {
        try {
            const allUserPointsOfSale = [] as UserPointsOfSale[];
            posSelected.value.forEach((pos: PointsOfSale) => {
                const userPointsOfSale = {
                    user_uid: currentUser.value.uid,
                    role: 1,
                    point_code: pos.point_code,
                    brand: globalStore.brand,
                    unique_point_code: pos.unique_point_code,
                };
                allUserPointsOfSale.push(userPointsOfSale);
            });

            await usersStore.addUserPos(allUserPointsOfSale);
            await usersStore.getUsers();
            emit('closeSidebar');
        } catch (error) {
            console.log(error);
        }
    };
</script>

<style lang="scss">
    .pos-bloc {
        display: flex;
        gap: 10px;
        align-items: center;

        .tag-label {
            color: white;
        }
        .text-color-black {
            color: var(--grayscale-black) !important;
        }
        .text-color-gray {
            color: var(--grayscale-gray-dark) !important;
        }
    }
    .pageTitle {
        margin-bottom: -30px;
    }
    .spinnerBloc {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        > .spinner-border {
            width: 3rem;
            height: 3rem;
        }
    }
    .managementRoleBloc {
        display: grid;
        place-items: center;
        height: 500px;
    }
</style>
