<template>
    <div class="users-list">
        <div class="users-list__header">
            <div class="users-list__header__first">
                <search-input
                    id="users-search-input"
                    :placeholder="t('searchUser')"
                    width="2px"
                    :search-in-list="[
                        { list: users, key: 'firstname' },
                        { list: users, key: 'lastname' },
                        { list: users, key: 'email' },
                    ]"
                    @update:searchInList="updateUsersTable"
                />
                <!-- <fin-groups-filter
                    :items-to-filter="users.map((u) => ({ ...u, value: u.pointofinterest.map((point) => point.details.financial_group) }))"
                    @update:financialGroup="updateSelectedGroups"
                /> -->
            </div>
            <div class="users-list__header__second">
                <!-- <switch-btn @switcher-value="filterUsersSwitch" />-->
                <fsb-dropdown class="linesFilter" :linkedInput="tableLinesLabel[0].label" :list="tableLinesLabel" :select-one="true" :close-dropdown-on-item-click="true" v-model="tableRowsPerPage" />
            </div>
        </div>
        <dynamic-table
            :fields="usersFields"
            :items="finalUsersTable"
            :per-page="tableRowsPerPage[0].value"
            unique-key="uid"
            @item-clicked="showUserDetails"
            with-actions-column
            :empty-text="t('noUser')"
            :empty-filtered-text="t('noUserFound')"
        >
            <template #actions="item">
                <icon-button icon-type="edit" variant="secondary" @click="() => openUserEditModal(item)" />
                <fsb-dropdown
                    class="users-list__dropdown-more"
                    :linked-button="DROPDOWN_MORE_BUTTON"
                    :list="generateDropdownMoreList(item)"
                    :select-one="true"
                    close-dropdown-on-item-click
                    close-dropdown-on-click-outside
                    :offset="{ left: -64, top: 0 }"
                    no-select
                />
            </template>
        </dynamic-table>

        <user-modal-delete :modal-show="modalShowDelete" :user="userToRemove" :action="eUserAction.REMOVE_BRAND" :close-modal="closeUserDeleteModal" />
        <user-modal-edit :modal-show="modalShowEdit" :user="userToEdit" :close-modal="closeUserModalEdit" />
        <user-modal-duplicate :modal-show="modalShowDuplicate" :user-details="userDetails" @close-modal="closeUserModalDuplicate" />

        <user-details-infos :show-sidebar="openSidebar" :user-details="userDetails" @updateShowSidebar="openSidebar = false" />
    </div>
</template>

<script lang="ts" setup>
    import { useUsersStore } from '@/store';
    import { storeToRefs } from 'pinia';
    import { computed, ref, watchEffect, inject, watch, Ref, provide } from 'vue';
    import { useI18n } from 'vue-i18n';
    import dayjs from 'dayjs';
    import { iUser } from '@/interfaces/global';
    import { isEqual } from 'lodash';

    import SwitchBtn from '../SwitchBtn.vue';
    import UserModalDelete from './UserModalDelete.vue';
    import UserModalDuplicate from './UserModalDuplicate.vue';
    import UserModalEdit from './UserModalEdit.vue';
    import UserDetailsInfos from './UserDetailsInfos.vue';
    import { eUserAction } from '@/interfaces/global';

    const { t } = useI18n({ useScope: 'global' });

    const usersStore = useUsersStore();
    let { users } = storeToRefs(usersStore);

    const useModalDelete = inject('useModal') as any;
    const { modalShow: modalShowDelete, openModal: openModalDelete, closeModal: closeModalDelete } = useModalDelete();

    const useModalDuplicate = inject('useModal') as any;
    const { modalShow: modalShowDuplicate, openModal: openModalDuplicate, closeModal: closeModalDuplicate } = useModalDuplicate();

    const userToRemove = ref<iUser | null>(null);

    const useModalEdit = inject('useModal') as any;
    const { modalShow: modalShowEdit, openModal: openModalEdit, closeModal: closeModalEdit } = useModalEdit();
    const userToEdit = ref<iUser | null>(null);

    const DROPDOWN_MORE_LIST = [
        {
            label: t('duplicateUser'),
            value: 'duplicateUser',
        },
        {
            label: t('deleteUser'),
            value: 'deleteUser',
        },
    ];

    const DROPDOWN_MORE_BUTTON = ref({
        type: 'icon',
        icon: 'dots',
        variant: 'secondary',
    });

    const filterUsers = ref(users.value);
    const usersFields = [
        { key: 'fullName', label: t('name'), sortable: true },
        { key: 'email', label: t('email'), sortable: true },
        { key: 'pdv', label: t('pdv', 2), sortable: true },
        { key: 'date', label: t('createDate'), sortable: true },
        // { key: 'status', label: t('status'), sortable: true },
    ];

    const updateUserData = () => {
        users.value.forEach((user: iUser) => {
            user.fullName = user.firstname + ' ' + user.lastname;
            user.pdv = user.pdvCount;
            user.status = user.active === 1 ? t('active') : t('inactive');
            user.date = dayjs(user.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY');
        });
    };

    watchEffect(() => {
        updateUserData();
    });

    const openSidebar: Ref<boolean> = ref(false);
    const userDetails = ref({});
    const showUserDetails = (index: number, user: iUser) => {
        openSidebar.value = !openSidebar.value;
        userDetails.value = user;
    };

    provide('userClicked', userDetails);

    const tableLinesLabel = ref([
        { label: '25 ' + t('lines'), value: 25 },
        { label: '50 ' + t('lines'), value: 50 },
        { label: '75 ' + t('lines'), value: 75 },
        { label: '100 ' + t('lines'), value: 100 },
    ]);
    const tableRowsPerPage = ref([tableLinesLabel.value[0]]);

    const updateUsersTable = ({ searchResult, searchInput }: { searchResult: any[]; searchInput: [] }) => {
        filterUsers.value = !searchInput ? users.value : [...new Set(searchResult.flat())];
    };

    const ACTIVE_FILTER: { [key: string]: number } = { inactive: 99, active: 1, all: -1 };
    const activeFilter = ref(ACTIVE_FILTER.all);

    const filterUsersSwitch = (status: string) => {
        activeFilter.value = ACTIVE_FILTER[status];
    };

    const finalUsersTable = computed(() => {
        let finalResult = [];

        finalResult = filterUsers.value;

        return activeFilter.value !== -1 ? finalResult.filter((user) => user.active === activeFilter.value) : finalResult;
    });

    const openUserDeleteModal = (user: iUser) => {
        userToRemove.value = user;
        openModalDelete();
    };

    const openUserDuplicateModal = (user: iUser) => {
        userDetails.value = user;
        openModalDuplicate();
    };

    const closeUserDeleteModal = () => {
        userToRemove.value = null;
        closeModalDelete();
    };

    const onMoreAction = (dropdownItem: any, item: any) => {
        const { item: user } = item;

        if (dropdownItem && dropdownItem.value === 'deleteUser') {
            openUserDeleteModal(user);
        } else if (dropdownItem && dropdownItem.value === 'duplicateUser') {
            openUserDuplicateModal(user);
        }
    };

    const openUserEditModal = (item: any) => {
        const { item: user } = item;

        if (user) {
            userToEdit.value = user;
            openModalEdit();
        }
    };

    const closeUserModalEdit = () => {
        userToEdit.value = null;
        closeModalEdit();
    };

    const closeUserModalDuplicate = () => {
        closeModalDuplicate();
    };

    const generateDropdownMoreList = (selectedItem: any) => {
        return DROPDOWN_MORE_LIST.map((item) => ({ ...item, onClick: () => onMoreAction(item, selectedItem) }));
    };

    watch(
        () => users.value,
        (newUsersList, oldUsersList) => {
            if (isEqual(newUsersList, oldUsersList)) return;
            filterUsers.value = newUsersList;
        }
    );
</script>

<style lang="scss">
    .fsb-dropdown {
        width: auto !important;
    }

    .fsb-dropdown__line {
        white-space: nowrap;
        width: auto !important;
    }

    .users-list__dropdown-more {
        .fsb-dropdown {
            transform: translateX(-50%) !important;
        }
    }

    .users-list {
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            &__first,
            &__second {
                display: flex;
                gap: 16px;
            }
        }

        .linesFilter {
            width: 110px !important;
            > div {
                width: 110px !important;
                cursor: pointer;
                &.fsb-dropdown {
                    &__trigger {
                        overflow: hidden !important;
                    }
                    &__trigger-node {
                        padding: 13px 10px !important;
                        width: 110px !important;
                    }
                }
            }
        }

        .fsb-dynamic-table {
            .position-relative:has(.fsb-dropdown) {
                width: auto !important;
            }
        }

        &__dropdown-more {
            .--right {
                transform: translate(40px, 90px) !important;
            }
        }
    }
</style>
