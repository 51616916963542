<template>
    <div class="list-item">
        <div>
            <p class="fsb-typo-para-regular text-color-black">{{ truncateText(mainText) }}</p>
            <p class="fsb-typo-para-small text-color-gray">{{ truncateText(secondaryText) }}</p>
        </div>

        <div class="list-item-2bloc">
            <tag-label :label="roleName" :custom-color="roleColor" />

            <div class="icon-bloc">
                <div v-if="enableIconGroupMode" class="group-icons">
                    <icon-button :icon-type="btnIcon" size="12" @click="emit('btnAction')" />
                    <icon-button v-if="secondBtnIcon" :icon-type="secondBtnIcon" size="12" @click="emit('secondBtnAction')" />
                </div>

                <ad-icon v-else :icon="btnIcon" size="x-large" variant="disabled" style="cursor: pointer" @click="emit('btnAction')" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ROLE_LIST } from '@/utils/constants';
    import { computed, ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });

    const props = defineProps<{
        mainText: string;
        secondaryText: string;
        role?: number;
        enableIconGroupMode: boolean;
        btnIcon: string;
        secondBtnIcon?: string;
    }>();

    const emit = defineEmits(['btnAction', 'secondBtnAction']);

    const roleColor = ref('#DBE2F5');

    const roleName = computed(() => formatUserRole(props.role || 1));

    const formatUserRole = (roleNumber: number) => {
        const role = ROLE_LIST.find((role) => role.value === roleNumber) || ROLE_LIST[0];
        roleColor.value = role.color;
        return t(role.label);
    };

    const truncateText = (text: string) => {
        const maxLength = props.btnIcon && props.secondBtnIcon ? 34 : 42;
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
</script>

<style lang="scss" scoped>
    .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text-color-black {
            color: var(--grayscale-black) !important;
        }
        .text-color-gray {
            color: var(--grayscale-gray-dark) !important;
        }
    }

    .list-item-2bloc {
        display: flex;
        align-items: center;

        .fsb-tag-label {
            margin-right: 24px;
        }

        .icon-bloc {
            .group-icons {
                display: flex;
                gap: 10px;
                margin-left: 10px;
            }
        }
    }
</style>
