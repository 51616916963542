// decodedToken

export type iCreaDetails = {
    details?: iCreaDetails;
    banner_fields?: string;
    crea_code?: string;
    display_order?: number;
    end_date?: string;
    start_date?: string;
    id?: number;
    label?: string;
    media_support?: string[];
    status?: number;
    timestamp?: string;
    type?: number;
    url_banner?: string;
    banners?: string[];
    url_banner_code?: string;
    url_picture?: string;
    validity_end_date?: string;
    validity_start_date?: string;
    models?: string[];
    operation_code?: string[];
    trade?: string[];
    groups?: string[];
    thumbnail_image?: string;
    categoriesToRemove?: string[];
    categoriesToAdd?: string[];
    categories?: string[];
};

export interface iCrea {
    crea_category_code: string;
    crea_code: string;
    crea_to_category_code: string;
    details?: iCreaDetails;
    name?: string;
    id: number;
}

export interface iCreaCategory {
    crea_category_code: string;
    crea_code: string;
    crea_to_category_code: string;
    name?: string;
    id: number;
}

export interface iMetaCategoryDetails {
    crea_metacategory_code: string;
    display_order: number;
    id: number;
    name: string;
    status: number;
    timestamp: string;
    creacategories?: iCategory[];
}
export interface iMetaCategory {
    crea_category_code: string;
    crea_metacategory_code: string;
    details: iMetaCategoryDetails;
    id: number;
}

export interface iCategory {
    crea_category_code: string;
    creas?: iCrea[];
    display_order?: number;
    metas?: iMetaCategory[];
    name: string;
    status?: number;
    timestamp?: string;
    creacategory_to_metacategory_code?: string;
}

export interface iBanner {
    id: number;
    brandId: number;
    patternId: number;
    code: string;
    childBanner: any[];
    updatedAt: string;
    createdAt: string;
    name: string;
    path: string;
}

export interface iMedia {
    media_support_code: string;
    media_category_code?: string;
    name: string;
}

export interface iActivation {
    activator_code: string;
    label: string;
    operations?: {
        code: string;
        name: string;
    }[];
}

export interface iModel {
    details?: any;
    model_code: string;
    name: string;
    timestamp: string;
}

export interface iMultiSelectOption {
    label: string | undefined;
    value: any;
    selected: boolean;
    [key: string]: any | any[];
}

export interface iGenericOption {
    value: string | string[];
    [key: string]: any | any[];
}

export interface iDataValidation {
    id: string | number;
    isValid: boolean;
    required?: boolean;
    keyDataValue?: string;
    formatValueAsArray?: boolean;
    defaultValue?: any;
}

export interface iThemeLink {
    name: string;
    label: string;
}

export interface iPointofinterest {
    id: number;
    timestamp: string;
    status: number;
    point_code: string;
    country_code: string;
    geometry_hash: string;
    towns_geometry_hash: string;
    medias_geometry_hash: string;
    details: {
        point_code: string;
        name: string;
        address: string;
        zipcode: string;
        city: string;
        financial_group: string;
        latitude: number;
        longitude: number;
        population: number;
    };
    role: number;
    unique_point_code: string;
    active?: number;
}

export interface iUser {
    id: number;
    uid: string;
    email: string;
    firstname: string;
    lastname: string;
    fullName?: string;
    pdv?: number;
    status?: string;
    role?: number;
    date?: string;
    address: string;
    phone: string;
    active: number;
    created_at: string;
    updated_at: string;
    entity_code: string;
    entity: {
        id: number;
        name: string;
        type: number;
        code: string;
        details: {
            brand_name: string;
        };
    };
    pointofinterest: iPointofinterest[];
    rights?: iRights[];
}

export interface iRights {
    brand: string;
    rights: {
        service: string;
        role: string;
    }[];
}

export interface iFinancialGroup {
    code: string;
    name: string;
    type?: number;
    count?: number;
}

export interface iPosDetails {
    point_code: string;
    name: string;
    address: string;
    zipcode: string;
    city: string;
    financial_group: string;
    latitude: number;
    longitude: number;
    population: number;
    region?: string;
    district?: string;
}

export interface iPos {
    point_code: string;
    brand: string;
    users_number: number;
    active: number;
    subscribed: number;
    price: number;
    details: iPosDetails;
    users: iUserOfPos[];
    posInfo?: {
        id: number;
        point_code: string;
        brand: string;
        inc: number;
        customer_id: string;
        details: {
            email: string;
            name: string;
            point_text: string;
            company_name: string;
            address: string;
            subscription: {
                amount: number;
                status: string;
            };
            accountingDept: {
                email: string;
            };
        };
    };
    invoiceInfo?: {
        sepa_debit: {
            bank_code: string;
            branch_code: string;
            country: string;
            fingerprint: string;
            last4: string;
            mandate_reference: string;
            mandate_url: string;
        };
        mandate: any;
    };
    adMandate?: {
        id: number;
        point_code: string;
        brand: string;
        status: number;
        validity: number;
        details: {
            my_manda_informations: {
                siret: string;
            };
        };
        createdAt: string;
        updatedAt: string;
    }[];
    replication?: {
        address: string;
        name: string;
    };
}

export interface iUserOfPos {
    uid: string;
    role: number;
    firstName: string;
    lastName: string;
    email: string;
    active: number;
    code: string;
}

export interface iSelectedGroup {
    label: string;
    value: string;
}

export interface iRole {
    value: number;
    color: string;
    label: string;
}

export interface iRolesByService {
    [service: string]: {
        rightId: string;
        roles: {
            value: string;
            role: string;
        }[];
    };
}

export interface iOption {
    value: string;
    [key: string]: any | any[];
}

export enum eUserAction {
    REMOVE_BRAND,
    DELETE,
}

export type Zone = {
    code: string;
    zoneName: string;
    replicationName: string;
    replicationAddress: string;
    brand: string;
    pointCode: string;
    active: boolean;
    geometryHash: string;
};

export type UserPointsOfSale = {
    user_uid: string;
    role: number;
    point_code: string;
    unique_point_code: string;
    brand: string;
};

export type PointsOfSale = {
    active: number;
    checked: boolean;
    point_code: string;
    unique_point_code: string;
    name: string;
    value: string;
};

export type Trade = {
    trade_code: string;
    label: string;
    display_order: string;
    created_at: string;
    updated_at: string;
};

export type TradesModels = {
    [key: string]: string[];
};

export type BrandGroups = {
    code: string;
    name: string;
    type: number;
    created_at: string;
    updated_at: string;
};
