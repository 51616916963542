<template>
    <base-modal
        :modal-show="showModal"
        :title="t('delete')"
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="() => emit('update:showModal', false)"
        :show-secondary-button="false"
        :primary-label="t('confirm')"
        :on-primary-button-click="() => emit('delete')"
        @close-modal="() => emit('update:showModal', false)"
    >
        <slot></slot>
    </base-modal>
</template>

<script setup lang="ts">
    import { useI18n } from 'vue-i18n';

    defineProps<{
        showModal: boolean;
    }>();

    const emit = defineEmits(['update:showModal', 'delete']);

    const { t } = useI18n({ useScope: 'global' });
</script>

<style lang="scss" scoped>
    :slotted(p) {
        line-height: 18px;
    }
</style>
