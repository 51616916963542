<template>
    <base-modal
        :modal-show="modalShow"
        id="add-user"
        :title="t('addUser')"
        hide-header-close
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="onCloseModal"
        :show-secondary-button="false"
        :primary-label="primaryLabel"
        :on-primary-button-click="nextAction"
        :is-primary-button-disabled="isStepDisabled"
        @close-modal="onCloseModal"
    >
        <form-schema v-if="step === 0" :title="t('editUserInstruction')" :schemas="schemas" @on-form-validate="onFormValidate" @on-update-form="onUpdateForm"></form-schema>
        <generic-search-list v-else-if="step === 1" :placeholder-of-search-input="t('findingBrand')" :list="brandList" @on-item-selected="updateSelectedBrand"></generic-search-list>
    </base-modal>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import { Ref, ref, computed } from 'vue';
    import { eFormType, eFormState } from '@adcleek/front-storybook/fsb-interfaces';
    import { useUsersStore } from '@/store';
    import { inject } from 'vue';
    import GenericSearchList from '@/components/SharedUi/GenericSearchList';
    import { watch } from 'vue';
    import { useGlobalStore } from '@/store';

    const emit = defineEmits(['close-modal']);

    const snack: any = inject('snackBar');
    const globalStore = useGlobalStore();
    const userStore = useUsersStore();
    interface iProps {
        modalShow: boolean;
        brand?: string;
    }

    const { t } = useI18n({ useScope: 'global' });

    interface iUserForm {
        firstname: string;
        lastname: string;
        email: string;
    }

    let form: iUserForm = {
        firstname: '',
        lastname: '',
        email: '',
    };
    const props = defineProps<iProps>();

    /****************************** USER ******************************/

    let formValidity: Ref<eFormState> = ref([]);

    const onFormValidate = (val: eFormState) => {
        formValidity.value = val;
    };

    const onUpdateForm = (updatedForm: iUserForm) => {
        Object.assign(form, updatedForm);
    };

    const schemas = [
        {
            type: eFormType.input,
            label: t('firstName'),
            formKey: 'firstname',
            required: true,
            order: 1,
            details: {
                placeholder: t('firstName'),
            },
        },
        {
            type: eFormType.input,
            label: t('name'),
            formKey: 'lastname',
            required: true,
            order: 1,
            details: {
                placeholder: t('lastname'),
            },
        },
        {
            type: eFormType.email,
            label: t('email'),
            formKey: 'email',
            required: true,
            order: 2,
            details: {
                placeholder: t('email'),
            },
            delay: 1000,
            async validate(value: string) {
                let msgError = 'emailNotAvailable';
                try {
                    const validity = await userStore.checkEmailAvailability(value, props.brand);
                    if (!validity.data) throw new Error(msgError);
                    return null;
                } catch (e) {
                    return new Error(msgError);
                }
            },
        },
    ];

    /****************************** BRAND ******************************/

    let selectedBrands: Ref<string[]> = ref(props.brand ? [props.brand] : []);
    let brandList: Ref<{ value: string; label: string; selected: boolean }[]> = ref([]);
    const getBrands = async () => {
        const brands = await userStore.getBrands();
        brandList.value = brands.map((brand) => {
            return { value: brand.code, label: brand.label, selected: false };
        });
    };

    const updateSelectedBrand = (val: any) => {
        if (val.length === 0) {
            selectedBrands.value.length = 0;
        } else {
            selectedBrands.value = val.map((brand: any) => brand.value);
        }
    };

    /****************************** MODAL ******************************/

    let step: Ref<number> = ref(0);

    // let isStepDisabled: Ref<boolean> = ref(true);

    // watch([step, formValidity, selectedBrands], ([nStep, nFormValidity, nSelectedBrands]) => {
    //     let value: any = 0;
    //     if(nStep === 0) value =  nFormValidity !== eFormState.valid;
    //     else value = nSelectedBrands.length === 0;
    //     isStepDisabled.value = value;
    // })

    const isStepDisabled = computed(() => {
        let value: any = 0;
        if (step.value === 0) value = formValidity.value !== eFormState.valid;
        else value = selectedBrands.value.length === 0;
        return value;
    });

    const primaryLabel = computed(() => {
        if (props.brand || step.value === 1) {
            return t('create');
        }
        return t('next');
    });

    const nextAction = () => {
        if (props.brand) createUser();
        else if (step.value === 0) step.value++;
        else createUser();
    };

    const createUser = async () => {
        try {
            // check if user alreay exist but didn't have the brand
            let user = null;
            if (props.brand) {
                user = (await userStore.getUserByEMail(form.email)).data;
                if (user.length) {
                    await userStore.addBrands(user[0].uid, [props.brand]);
                }
            }
            if (user === null || user.length === 0) {
                let payload = { ...form, brands: selectedBrands.value };
                await userStore.createUser(payload);
            }
            snack.open('success', t('userSuccessfullyCreated', { email: form.email, brands: selectedBrands.value.join(', ') }));
            if (props.brand) {
                userStore.getUsers();
            } else {
                userStore.getUsersMultiBrands();
            }
            onCloseModal();
        } catch (e) {
            console.error(e);
            snack.open('alert', t('userErrorCreated'));
        }
    };

    const resetModal = () => {
        form.email = '';
        form.lastname = '';
        form.firstname = '';
        formValidity.value = schemas.every((schema) => {
            schema.required === false;
        });
        step.value = 0;
    };

    const onCloseModal = () => {
        emit('close-modal');
        resetModal();
    };

    watch(
        () => props.modalShow,
        (val) => {
            if (val) getBrands();
            userStore.getUsersMultiBrands();
        }
    );
</script>

<style lang="scss"></style>
