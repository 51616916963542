<template>
    <div class="list-item">
        <div>
            <p class="fsb-typo-para-medium" style="margin-bottom: 1px">{{ mainText }}</p>
            <p class="fsb-typo-heading-6">{{ secondaryText }}</p>
        </div>
        <div class="list-item-bloc">
            <fsb-dropdown v-model="selectedRole" :linked-input="true" :list="roleList" close-dropdown-on-item-click close-dropdown-on-click-outside select-one :full-width="true" />
            <div style="width: 16px"></div>
            <ui-button :label="t('cancel')" variant="secondary" @click="emit('cancel')" />
            <div style="width: 8px"></div>
            <ui-button :label="t('save')" variant="primary" @click="emit('updateRole', selectedRole[0]?.value)" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ROLE_LIST } from '@/utils/constants';
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        mainText: string;
        secondaryText: string;
        role: number;
    }>();

    const emit = defineEmits(['cancel', 'updateRole']);

    const { t } = useI18n({ useScope: 'global' });

    const roleList = computed(() => ROLE_LIST.map((role) => ({ ...role, label: t(role.label) })));
    const selectedRole = computed(() => [roleList.value.find((role) => role.value === props.role)]);
</script>

<style lang="scss" scoped>
    .list-item {
        display: flex;
        flex-direction: column;
    }

    .list-item-bloc {
        margin-top: 3px;
        display: flex;
        justify-content: space-between;
    }
</style>
