<template>
    <base-modal
        :modal-show="modalShow"
        id="user-modal-edit"
        :title="t('edit')"
        hide-header-close
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="closeModal"
        :show-secondary-button="false"
        :primary-label="t('save')"
        :is-primary-button-disabled="modalDisabled"
        :on-primary-button-click="editUser"
        @close-modal="closeModal"
    >
        <b-form>
            <p class="fsb-typo-para-medium user-modal-edit__instruction">{{ t('editUserInstruction') }}</p>
            <div class="user-modal-edit__block--2">
                <simple-input
                    id="firstname"
                    :invalid="firstnameInvalid"
                    :invalid-label="t('firstnameInvalid')"
                    :label="t('firstName')"
                    :default-value="userFirstname"
                    required
                    @update:value="onUpdateUserFirstname"
                />
                <simple-input
                    id="lastname"
                    :invalid="lastnameInvalid"
                    :invalid-label="t('lastnameInvalid')"
                    :label="t('name')"
                    :default-value="userLastname"
                    required
                    @update:value="onUpdateUserLastname"
                />
            </div>
            <simple-input id="username" :invalid="usernameInvalid" :invalid-label="t('usernameInvalid')" :label="t('username')" :default-value="userName" required @update:value="onUpdateUserName" />
        </b-form>
    </base-modal>
</template>

<script lang="ts" setup>
    import { computed, ComputedRef, ref, Ref, watch, inject } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useUsersStore } from '@/store';
    const snack: any = inject('snackBar');

    const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    interface Props {
        user?: iUser | null;
        modalShow: boolean;
        closeModal: () => void;
    }

    const props = defineProps<Props>();

    const { t } = useI18n({ useScope: 'global' });

    const usersStore = useUsersStore();

    const firstnameInvalid: ComputedRef<boolean> = computed(() => {
        return userFirstname.value === '';
    });

    const lastnameInvalid: ComputedRef<boolean> = computed(() => {
        return userLastname.value === '';
    });

    const usernameInvalid: ComputedRef<boolean> = computed(() => {
        return userName.value === '' || (!!props.user && props.user.email !== userName.value && !EMAIL_REGEXP.test(userName.value));
    });

    const dataChanged: ComputedRef<boolean> = computed(() => {
        return props.user ? userFirstname.value !== props.user.firstname || userLastname.value !== props.user.lastname || userName.value !== props.user.email : false;
    });

    const modalDisabled: ComputedRef<boolean> = computed(() => {
        return !dataChanged.value || (dataChanged.value && (firstnameInvalid.value || lastnameInvalid.value || usernameInvalid.value));
    });

    const userFirstname: Ref<string> = ref(props.user?.firstname || '');
    const userLastname: Ref<string> = ref(props.user?.lastname || '');
    const userName: Ref<string> = ref(props.user?.email || '');

    const editUser = async () => {
        if (props.user) {
            try {
                await usersStore.editUser(props.user.uid, {
                    firstname: userFirstname.value,
                    lastname: userLastname.value,
                    email: userName.value,
                });
                snack.open('success', t('userSuccessfullyModified'));
            } catch (error) {
                snack.open('alert', t('userModificationFailed'));
                console.error(error);
            }

            props.closeModal();
        }
    };

    const onUpdateUserFirstname = ({ value }: { value: string }) => {
        userFirstname.value = value;
    };

    const onUpdateUserLastname = ({ value }: { value: string }) => {
        userLastname.value = value;
    };

    const onUpdateUserName = ({ value }: { value: string }) => {
        userName.value = value;
    };

    watch(
        () => props.user,
        (user) => {
            userFirstname.value = user?.firstname || '';
            userLastname.value = user?.lastname || '';
            userName.value = user?.email || '';
        }
    );
</script>

<!-- UNSCOPED for Modal style -->
<style lang="scss">
    #user-modal-edit {
        .fsb-base-modal {
            &__content {
                overflow: hidden;
                display: flex;
                flex-direction: column;

                &.--with-max-height {
                    .list-categories-inputs__fields {
                        margin-bottom: 56px;
                    }
                }
            }
        }

        form {
            & > * {
                margin-bottom: 16px;
            }

            overflow: hidden;
            display: flex;
            flex-direction: column;

            .user-modal-edit {
                &__block--2 {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 16px;
                }

                &__instruction {
                    font-weight: bold;
                }
            }
        }
    }
</style>
