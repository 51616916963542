<template>
    <div class="create-theme step-1">
        <b-form>
            <multi-select
                class="fsb-typo-heading-6"
                id="select-operation"
                with-search-bar
                with-nested-list
                with-floating-options
                :items="formattedActivations"
                :label="t('selectActivation')"
                :field-label-text="t('associatedActivation')"
                :search-label="t('searchOperation')"
                :search-bar-minimum-characters="3"
                :enable-multi-select="false"
                :with-custom-nothing="{
                    text: t('selectSearchNoOperation'),
                    cta: {
                        label: t('createOperation'),
                        onClick: () => {
                            // TODO
                            // Create new operation
                        },
                    },
                }"
                :required="findIsRequired('select-operation')"
                nested-item-label="name"
                @update:value="($evt: any) => onUpdateValue({...$evt, id: 'select-operation'})"
                @input="($evt: any) => updateActivator($evt)"
            />

            <simple-input
                class="fsb-typo-heading-6"
                id="input-theme-name"
                :label="t('themeName')"
                :placeholder="themeDetails?.label ? null : t('themeSelectPlaceholder')"
                required
                :default-value="themeDetails?.label"
                @update:value="($evt: any) => onUpdateValue({...$evt, id: 'input-theme-name'})"
            />

            <div class="create-theme__inline-inputs">
                <multi-select
                    class="fsb-typo-heading-6"
                    id="select-brand"
                    with-floating-options
                    :items="formattedBrands"
                    :label="t('allBrands')"
                    :field-label-text="t('brand')"
                    :required="findIsRequired('select-brand')"
                    :enable-multi-select="false"
                    @update:value="($evt: any) => onUpdateValue({...$evt, id: 'select-brand'})"
                />
                <multi-select
                    class="fsb-typo-heading-6"
                    id="select-trade"
                    with-floating-options
                    :items="formattedTrades"
                    :label="t('selectTrade')"
                    :field-label-text="t('trade')"
                    :required="findIsRequired('select-trade')"
                    :enable-multi-select="false"
                    @update:value="($evt: any) => onUpdateValue({...$evt, id: 'select-trade'})"
                />
            </div>

            <multi-select
                id="select-product"
                class="fsb-typo-heading-6"
                with-floating-options
                :items="formattedProducts"
                :label="t('selectProduct')"
                :field-label-text="t('product')"
                :required="findIsRequired('select-product')"
                :enable-multi-select="false"
                @update:value="($evt: any) => onUpdateValue({...$evt, id: 'select-product'})"
            />

            <multi-select
                class="fsb-typo-heading-6"
                id="select-category"
                with-floating-options
                with-nested-list
                nested-item-label="name"
                :items="formattedCategories"
                :label="t('selectCategory')"
                :field-label-text="t('categories')"
                :required="findIsRequired('select-category')"
                @update:value="($evt: any) => onUpdateValue({...$evt, id: 'select-category'})"
            />

            <separator-gap />

            <div class="create-theme__periods">
                <field-label :label="t('availablePeriodOnAdcleek')" class="fsb-typo-heading-6" />
                <div class="create-theme__inline-inputs">
                    <div>
                        <field-label :label="t('startDate')" for-field="available-adcleek-start-date" :required="findIsRequired('available-adcleek-start-date')" class="fsb-typo-heading-6" />
                        <b-form-datepicker
                            id="available-adcleek-start-date"
                            v-model="availableStartDate"
                            :min="availableStartMin"
                            :max="availableStartMax"
                            :locale="APP_LOCALE"
                            :placeholder="t('selectDate')"
                            :required="findIsRequired('available-adcleek-start-date')"
                            @input="($evt: any) => onUpdateValue({value: $evt, id: 'available-adcleek-start-date'})"
                            class="fsb-typo-heading-6"
                        ></b-form-datepicker>
                    </div>
                    <div>
                        <field-label :label="t('endDate')" for-field="available-adcleek-end-date" :required="findIsRequired('available-adcleek-end-date')" class="fsb-typo-heading-6" />
                        <b-form-datepicker
                            class="fsb-typo-heading-6"
                            id="available-adcleek-end-date"
                            v-model="availableEndDate"
                            :min="availableEndMin"
                            :max="availableEndMax"
                            :locale="APP_LOCALE"
                            :placeholder="t('selectDate')"
                            :required="findIsRequired('available-adcleek-end-date')"
                            @input="($evt: any) => onUpdateValue({value: $evt, id: 'available-adcleek-end-date'})"
                        ></b-form-datepicker>
                    </div>
                </div>
            </div>

            <separator-gap />

            <div class="create-theme__periods">
                <field-label :label="t('campaignPeriod')" class="fsb-typo-heading-6" />
                <div class="create-theme__inline-inputs">
                    <div>
                        <field-label :label="t('startDate')" for-field="campaign-start-date" :required="findIsRequired('campaign-start-date')" class="fsb-typo-heading-6" />
                        <b-form-datepicker
                            class="fsb-typo-heading-6"
                            id="campaign-start-date"
                            v-model="campaignStartDate"
                            :min="availableStartMin"
                            :max="availableStartMax"
                            :locale="APP_LOCALE"
                            :placeholder="t('selectDate')"
                            :required="findIsRequired('campaign-start-date')"
                            @input="($evt: any) => onUpdateValue({value: $evt, id: 'campaign-start-date'})"
                        ></b-form-datepicker>
                    </div>
                    <div>
                        <field-label :label="t('endDate')" for-field="campaign-end-date" :required="findIsRequired('campaign-end-date')" class="fsb-typo-heading-6" />
                        <b-form-datepicker
                            class="fsb-typo-heading-6"
                            id="campaign-end-date"
                            v-model="campaignEndDate"
                            :min="availableEndMin"
                            :max="availableEndMax"
                            :locale="APP_LOCALE"
                            :placeholder="t('selectDate')"
                            :required="findIsRequired('campaign-end-date')"
                            @input="($evt: any) => onUpdateValue({value: $evt, id: 'campaign-end-date'})"
                        ></b-form-datepicker>
                    </div>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script lang="ts" setup>
    import { ref, Ref, computed, ComputedRef, watch, onMounted } from 'vue';
    import { useThemeStore } from '@/store';
    import { useCategoryStore } from '@/store';
    import { useCustomerStore } from '@/store/views/customer';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';
    import { iActivation, iModel, iCategory, iMultiSelectOption, iDataValidation, iMetaCategoryDetails } from '@/interfaces/global';
    import { APP_LOCALE } from '@/helpers/translations';
    import { findIsRequired as _findIsRequired, checkDataValid, formatDataValue } from '@/helpers/dataValidation';
    import { flattenDeep } from 'lodash';
    import { Trade, BrandGroups } from '@/interfaces/global';

    const props = defineProps({
        themeDetails: {
            type: Object,
            default: null,
        },
    });

    const emit = defineEmits(['data-validation', 'update:value']);

    const { t } = useI18n({ useScope: 'global' });

    const customerStore = useCustomerStore();
    const themeStore = useThemeStore();
    const categoryStore = useCategoryStore();

    const { tradesModels, trades } = storeToRefs(customerStore);
    const { activations, models, brandsGroups } = storeToRefs(themeStore);
    const { categories, metaCategories } = storeToRefs(categoryStore);

    //TODO: ask for start and end date min max from user story
    const availableStartMin: Ref<string> = ref('');
    const availableStartMax: Ref<string> = ref('');
    const availableEndMin: Ref<string> = ref('');
    const availableEndMax: Ref<string> = ref('');

    const availableStartDate: Ref<string> = ref(props.themeDetails ? props.themeDetails.validity_start_date : '');
    const availableEndDate: Ref<string> = ref(props.themeDetails ? props.themeDetails.validity_end_date : '');
    const campaignStartDate: Ref<string> = ref(props.themeDetails ? props.themeDetails.start_date : '');
    const campaignEndDate: Ref<string> = ref(props.themeDetails ? props.themeDetails.end_date : '');

    const formattedActivations: ComputedRef<iMultiSelectOption[]> = computed(() =>
        activations.value.map((a: iActivation) => ({
            value: a.activator_code,
            label: a.label,
            nestedList: a.operations ? a.operations.map((o) => ({ ...o, value: o.code, selected: props.themeDetails ? props.themeDetails.details?.operation_code?.includes(o.code) : false })) : [],
            selected: props.themeDetails ? props.themeDetails.details?.activator_code?.includes(a.activator_code) : false,
        }))
    );

    const formattedTrades: ComputedRef<iMultiSelectOption[]> = computed(() => {
        if (tradesModels.value && Object.keys(tradesModels.value).length) {
            return Object.keys(tradesModels.value).map((t: string) => {
                const trade = trades.value.find((trade: Trade) => trade.trade_code === t);
                return {
                    value: trade?.trade_code,
                    label: trade?.label,
                    selected: props.themeDetails ? props.themeDetails.details?.trade?.includes(t) : false,
                };
            });
        } else {
            return trades.value.map((trade: Trade) => ({
                value: trade.trade_code,
                label: trade.label,
                selected: props.themeDetails ? props.themeDetails.details?.trade?.includes(trade.trade_code) : false,
            }));
        }
    });

    const formattedProducts: ComputedRef<iMultiSelectOption[]> = computed(() =>
        models.value.map((m: iModel) => ({ value: m.model_code, label: m.name, selected: props.themeDetails ? props.themeDetails.details?.models?.includes(m.model_code) : false }))
    );

    const formattedBrands: ComputedRef<iMultiSelectOption[]> = computed(() => {
        return [
            {
                value: 'all',
                label: t('allBrands'),
                selected: props.themeDetails ? props.themeDetails.details?.groups?.includes('all') : true,
            },
            ...brandsGroups.value.map((group: BrandGroups) => ({
                value: group.code,
                label: group.name,
                selected: props.themeDetails ? props.themeDetails.details?.groups?.includes(group.code) : false,
            })),
        ];
    });

    const formattedCategories: ComputedRef<iMultiSelectOption[]> = computed(() => {
        const categoriesGrouped = metaCategories.value.map((m: iMetaCategoryDetails) => ({
            value: m.crea_metacategory_code,
            label: m.name,
            nestedList: m.creacategories
                ? m.creacategories.map((c) => {
                      const isCreaSelected = props.themeDetails
                          ? !!flattenDeep(categories.value.map((cat) => cat.creas ?? [])).find((c_) => c_.crea_code === props.themeDetails.crea_code && c.crea_category_code === c_.crea_category_code)
                          : false;

                      return {
                          ...c,
                          value: c.crea_category_code,
                          selected: props.themeDetails ? isCreaSelected : false,
                      };
                  })
                : [],
            selected: false,
        }));

        const categoriesUnGrouped = categories.value.filter((c: iCategory) => !c.metas.length);

        return [
            ...categoriesGrouped,
            {
                value: t('noMetacategory'),
                label: t('noMetacategory'),
                nestedList: categoriesUnGrouped.map((c) => {
                    const isCreaSelected = props.themeDetails
                        ? !!flattenDeep(categoriesUnGrouped.map((cat) => cat.creas ?? [])).find((c_) => c_.crea_code === props.themeDetails.crea_code && c.crea_category_code === c_.crea_category_code)
                        : false;

                    return {
                        ...c,
                        value: c.crea_category_code,
                        selected: isCreaSelected,
                    };
                }),
                selected: false,
            },
        ];
    });

    const findIsRequired = (id: string) => _findIsRequired(id, dataValidation.value);

    const dataValidation: Ref<iDataValidation[]> = ref([
        {
            id: 'select-operation',
            isValid: false,
            required: formattedActivations.value.length > 0,
            keyDataValue: 'details.operation_code',
            defaultValue: props.themeDetails ? props.themeDetails.details?.operation_code : null,
        },
        { id: 'input-theme-name', isValid: false, required: true, keyDataValue: 'label', defaultValue: props.themeDetails ? props.themeDetails.label : null },
        { id: 'select-brand', isValid: false, required: false, keyDataValue: 'details.groups', defaultValue: props.themeDetails ? props.themeDetails.details?.groups : null },
        { id: 'select-trade', isValid: false, required: false, keyDataValue: 'details.trade', defaultValue: props.themeDetails ? props.themeDetails.details?.trade : null },
        { id: 'select-product', isValid: false, required: false, keyDataValue: 'details.models', defaultValue: props.themeDetails ? props.themeDetails.details?.models : null },
        {
            id: 'select-category',
            isValid: false,
            required: false,
            keyDataValue: 'categories',
            defaultValue: props.themeDetails ? categories.value.filter((c) => c.creas?.map((cc) => cc.crea_code)?.includes(props.themeDetails.crea_code)) : null,
        },
        { id: 'available-adcleek-start-date', isValid: false, required: true, keyDataValue: 'validity_start_date', defaultValue: props.themeDetails ? props.themeDetails.validity_start_date : null },
        { id: 'available-adcleek-end-date', isValid: false, required: true, keyDataValue: 'validity_end_date', defaultValue: props.themeDetails ? props.themeDetails.validity_end_date : null },
        { id: 'campaign-start-date', isValid: false, required: true, keyDataValue: 'start_date', defaultValue: props.themeDetails ? props.themeDetails.start_date : null },
        { id: 'campaign-end-date', isValid: false, required: true, keyDataValue: 'end_date', defaultValue: props.themeDetails ? props.themeDetails.end_date : null },
    ]);

    const isDataValid: ComputedRef<boolean> = computed(() => checkDataValid(dataValidation.value));

    const onUpdateValue = ({ value, id }: { value: string | number; id: string }) => {
        const index = dataValidation.value.findIndex((d: iDataValidation) => d.id === id);
        dataValidation.value[index].isValid = value !== '' && !!value.length;
        emit('update:value', { value: { ...formatDataValue(dataValidation.value[index], value) }, id });
    };

    const updateActivator = async (value: string) => {
        // TODO
    };

    watch(isDataValid, (value: boolean) => emit('data-validation', { isDataValid: value }));

    onMounted(() => {
        if (props.themeDetails) {
            let themeDetails = {};
            dataValidation.value.forEach((entry: iDataValidation) => {
                themeDetails = { ...themeDetails, ...formatDataValue(entry, entry.defaultValue) };
            });
            emit('update:value', { value: themeDetails });
        }
    });
</script>

<style lang="scss">
    .step-1 {
        form {
            & > *:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    .create-theme {
        &_periods {
            & > *:not(:first-child) {
                margin-bottom: 4px;
            }
        }
        &__inline-inputs {
            display: flex;
            justify-content: space-between;

            & > * {
                flex: 1;
            }

            & > *:last-child {
                margin-left: 8px;
            }
        }
    }
</style>
