<template>
    <div class="checkboxes-list">
        <h3 class="fsb-typo-heading-5">{{ title }}</h3>

        <div class="checkboxes-list__filter">
            <search-input ref="searchInputComponent" :id="`search-input-checkboxes-list-${id}`" full-width :placeholder="searchInputPlaceholder" @update:value="updateFilterKeyword" />
            <transition name="fadeX" mode="out-in">
                <fin-groups-filter ref="financialComponent" v-if="itemsToFilter.length" :items-to-filter="itemsToFilter" @update:financialGroup="updateSelectedGroups" />
            </transition>
        </div>

        <div class="checkboxes-list__items">
            <checkbox-all v-if="items.length" :name="`checkbox-all-${id}`" :list="filteredItems" @select="() => changeAllOptionValues(true)" @unselect="() => changeAllOptionValues(false)" />
            <separator-gap v-if="showSeparator" :height="16" with-border></separator-gap>
            <div class="checkboxes-list__items__cards">
                <checkbox-card v-for="item of filteredItems" variant="background" :key="item[uniqueKey]" :reverse="true" :name="item[uniqueKey]" v-model:checked="item.checked">
                    <slot :item="item"></slot>
                </checkbox-card>
            </div>
        </div>

        <transition name="fadeY" mode="out-in">
            <nothing-found v-if="!filteredItems.length" :text="nothingFoundLabel"></nothing-found>
        </transition>

        <transition name="fadeY" mode="out-in">
            <div class="checkboxes-list__footer fsb-bg-gray-white" v-if="selectedItems.length > 0">
                <p class="fsb-typo-para-regular">{{ selectedItems.length }} {{ selectedLabel }}</p>
                <ui-button :label="t('cancel')" variant="secondary" small @click="cancelSelection"></ui-button>
                <ui-button :label="confirmLabel" small @click="confirmSelectionFn"></ui-button>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
    import { iGenericOption } from '@/interfaces/global';
    import { computed, defineExpose, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import FinGroupsFilter from './FinGroupsFilter.vue';
    import { onMounted } from 'vue';
    import { formatForSearchValue } from '@/helpers/tools';
    import { difference, intersection, intersectionBy, union, uniq } from 'lodash';

    interface Props {
        title: string;
        id: string;
        searchInputPlaceholder: string;
        searchKeys: string[];
        items: any[];
        uniqueKey: string;
        selectedLabel?: string;
        confirmLabel: string;
        confirmSelectionFn: () => void;
        cancelSelectionFn?: () => void;
        itemsToFilter: iGenericOption[];
        showSeparator?: boolean;
        nothingFoundLabel?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        selectedLabel: 'items selected',
        cancelSelectionFn: () => {
            //
        },
        itemsToFilter: () => [],
        showSeparator: true,
        nothingFoundLabel: '',
    });

    const emits = defineEmits(['onUpdateSelectedItems']);

    const { t } = useI18n({ useScope: 'global' });

    const searchInputComponent = ref(null);
    const financialComponent = ref(null);
    const keywordToFilter = ref('');
    const filterResultsOfFinGroup = ref<string[] | null>(null);
    const formattedItems = ref<any[]>([]);
    const filteredItems = ref<any[]>([]);

    const selectedItems = computed(() => formattedItems.value.filter((item) => item.checked));

    const isPresent = (element: string, keyword: string) => element && formatForSearchValue(element).includes(keyword);

    watch(
        () => selectedItems.value,
        (selectedItems) => emits('onUpdateSelectedItems', { selectedItems })
    );

    watch(
        () => props.items,
        () => {
            formattedItems.value = props.items.map((item) => {
                return { ...item, value: item[props.uniqueKey], checked: false };
            });
            filteredItems.value = formattedItems.value.slice();
        }
    );

    watch([() => keywordToFilter.value, () => filterResultsOfFinGroup.value], ([keyword, newFilterResultsOfFinGroup]) => {
        const formattedKeyword = formatForSearchValue(keyword);
        if (formattedKeyword || newFilterResultsOfFinGroup) {
            const isKeywordIncludedInItem = (item: any, keyword: string) => {
                return (
                    isPresent(item.name, keyword) || isPresent(item.firstname, keyword) || isPresent(item.lastname, keyword) || isPresent(item.email, keyword) || isPresent(item.point_code, keyword)
                );
            };

            const withKeyword = formattedKeyword.length > 0 ? formattedItems.value.filter((item) => isKeywordIncludedInItem(item, formattedKeyword)) : formattedItems.value;
            const withFinGroup = newFilterResultsOfFinGroup ?? formattedItems.value;

            filteredItems.value = intersectionBy(withKeyword, withFinGroup, props.items[0].hasOwnProperty('uid') ? 'uid' : 'unique_point_code');
        } else {
            filteredItems.value = formattedItems.value;
        }
    });

    const cancelSelection = () => {
        changeAllOptionValues(false);
        props.cancelSelectionFn();
        searchInputComponent.value?.emptyInput();
        financialComponent.value?.resetFinancialDropdown();
    };

    const changeAllOptionValues = (checked: boolean) => {
        filteredItems.value.forEach((option) => {
            option.checked = checked;
        });
    };

    const updateSelectedGroups = (filterResult: iGenericOption[]) => {
        filterResultsOfFinGroup.value = filterResult;
    };

    const updateFilterKeyword = ({ value }: { value: string }) => {
        keywordToFilter.value = value;
    };

    onMounted(() => {
        formattedItems.value = props.items.map((item) => {
            return { ...item, value: item[props.uniqueKey], checked: false };
        });
        filteredItems.value = formattedItems.value.slice();
    });

    defineExpose({
        cancelSelection,
    });
</script>

<style lang="scss">
    .checkboxes-list {
        padding: 40px 0;

        h3 {
            font-weight: 700 !important;
            margin-bottom: 24px;
        }

        &__filter {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;

            .fsb-search-input {
                flex: 1;
            }

            .position-relative {
                width: auto !important;
            }
        }

        &__items {
            margin-top: 24px;

            &__cards {
                margin-top: 14px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .fsb-checkbox-card {
                    border-radius: 16px;
                    margin: 0 2px;
                }
            }
        }

        &__footer {
            position: fixed;
            bottom: 0;
            right: 0;
            width: 100%;
            padding: 16px 40px 16px 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            z-index: 4;
        }
    }
</style>
