<template>
    <div class="search-input" :style="`--search-input-width: ${width};`">
        <span class="search-input__icon">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </span>
        <simple-input class="search-input__field" :id="id" :placeholder="placeholder" @update:value="updateValue" />
    </div>
</template>

<script lang="ts" setup>
    import SimpleInput from '@/components/SharedUi/SimpleInput.vue';

    interface Props {
        id: string;
        placeholder?: string;
        width: string;
    }

    withDefaults(defineProps<Props>(), {
        placeholder: '',
        width: '32%',
    });

    const emit = defineEmits(['update:value']);

    const updateValue = ({ value }: { value: string }) => emit('update:value', { value: value as string });
</script>

<style scoped lang="scss">
    .search-input {
        position: relative;
        width: var(--search-input-width);

        &__icon {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
</style>

<style lang="scss">
    .search-input {
        .simple-input__field {
            padding: 10px 12px;
            padding-left: 32px;
        }
    }
</style>
