import { defineStore } from 'pinia';
import { axiosInstance } from '@/axiosInstance';
import { useGlobalStore } from '@/store';
import { Trade, TradesModels } from '@/interfaces/global';

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            return Promise.resolve({ data: null });
        }
    }
);

export const useCustomerStore = defineStore('useCustomerStore', {
    state: () => ({
        tradesModels: {} as TradesModels,
        trades: [] as Trade[],
    }),
    getters: {
        apiTradesModels() {
            return '/oversight/v1/pos-plans/models/trades';
        },
        apiTrades() {
            return '/oversight/v1/global/configs';
        },
    },
    actions: {
        async getTradesModels() {
            try {
                const globalStore = useGlobalStore();
                const brand: string = globalStore.brand;
                const config = {
                    headers: {
                        brand,
                    },
                };
                this.tradesModels = (await axiosInstance.get(this.apiTradesModels, config)).data;
            } catch (error) {
                console.log(error);
            }
        },
        async getTrades() {
            try {
                const globalStore = useGlobalStore();
                const brand: string = globalStore.brand;
                const config = {
                    headers: {
                        brand,
                    },
                };
                this.trades = (await axiosInstance.get(this.apiTrades, config)).data.trades;
            } catch (error) {
                console.log(error);
            }
        },
    },
});
