<template>
    <div class="simple-input">
        <div class="simple-input__label" v-if="label">
            <field-label :label="label" :required="required" :for-field="id" />
        </div>

        <b-form-input class="simple-input__field" v-model="inputValue" :id="id" :name="id" :placeholder="placeholder" :required="required" @input="updateValue" />
    </div>
</template>

<script lang="ts" setup>
    import { Ref, ref } from 'vue';
    import FieldLabel from './FieldLabel.vue';

    interface Props {
        id: string;
        label?: string;
        placeholder?: string;
        type: string;
        required?: boolean;
        defaultValue?: string | number | null;
    }

    const props = withDefaults(defineProps<Props>(), {
        type: 'text',
        placeholder: '',
        required: false,
        defaultValue: null,
    });

    const emit = defineEmits(['update:value']);

    const inputValue: Ref<string | number> = ref(props.defaultValue || (props.type === 'number' || props.type === 'tel' ? 0 : ''));

    const updateValue = (value: string | number) => {
        inputValue.value = value;
        emit('update:value', { value });
    };
</script>

<style scoped lang="scss">
    .simple-input {
        * {
            font-family: Inter;
            font-style: normal;
            letter-spacing: -0.4px;
        }

        &__label {
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            label {
                color: var(--grayscale-black);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-right: 4px !important;
            }
        }

        &__field {
            border-radius: 8px;
            border: 1px solid var(--grayscale-gray-regular);
            background-color: var(--grayscale-white);
            padding: 12px 16px;
            height: auto;

            &::placeholder {
                color: var(--grayscale-gray-dark);
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
            }
        }
    }
</style>
