import { axiosInstance } from '@/axiosInstance';
import { Zone } from '@/interfaces/global';
import { useGlobalStore } from '@/store';
import { ActionType, AllGeoJSON, TownData, ZoneData } from '@adcleek/front-zones-lib';
import { defineStore } from 'pinia';

export const useZoneStore = defineStore('useZoneStore', {
    state: () => ({
        zoneList: [] as Zone[],
        zoneData: { data: {}, townDataList: [] } as unknown as ZoneData,
        showZoneEditorOfPos: false,
        posName: '',
        actionType: ActionType.CREATION,
    }),
    getters: {},
    actions: {
        async initZoneEditor(pointCode: string, zoneCode?: string) {
            if (zoneCode) {
                await this.getZoneData(pointCode, zoneCode);
            } else {
                this.zoneData.data.pointCode = pointCode;
                await this.getTownDataList(pointCode);
            }
        },

        async getZoneList(pointCode: string) {
            try {
                const global = useGlobalStore();
                const { data } = await axiosInstance.get(`/zones/brand/${global.brand}/pos/${pointCode}/zones`);
                this.zoneList = data;
            } catch (error) {
                console.log(error);
            }
        },

        async updateStatus(index: number, status: boolean) {
            try {
                if (status) {
                    await axiosInstance.put(`/zones/zone/${this.zoneList[index].code}/activate`);
                } else {
                    await axiosInstance.put(`/zones/zone/${this.zoneList[index].code}/deactivate`);
                }
            } catch (error) {
                console.log(error);
            }
        },

        async getZoneData(pointCode: string, zoneCode: string) {
            try {
                const global = useGlobalStore();
                const { data } = await axiosInstance.get(`/zones/brand/${global.brand}/pos/${pointCode}/zone/${zoneCode}`);
                this.zoneData = data;
            } catch (error) {
                console.log(error);
            }
        },

        async getTownDataList(pointCode: string) {
            try {
                const global = useGlobalStore();
                const { data } = await axiosInstance.get(`/zones/brand/${global.brand}/pos/${pointCode}/towns`);
                this.zoneData.townDataList = data;
            } catch (error) {
                console.log(error);
            }
        },

        async createZone(zoneData: ZoneData) {
            const global = useGlobalStore();
            await axiosInstance.post('/zones/zone', {
                data: { ...zoneData.data, brand: global.brand },
                townCodeList: zoneData.townDataList.filter((townData) => townData.active).map((townData) => townData.townCode),
            });
        },

        async updateZone(zoneData: ZoneData) {
            await axiosInstance.put(`/zones/zone/${zoneData.data.code}`, {
                data: zoneData.data,
                townCodeList: zoneData.townDataList.filter((townData) => townData.active).map((townData) => townData.townCode),
            });
        },

        async deleteZone(zoneCode: string) {
            await axiosInstance.delete(`/zones/zone/${zoneCode}`);
        },

        async getPolygon(townDataList: TownData[]): Promise<AllGeoJSON> {
            try {
                const hashList = townDataList.filter((townData) => townData.active).map((townData) => townData.townGeometryHash);
                const { data } = await axiosInstance.post(`/zones/polygon`, { hashList });
                return data;
            } catch (error) {
                throw error;
            }
        },

        closeZoneEditor() {
            this.showZoneEditorOfPos = false;
            this.zoneData = { data: {}, townDataList: [] } as unknown as ZoneData;
        },
    },
});
