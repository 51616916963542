<template>
    <div class="list-categories-inputs">
        <div class="list-categories-inputs__label" v-if="label">
            <field-label class="fsb-typo-heading-6" :label="label" :required="required" />
        </div>
        <div class="list-categories-inputs__fields" ref="listRef">
            <div class="input" v-for="(category, index) of categories" :key="`list-simple-input=${id}-${index}`" :id="`list-simple-input=${id}-${index}`" :name="`list-simple-input=${id}-${index}`">
                <b-form-input class="list-categories-inputs__field" v-model="category.name" :placeholder="placeholder" :required="index === 0" trim @input="onInputCategory" />
                <transition name="fadeX" mode="out-in">
                    <icon-button v-if="index === 0 && categories.length === 1" icon-type="add" :on-click="addInputCategory" />
                    <icon-button v-else icon-type="delete" :on-click="() => removeInputCategory(index)" />
                </transition>
            </div>
            <transition name="fade" mode="out-in">
                <div class="input--add" v-if="categories.length > 1">
                    <ui-button :label="t('addCategory')" :on-click="addInputCategory" variant="secondary">
                        <template #prefix-icon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M0.765625 7.00003H12.7656H0.765625Z" fill="#1F1F1F" />
                                <path d="M0.765625 7.00003H12.7656" stroke="#1F1F1F" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.76562 1V13" stroke="#1F1F1F" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </template>
                    </ui-button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { Ref, ref, onMounted } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { iCategory } from '@/interfaces/global';

    const { t } = useI18n({ useScope: 'global' });

    interface Props {
        id: string;
        label?: string;
        placeholder?: string;
        type: string;
        required?: boolean;
        defaultValue?: iCategory[] | null;
    }

    const props = withDefaults(defineProps<Props>(), {
        type: 'text',
        placeholder: '',
        defaultValue: null,
    });

    const emit = defineEmits(['update:categories', 'remove:categories']);

    const categories: Ref<iCategory[]> = ref(props.defaultValue && props.defaultValue.length > 0 ? props.defaultValue : [{ name: '' }]);

    const onInputCategory = (categoryName: string) => {
        const updatedCategoryIndex = categories.value.findIndex((cat: iCategory) => cat.name === categoryName);

        if (updatedCategoryIndex !== -1) {
            emit('update:categories', { categories: categories.value.filter((cat: iCategory) => cat.name !== ''), updatedCategory: categories.value[updatedCategoryIndex] });
        }
    };

    const listRef: Ref<any> = ref(null);

    const scrollToBottom = () => {
        if (listRef.value) {
            setTimeout(() => {
                listRef.value.scrollTo({ top: listRef.value.scrollHeight, behavior: 'smooth' });
            }, 320);
        }
    };

    const addInputCategory = () => {
        categories.value.push({ name: '' });
        emit('update:categories', { categories: categories.value });

        scrollToBottom();
    };

    const removeInputCategory = (index: number) => {
        emit('update:categories', { categories: categories.value });
        emit('remove:categories', { tempRemovedCategory: categories.value[index] });

        categories.value.splice(index, 1);
    };

    onMounted(() => {
        scrollToBottom();
    });
</script>

<style scoped lang="scss">
    .list-categories-inputs {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;

        &__label {
            display: flex;
            align-items: center;
            margin-bottom: 2px;
        }

        &__fields {
            margin-top: 8px;
            overflow-y: auto;
            overflow-x: hidden;
            margin-bottom: 48px;

            .input {
                display: flex;
                width: 100%;
                margin-bottom: 8px;
            }

            .input--add {
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }

        &__field {
            border-radius: 8px;
            border: 1px solid var(--grayscale-gray-regular);
            background-color: var(--grayscale-white);
            padding: 12px 16px;
            flex: 1;
            height: 100%;

            &::placeholder {
                color: var(--grayscale-gray-dark);
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
            }
        }

        .fsb-icon-button {
            margin-left: 16px;
        }
    }
</style>
