<template>
    <component :is="wrapper" class="user-card">
        <edit-role-item
            v-if="isEditing"
            :main-text="`${userFullname}`"
            :secondary-text="t('moduleRole')"
            :role="user.role"
            @cancel="isEditing = false"
            @update-role="(newRole) => updateRole(user, newRole)"
        />

        <list-item-with-role-label
            v-else
            :main-text="`${userFullname}`"
            :secondary-text="user.email"
            :role="role"
            :enable-icon-group-mode="true"
            btn-icon="edit"
            second-btn-icon="delete"
            @btn-action="isEditing = true"
            @second-btn-action="() => (modalDeleteData = { showModal: true, index, user })"
        />

        <modal-delete v-model:show-modal="modalDeleteData.showModal" @delete="deleteUserOfPos">
            <p>
                {{ t('modalMessageToDeleteUser', { name: userFullname }) }}
                <br />
                {{ t('irreversibleAction') }}
            </p>
        </modal-delete>
    </component>
</template>

<script setup lang="ts">
    import { iUserOfPos } from '@/interfaces/global';
    import { computed, ref } from 'vue';
    import { usePosStore } from '@/store';
    import { useI18n } from 'vue-i18n';

    import ListItemWithRoleLabel from '../SharedUi/ListItemWithRoleLabel.vue';
    import EditRoleItem from '@/components/SharedUi/EditRoleItem.vue';
    import ModalDelete from '../SharedUi/ModalDelete.vue';

    const props = withDefaults(
        defineProps<{
            user: iUserOfPos;
            index: number;
            pointCode: string;
            wrapper?: string;
        }>(),
        {
            wrapper: 'div',
        }
    );

    const emits = defineEmits(['update:user', 'deleteUserFromList']);

    const { t } = useI18n({ useScope: 'global' });

    const posStore = usePosStore();

    const isEditing = ref(false);

    const role = ref(props.user.role);

    const modalDeleteData = ref({ showModal: false, index: 0, user: {} as iUserOfPos });

    const userFullname = computed(() => `${props.user.firstName} ${props.user.lastName}`);

    const updateRole = async (user: iUserOfPos, newRole: number) => {
        if (user.role !== newRole) {
            const res = await posStore.updateUserRole(props.pointCode, user.uid, user.code, newRole);
            if (res !== undefined) {
                user.role = newRole;
                role.value = newRole;
                isEditing.value = false;
            }
        } else {
            isEditing.value = false;
        }
        emits('update:user', user);
    };

    const deleteUserOfPos = async () => {
        try {
            await posStore.deleteUserOfPos(modalDeleteData.value.user.code);
            emits('deleteUserFromList');
            modalDeleteData.value.showModal = false;
        } catch (error) {
            console.log(error);
        }
    };
</script>

<style lang="scss">
    .user-card {
        cursor: pointer;
        width: 100%;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid var(--grayscale-background);
        background-color: transparent;
        transition: background-color ease-in-out 0.32s;

        &:not(.group-item):hover {
            background-color: var(--grayscale-background);
        }
    }
</style>
