<template>
    <base-modal
        :modal-show="modalShow"
        id="duplicate-user"
        :title="t('addUser')"
        hide-header-close
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="onCloseModal"
        :show-secondary-button="false"
        :primary-label="t('duplicate')"
        :on-primary-button-click="createUser"
        :is-primary-button-disabled="buttonDisabled"
        @close-modal="onCloseModal"
    >
        <form-schema
            v-if="staticSchemas.length"
            :title="t('editUserInstruction')"
            :schemas="staticSchemas"
            @on-form-validate="(value: eFormState) => onFormValidate(value, 'static')"
            @on-update-form="(value: Record<string, any>) => onUpdateForm(value, 'static')"
        ></form-schema>

        <form-schema
            v-if="dynamicSchemas.length"
            :title="t('rolePerService')"
            :schemas="dynamicSchemas"
            @on-form-validate="(value: eFormState) => onFormValidate(value, 'dynamic')"
            @on-update-form="(value: Record<string, any>) => onUpdateForm(value, 'dynamic')"
        ></form-schema>
    </base-modal>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import { ref, reactive, onMounted, watch, computed } from 'vue';
    import { eFormType, eFormState, iUser } from '@adcleek/front-storybook/fsb-interfaces';
    import { useUsersStore } from '@/store';
    import { inject } from 'vue';

    const emit = defineEmits(['close-modal']);
    const snack: any = inject('snackBar');
    const userStore = useUsersStore();

    interface iProps {
        modalShow: boolean;
        brand?: string;
        userDetails?: iUser;
    }
    const props = defineProps<iProps>();
    const { t } = useI18n({ useScope: 'global' });

    interface iUserForm {
        firstname: string;
        lastname: string;
        email: string;
    }

    const form = reactive<iUserForm>({
        firstname: '',
        lastname: '',
        email: '',
    });

    const staticSchemas = ref([]);
    const dynamicSchemas = ref([]);
    const formValidity = ref<Record<string, eFormState>>({
        static: eFormState.loading,
        dynamic: eFormState.loading,
    });
    const services = ref<string[]>([]);
    const buttonDisabled = computed(() => {
        const isStaticValid = formValidity.value.static === eFormState.valid;
        const isDynamicValid = formValidity.value.dynamic === eFormState.valid;
        return !(isStaticValid && isDynamicValid);
    });

    const onFormValidate = (val: eFormState, type: 'static' | 'dynamic') => {
        formValidity.value[type] = val;
    };

    const onUpdateForm = (updatedField: Record<string, any>, type: 'static' | 'dynamic') => {
        Object.entries(updatedField).forEach(([key, value]) => {
            form[key] = Array.isArray(value) ? [...value] : value;
        });
    };

    const staticSchemaList = [
        {
            type: eFormType.input,
            label: t('firstName'),
            formKey: 'firstname',
            required: true,
            order: 1,
            details: {
                placeholder: t('firstName'),
            },
        },
        {
            type: eFormType.input,
            label: t('name'),
            formKey: 'lastname',
            required: true,
            order: 1,
            details: {
                placeholder: t('lastname'),
            },
        },
        {
            type: eFormType.email,
            label: t('email'),
            formKey: 'email',
            required: true,
            order: 3,
            details: {
                placeholder: t('email'),
            },
            async validate(value: string) {
                try {
                    const validity = await userStore.checkEmailAvailability(value, props.userDetails.brand);
                    if (!validity.data) throw new Error('emailNotAvailable');
                    return null;
                } catch {
                    return new Error('emailNotAvailable');
                }
            },
        },
    ];

    const prepareServices = async () => {
        try {
            if (props.userDetails.uid) {
                const rights = (await userStore.getUserRights(props.userDetails.uid)) || [];
                const filteredRights = rights.filter((right) => right.brand === props.userDetails.brand);
                services.value = filteredRights.flatMap((userRight) => userRight.rights.map((right) => right.service));
            }
        } catch (error) {
            console.error('Error fetching rights:', error);
        }
    };

    const getDynamicSchemas = async () => {
        if (!services.value.length) {
            console.warn('No services available to fetch roles.');
            return;
        }

        try {
            const rolesByService = await userStore.getRolesByService(services.value);
            let orderCounter = staticSchemaList.length;

            const dynamicSchemaList = Object.keys(rolesByService).map((key) => {
                const service = rolesByService[key];
                orderCounter++;
                return {
                    type: eFormType.singleSelect,
                    label: t(key),
                    formKey: key,
                    required: true,
                    order: orderCounter,
                    labelPosition: 'inline',
                    details: {
                        items: service.roles.map((role) => ({
                            value: role.value,
                            label: `${t(role.role)} - ${role.value}`,
                        })),
                        label: t('chooseRole'),
                    },
                };
            });

            dynamicSchemas.value = dynamicSchemaList;
        } catch (error) {
            console.error('Error fetching roles by service:', error);
        }
    };

    const createUser = async () => {
        try {
            const serviceRoles = Object.keys(form)
                .filter((key) => services.value.includes(key))
                .reduce((acc, service) => {
                    acc[service] = form[service];
                    return acc;
                }, {} as Record<string, string[]>);

            const payload = {
                firstname: form.firstname,
                lastname: form.lastname,
                email: form.email,
                brand: props.userDetails?.brand,
                uid: props.userDetails?.uid,
                services: serviceRoles,
            };

            const newUser = await userStore.duplicateUser(payload);

            await userStore.getUsers();
            snack.open('success', t('userSuccessfullyCreated', { email: form.email }));
            onCloseModal();
        } catch (error) {
            console.error('Error creating user:', error);
            snack.open('alert', t('userErrorCreated'));
        }
    };

    const resetModal = () => {
        Object.keys(form).forEach((key) => (form[key] = ''));
        formValidity.value = { static: eFormState.loading, dynamic: eFormState.loading };
    };

    const onCloseModal = () => {
        resetModal();
        emit('close-modal');
    };

    onMounted(async () => {
        staticSchemas.value = staticSchemaList;
        await prepareServices();
        await getDynamicSchemas();
    });

    watch(
        () => props.userDetails,
        async () => {
            await prepareServices();
            await getDynamicSchemas();
        },
        { immediate: true }
    );
</script>

<style lang="scss"></style>
